import React from 'react'
import { Container } from 'react-bootstrap'
import './about.css'
const AboutPage = () => {
    return (
      <div className="iframe-container">
        <iframe src="landing\landing.html" ></iframe>
        </div>
      )
}

export default AboutPage