import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getIdToken } from "firebase/auth";
import { getCurrentUser } from "../Common/LocalStorage"

const auth = getAuth();

const getFirebaseUser = () => {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                resolve(user);
            } else {
                reject(new Error('No user is signed in'));
            }
        });
    });
};

export const VerifyPaymentUtils = async (external_id,user_email) =>
{  
    let currentUser = await getFirebaseUser();
    let token_id = await getIdToken(currentUser, true)
    // let token_id = currentUser.stsTokenManager.accessToken
  const verifyPaymentURL = process.env.REACT_APP_VERIFY_PAYMENT_URL;
        const bodyData = {
        ExternalIdentifier: external_id,
        UserEmail:user_email
        };
    try {
        const response = await fetch(verifyPaymentURL, {
            method: 'POST',
            body: JSON.stringify(bodyData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Accept': 'application/json, text/plain, */*',
                'Authorization': `Bearer ${token_id}`
                },
            });
            const data = await response.json();
            return data; // Return JSON response from server
    } catch (err) {
        console.log("error in verifyPaymentUtils for:", err);
        throw err; // Rethrow the error
    }
}
