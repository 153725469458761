import React, { useState, useEffect } from 'react';
import { storage } from '../../firebase';
import {getCurrentUser} from "../../Common/LocalStorage";
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation } from "react-i18next";
import './imagerepositorybrowser.css'
import { ToastMessage } from ".././ToastMessage";
import unsplash_logo from "./unsplash_logo.png";
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { fetchAndDecrypt } from '../FileStorageUtils';


const ImageRepositoryBrowser = ({selectedImage, setSelectedImage, lastAction, setLastAction, numberOfStoredImages, duringNewImageUpload, isSelectedImageExternal, setIsSelectedImageExternal, handleImageClick, externalImages, setExternalImages}) => {
  const [images, setImages] = useState([]);
  const [currentNumberOfImages, setcurrentNumberOfImages] = useState(numberOfStoredImages)
    const { t } = useTranslation();

//   const [selectedImage, setSelectedImage] = useState(null);
  const styles = {
    column: {
      // padding: '5px'
    },
    card: {
      border: 'none',
      cursor: 'pointer',
    },
    header:{
      fontSize: '16px'
    },
    cardImage: {
      objectFit: 'cover',
      height: '120px',
      width: 'auto',
      maxWidth: '100%',
      border: '3px solid transparent',
      borderRadius: '0.3rem',
    },
    selectedImage:{
        objectFit: 'cover',
        border:'3px solid #8A459B',
        height: '120px',
        borderRadius: '0.3rem',
    }
  };

  useEffect(() => {
    console.log("Listing all user images");
  
    const emailImagesRef = storage.ref(`/images/${getCurrentUser().email}`);
    const uidImagesRef = storage.ref(`/images/${getCurrentUser().uid}`);
  
    Promise.all([emailImagesRef.listAll(), uidImagesRef.listAll()])
      .then(([emailImagesList, uidImagesList]) => {
        const emailImageCount = emailImagesList.items.length;
        const uidImageCount = uidImagesList.items.length;
        setcurrentNumberOfImages(emailImageCount + uidImageCount);
  
        const emailImagePromises = emailImagesList.items.map(async imageRef => {
          const [downloadURL, metadata] = await Promise.all([
            imageRef.getDownloadURL(),
            imageRef.getMetadata(),
          ]);
          return { downloadURL, uploadDate: new Date(metadata.timeCreated), name: metadata.name };
        });
  
        const uidImagePromises = uidImagesList.items.map(async imageRef => {
          const [downloadURL, metadata] = await Promise.all([
            imageRef.getDownloadURL(),
            imageRef.getMetadata(),
          ]);
          console.log(JSON.stringify(metadata))
          return { downloadURL, uploadDate: new Date(metadata.timeCreated), name: metadata.name };
        });
  
        Promise.all([...emailImagePromises, ...uidImagePromises])
          .then(async (imagesWithDates)  => {
            const decryptedImages = await Promise.all(imagesWithDates.map(async (image) => {
            try {
              // Decrypt the image URLs before setting them in state
              const decrypted = await fetchAndDecrypt(image.downloadURL, image.name);
              return { ...image, decryptedAsBase64: decrypted };
            } catch (error) {
              console.error('Error during decryption:', error);
            }
          }));      
          const sortedImages = decryptedImages.sort((a, b) => b.uploadDate - a.uploadDate);
          const imgs = sortedImages.map(image => {return image.decryptedAsBase64});
          setImages(imgs);
            if(lastAction === 'upload'){
            setSelectedImage(imgs[0])
            }
          })
          .catch(console.error);
          })
        .catch(console.error);
    }, []);





  return (
    <div className='images-body' style={{ height: '300px', border: '1px solid #ced4da', borderRadius: '0.3rem', padding: '10px', display: (currentNumberOfImages > 0 || duringNewImageUpload) ? 'block' : 'none' }}>
      {externalImages && externalImages.length > 0 ?
      <div>
        <span style={styles.header}>
          {t("web_search_results")}
          <img className="web_results_credit" src={unsplash_logo}></img>
        </span> 
        <Row xs={2} sm={3} md={4} lg={4} xl={4} xxl={4} className="g-4" style={styles.row}>
          {externalImages.map((image, index) => (
            <Col key={image} style={styles.column}>
              <Card className="image_card">
                <Card.Img
                  variant="bottom"
                  src={image.urls.regular}
                  style={selectedImage === image.urls.regular ? styles.selectedImage : styles.cardImage}
                  onClick={() => handleImageClick(image.urls.regular, true,`${image.user.first_name} ${image.user.last_name}`)}
                />
              </Card>
              <span className="web_results_author">
                  {t("web_search_image_credit")} 
                  <a href={image.user.links.html} target="_blank"> {image.user.first_name} {image.user.last_name}</a>
              </span>
            </Col>
          ))}
        </Row>
        </div>
        :
        <div />
      }
      <span style={styles.header}>{t("last_images_uploaded")}</span>
      {images && images.length > 0 && !duringNewImageUpload ?
        <Row xs={2} sm={3} md={4} lg={4} xl={4} xxl={4} className="g-4" style={styles.row}>
          {images.map((image, index) => (
            <Col key={image} style={styles.column}>
              <Card className="image_card">
                <Card.Img
                  variant="bottom"
                  src={image}
                  style={selectedImage === image ? styles.selectedImage : styles.cardImage}
                  onClick={() => handleImageClick(image,false)}
                />
              </Card>
            </Col>
          ))}
        </Row>
        :
        <div>
          <ToastMessage
            header_text=""
            body_text={t("img_picker_loading")}
            position="auto"
          />
        </div>
      }
    </div>
  );
};

export default ImageRepositoryBrowser;