import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import CloseModalButton from "../Common/CloseModalButton";
import { ItemTypes, DefaultImageMetrics } from './Consts'
import { updateSingleMetadataProperty } from "./DBUtils";

const PageSetupDialog = ({ show, setModalPageSetupShow, getDirection, pageOrientation, setPageOrientation, boxes, book_id }) => {

  const { t, i18n } = useTranslation();
  const [pickedOrientation, setPickedOrientation] = React.useState(pageOrientation);

  useEffect(() => {
    // Set the orientation to the current page orientation when the modal is shown
    if (show) {
      setPickedOrientation(pageOrientation);
    }
  }, [show, pageOrientation]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setModalPageSetupShow(false);
    try {
      await updateSingleMetadataProperty(book_id, "page_orientation", pickedOrientation).then(response => {
        console.log("changed orientation of :"+book_id+" to: " + pickedOrientation + ". operation result:"+response)
      })
    }catch (error) {
        console.log(" Error updating book orientation: " +error);
    }

    if(pickedOrientation != pageOrientation){
      updateBoxesOrientation();
      setPageOrientation(pickedOrientation);  // Update the page orientation
    }
  };

  const updateBoxesOrientation = () => {
    // Define scale factors based on dimensions
    const scalingFactor = 510 / 640;
    
    for (const box of boxes) {
      switch (pickedOrientation) {
        case 'portrait':
          // Calculate new position coordinates for horizontal orientation
          const newLeftHorizontal =  Math.min(480-box.width, box.left * scalingFactor);
          // Making sure content stay within the new board's dimensions
          const newTopHorizontal = box.type != ItemTypes.TEXT_BOX ?
            Math.min(610-box.height, box.top * (1 / scalingFactor)): 
            Math.min(560, box.top * (1 / scalingFactor));
  
          // Update box position for horizontal orientation
          box.left = newLeftHorizontal;
          box.top = newTopHorizontal;
          break;
  
        case 'landscape':
          const newLeftVertical = Math.min(610-box.width, box.left * (1 / scalingFactor));
          const newTopVertical = box.type != ItemTypes.TEXT_BOX ?
          Math.min(480-box.height, box.top * scalingFactor): 
          Math.min(430, box.top * scalingFactor);

          box.left = newLeftVertical;
          box.top = newTopVertical;
          break;
      }
    }
  }

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dir={getDirection()}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
        {t("page_setup_title")}
        </Modal.Title>
        <CloseModalButton onClick={() => setModalPageSetupShow(false)} />
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label style={{ fontWeight: 'bold' }}>{t("orientation_setting")}</Form.Label>
            <div key={`inline-radio`} className="mb-3">
              <Form.Check
                inline
                label={t("portrait_checkbox_caption")}
                name="orientation"
                type="radio"
                id={`inline-radio-1`}
                value="portrait"
                checked={pickedOrientation === "portrait"}
                onChange={() => setPickedOrientation("portrait")}
              />
              <Form.Check
                inline
                label={t("landscape_checkbox_caption")}
                name="orientation"
                type="radio"
                id={`inline-radio-2`}
                value="landscape"
                checked={pickedOrientation === "landscape"}
                onChange={() => setPickedOrientation("landscape")}
              />
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>

          <Button type="submit">
            {t("save_orientation_submit_btn")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default PageSetupDialog;
