import React, { memo, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes, DefaultImageMetrics } from './Consts'
import { Piece } from './Pieces/Piece';
import Draggable from 'react-draggable';
import './bookpage.css'

const BookPage = ({ boxes, moveBox, removeBox, onImageUploadActions, onImageUploadDone, onUploadTextDone, getImgUrl,
   viewOnlyMode, onImgPrivateChange, pageBackground, handlePencilClick, private_image, currentPageIndex, external_symbols_counter, pageOrientation }) => {
    
    // Dynamic dimensions based on page orientation
    const dimensions = {
      portrait: { width: "510px", height: "640px" },
      landscape: { width: "640px", height: "510px" } 
    };

    // Choose dimensions based on current page orientation
    const currentDimensions = pageOrientation === "portrait" ? dimensions.portrait : dimensions.landscape;

    const styles = {
      width: currentDimensions.width,
      height: currentDimensions.height, //((dimensions.height-180) > 640) ? (dimensions.height-180) : '640px',
      position: "relative",
      padding: "10px",
      margin: "10px",
      marginTop: "0px",
      /*background: "#FFFFFF",*/
      background: pageBackground || "#FFFFFF",
      boxShadow: "1px 1px 0px #A9A9A9, 3px 3px 0px #D3D3D3",
      marginLeft: "auto",
      marginRight: "auto",
      overflow: "auto",
      border: "3px solid #8A459B",
      fontFamily: "Heebo-Regular",
    };

    const [isViewMode, setViewMode] = useState(false);
    const onDropEnd = (event, dragdata) => {
      const left = dragdata.lastX//event.clientX//Math.round(item.left + draggableData.deltaX);
      const top = dragdata.lastY//event.clientY//Math.round(item.top + draggableData.deltaY);
      console.log("attempting to drop item left:" + left + ", top:" + top)

      if (dragdata.node.attributes.boxid) {
        console.log("dropped item left:" + left + ", top:" + top + " ,box.id:" + dragdata.node.attributes.boxid.nodeValue)
        moveBox(dragdata.node.attributes.boxid.nodeValue, left, top)
      }
      else console.log("failed to get box.id for move");

      return undefined;
    }

    useEffect(() => {
      setViewMode(viewOnlyMode);
    }, [viewOnlyMode]);

    const setBoxPosition = (box) => {
      let posToReturn = { x: 169, y: 200 } //default image position
      console.log("attepmting to render box id:" + box.id + (box.left && box.top ? "got box.left:" + box.left + " ,box.top:" + box.top : "** no box.left and box.top **"))

      if (box.left != null && box.top != null)
        posToReturn = { x: box.left, y: box.top }
      else
        if (box.type == ItemTypes.TEXT)
          posToReturn = { x: 1, y: 10 }

      console.log("rendering box id: " + box.id + " at:" + JSON.stringify(posToReturn))
      return posToReturn
    }

    const [isEditMode, setEditMode] = useState(false)
    const onEditModeChange = (mode) => {
      console.log("switching box edit mode to: " + mode)
      setEditMode(mode)
    }

    const handleDeleteSound = (soundId) => {
      const updatedPieces = boxes.filter(box => !(box.type === 'SOUND' && box.id === soundId));
      removeBox(soundId);
      console.log(`Sound with id ${soundId} deleted.`);
    }


    return (
      <div style={styles} className="handle">
        <div id="book-page" style={{ fontFamily: "Heebo-Regular" }}>
          {boxes.map((box, index) => {
            let PieceToAdd =
              <Draggable
                key={box.id}
                disabled={isEditMode || isViewMode}
                handle=".handle"
                bounds=".handle"
                position={setBoxPosition(box)}
                scale={1}
                onStart={() => console.log("on start")}
                onDrag={() => console.log("on drag")}
                onStop={onDropEnd}>
                <div id="todrag" className="handle study_piece" boxid={box.id}>
                <Piece
                  key={box.id}
                  id={box.id}
                  type={box.type}
                  {...box}
                  removeImg={removeBox}
                  onImageUploadActions={onImageUploadActions}
                  onImageUploadDone={onImageUploadDone}
                  onImgPrivateChange={onImgPrivateChange}
                  onUploadTextDone={onUploadTextDone}
                  onEditModeChange={onEditModeChange}
                  getImgUrl={getImgUrl}
                  viewOnlyMode={viewOnlyMode}
                  handlePencilClick={handlePencilClick}
                  private_image={private_image}
                  onDeleteSound={box.type === 'SOUND' ? handleDeleteSound : undefined}
                />
                </div>
              </Draggable>
            return (PieceToAdd);
          })}
          {currentPageIndex < 2 && external_symbols_counter > 0 && (
            <div style={{
              position: 'absolute',
              right: '10px',
              bottom: '10px',
              left: '10px',
              whiteSpace: 'pre-wrap',
              fontFamily: 'Arial, sans-serif',
              fontSize: '8px', // Adjust the font size as needed
              userSelect: 'none' // Disables text selection
            }}>
              Pictograms author: Sergio Palao. Origin: ARASAAC (http://www.arasaac.org). License: CC (BY-NC-SA). Owner: Government of Aragon (Spain)
            </div>
          )}
        </div>
      </div>
    );
};

export default BookPage;