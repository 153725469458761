import React from 'react';
import { useTranslation } from "react-i18next";


export const AccessibilityDocument = () => {

  const { t, i18n } = useTranslation();


  const styles = {
    page: {
      textAlign: (i18n.language=='he')?'right':'left',
      direction: (i18n.language=='he')?'rtl':'ltr',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      maxWidth: '800px',
      margin: '0 auto',
    },
    header: {
      fontSize: '32px',
      fontWeight: 'bold',
      marginBottom: '30px',
      color: '#8A459B' ,
      paddingBottom: '10px',
      textAlign:'center',
    },
    body: {
      fontSize: '18px',
      marginBottom: '20px',
      lineHeight: '1.5',
    },
    list: {
      listStyleType: 'circle',
      marginInlineStart: '0',
      marginRight: '20px',
    },
  };

  return (
    <div style={styles.page}>
      <div style={styles.header}>
        {t("accessibility_statement")}
      </div>

      <div style={styles.body}>
        <p>
        {t("accessibility_start")} 
        </p>
      </div>

      <div style={styles.body}>
        <p style={{ fontWeight: 'bold', color: '#8A459B'}}>{t("accessibility_content")}</p>
        <ol style={styles.list}>
                <li>
                    <h3>{t("accessibility_navigation_header")} </h3>
                    <p>{t("accessibility_navigation")}</p>
                </li>
                <li>
                    <h3>{t("accessibility_color_header")} </h3>
                    <p>{t("accessibility_color")}</p>
                </li>
                <li>
                    <h3>{t("accessibility_fonts_header")}</h3>
                    <p>{t("accessibility_fonts")}</p>
                </li>
                <li>
                    <h3>{t("accessibility_links_header")}</h3>
                    <p>{t("accessibility_links")}</p>
                </li>
                <li>
                    <h3>{t("accessibility_forms_header")}</h3>
                    <p>{t("accessibility_forms")}</p>
                </li>
        </ol>
      </div>

      <div style={styles.body}>
        <p>
        {t("accessibility_summary")}      
        </p>
      </div>
    </div>
  );
};