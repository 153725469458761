import React, { useCallback, useEffect, useState } from "react";
import { Form, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { EditBookScreen } from "../BookEdit/EditBookScreen";
import { SearchScreen } from "../BookSearch/SearchScreen";
//import logo from './images/book-stand-2826440-2347467.png';
import "./navbar.css";
import logo from "./images/ozzystorylogo.svg";
import { t } from "i18next";
import { LocalStorageKeys, UserPermission } from "../BookEdit/Consts";
import { getCurrentUser, logOut } from "./LocalStorage";
import { Button } from '@restart/ui';
import { useTranslation } from "react-i18next";
import "flag-icons/css/flag-icons.min.css";
import { get_organization, get_user } from "../BookEdit/DBUtils";
import { MdAutoFixHigh } from "react-icons/md";
import { getUserGroup } from "../UserMgmt/UserAdmin";

const languages = [
  {
    code: "he",
    name_lang: "עברית - HE",
    country_code: "il",
    dir: "rtl",
  },
  {
    code: "en",
    name_lang: "English - EN",
    country_code: "us",
    dir: "ltr",
  },
];

const styles = {
  generator_nav:{
    color:'#8A459B',
    fontWeight:'bold'
  }
}

export default function NavbarBootsrap({ user, onLogout, userMetaString }) {
  const [userObject, setUserObject] = useState(
    localStorage.getItem(LocalStorageKeys.userKey)
  );
  const [navExpanded, setNavExpanded] = useState(false);
  const [userData,setUserData] = useState({})
  const [userOrganization,setUserOrganization] = useState()
  const { i18n } = useTranslation();
  const currentLanguageCode = i18n.resolvedLanguage;
  const getDirection = () => (i18n.language  === "he" ? "rtl" : "ltr");
  const [flag, setFlag] = useState(currentLanguageCode);
  const [groupId,setGroupId] = useState(null)
  const getCurrentName = (e) => {
    if (e == "en") {
      return "EN";
    } else {
      return "HE";
    }
  };
  const logout = () => {
    setUserObject(null);
    onLogout();
  };

useEffect(() => {
  async function fetchUserData() {
    try {
      if (userMetaString)
      {
        let userMetadata = JSON.parse(userMetaString)
        setUserData(userMetadata)
        let userGroup = getUserGroup(userMetadata)
        if (userGroup && userGroup.group_id){
             setGroupId(userGroup.group_id)
            }
        if (userMetadata && userMetadata.organizations && userMetadata.organizations.id){
          const response = await get_organization(userMetadata.organizations.id);
          setUserOrganization(response);
        } 
        else{
          setUserOrganization(false);
        }
      }
    }
    catch (error){
      // Handle the error here, such as logging or showing a user-friendly message.
      console.error("Error fetching user data:", error);
    }
  }
  fetchUserData();
}, [user]);


  return (
    <Navbar
      dir={getDirection()}
      expand="lg"
      variant="light"
      className={(flag==='en')?"navbar_container":"navbar_container_he"}
      expanded={navExpanded}
    >
      <Navbar.Toggle
        //onToggle={() => setNavExpanded(!navExpanded)}
        aria-controls="responsive-navbar-nav"
        className="navbar-toggler"
        onClick={() => setNavExpanded(!navExpanded)}
      />
      <a href="/search">
        <Navbar.Brand as="img" className="logo" src={logo}></Navbar.Brand>
      </a>
      <Navbar.Collapse className="justify-content-left basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link className="navbar-item" as={Link} to="/search" onClick={() => setNavExpanded(false)}>
            {t("menu_search")}
          </Nav.Link>
          <Nav.Link className="navbar-item" as={Link} to="/yourstories" onClick={() => setNavExpanded(false)}>
            {t("menu_yourstories")}
          </Nav.Link>
          <Nav.Link className="navbar-item highlight" as={Link} to= {groupId ? "/ai-story/"+groupId : "/ai-story"} onClick={() => setNavExpanded(false)}>
            <MdAutoFixHigh className={"generate_story_icon "+(getDirection()=="rtl" ? "_rtl" : "")}/>
            {t("menu_aistory")}
          </Nav.Link>
          <Nav.Link className="navbar-item" as={Link} to="/contact" onClick={() => setNavExpanded(false)}>
            {t("menu_contact_us")}
          </Nav.Link>
          <NavDropdown
            id="about_dropdown"
            title={t("menu_about")}
            className="navbar-item">
                <NavDropdown.Item as={Link} to="/about" onClick={() => setNavExpanded(false)}>
                  {t("menu_about")}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/privacy" onClick={() => setNavExpanded(false)}>
                  {t("menu_privacy")}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/terms" target="_blank" onClick={() => setNavExpanded(false)}>
                  {t("menu_terms")}
                </NavDropdown.Item>
                {i18n.language==='he'?
                <NavDropdown.Item as={Link} to="/moe-gefen" onClick={() => setNavExpanded(false)}>
                  {t("menu_gefenprogram")}
                </NavDropdown.Item>
                :""}
            </NavDropdown>
        </Nav> 
      </Navbar.Collapse>
      <Navbar.Collapse className="basic-navbar-nav justify-content-end">
   {user && user.length > 0 && getCurrentUser() && userOrganization ? (
      <NavDropdown
        title={
            userOrganization.logo && (
              <img
                src={userOrganization.logo}
                alt="Organization Logo"
                width="70"
                height="20"
              />
            )
          }
        className="basic-nav-dropdown"
      >   
      <NavDropdown.Item as={Link} to="/organizationstories" onClick={() => setNavExpanded(false)}>
        {t("organization_stories")}
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/organizationstudents" onClick={() => setNavExpanded(false)}>
        {t("organization_students")}
      </NavDropdown.Item>
        {userData.permission == UserPermission.ORG_ADMIN || 
        (userData && userData.organizations.groups && 
          userData.organizations.groups.length > 0 && userData.organizations.id &&
          userData.organizations.groups[0].permission && userData.organizations.groups[0].permission == UserPermission.ORG_GROUP_ADMIN)
        ?
          <NavDropdown.Item as={Link} to={"/organizationusers/"+userData.organizations.id} onClick={() => setNavExpanded(false)}>
            {t("menu_org_user")}
          </NavDropdown.Item> : ""}
        </NavDropdown>
        ):''}

        <Nav className="ml-auto">
          {flag === "he" ? (
            <span className="fi fi-il"></span>
          ) : (
            <span className="fi fi-us"></span>
          )}
          <NavDropdown
            className="basic-nav-dropdown"
            title={getCurrentName(currentLanguageCode)}
            defaultValue={currentLanguageCode}
          >
            {languages.map(({ code, name_lang }) => {
              return (
                <NavDropdown.Item
                  key={code}
                  disabled={code === currentLanguageCode}
                  onClick={() => {
                    i18n.changeLanguage(code)
                    setFlag(code)
                    setNavExpanded(false)
                  }}
                >
                  {name_lang}
                </NavDropdown.Item>
              );
            })}
          </NavDropdown>

          {
            /*<Nav.Link id="loginItem" href="login">{user.user.displayName}</Nav.Link>*/
            //setAndDisplayUsername(user)
            //localStorage.getItem(LocalStorageKeys.userKey) ?
            //userObject ?
            user && user.length > 0 && getCurrentUser() ? (
              /*<Nav.Link id="loginItem" as={Link} to="/login">{getCurrentUser().displayName}</Nav.Link>*/
              <NavDropdown
                title={getCurrentUser().displayName}
                className="basic-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/yourstories" onClick={() => setNavExpanded(false)}>
                  {t("menu_yourstories")}
                </NavDropdown.Item>
                {!userOrganization && <NavDropdown.Item as={Link} to="/students" onClick={() => setNavExpanded(false)}>
                  {t("your_students")}</NavDropdown.Item>}
                <NavDropdown.Divider />
                <NavDropdown.Item
                  as={Button}
                  onClick={() => logOut().then(logout)}
                >
                  {t("logout")}
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link id="loginItem" as={Link} to="/login">
                {t("menu_signin")}
              </Nav.Link>
            )
          }
        </Nav>
      </Navbar.Collapse>
      {/*</Container>*/}
    </Navbar>
  );
}

function Search() {
  return SearchScreen;
}

function Edit() {
  return EditBookScreen;
}
