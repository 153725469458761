import React from 'react';

export const GefenProgramPage = () => {
  const styles = {
    page: {
      textAlign: 'right',
      direction: 'rtl',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      maxWidth: '800px',
      margin: '0 auto',
    },
    header: {
      fontSize: '32px',
      fontWeight: 'bold',
      marginBottom: '30px',
      color: '#8A459B' ,
      paddingBottom: '10px',
      textAlign:'center',
    },
    body: {
      fontSize: '18px',
      marginBottom: '20px',
      lineHeight: '1.5',
    },
    list: {
      listStyleType: 'circle',
      marginInlineStart: '0',
      marginRight: '20px',
    },
  };

  return (
    <div style={styles.page}>
      <div style={styles.header}>
        תוכנית אוזיסטורי - מכרז הגפן של משרד החינוך
      </div>

      <div style={styles.body}>
        <p>
          Ozzystory - פלטפורמה המנגישה למידה חברתית רגשית בעזרת AI. אנו שמים דגש על סיפורים חברתיים, החל מסיפורים על הזמנת חבר למשחק, התמודדות עם הפסדים ועד עזרה לחבר בצרה.
        </p>
        <p>
          בתוכנית נלווה אתכן כדי להפיק סדרת סיפורים מותאים אישית עבור התלמידים.
        </p>
        <p>
          תוכלו להכין סיפורים חברתיים אישיים ללא הגבלה כדי לעזור לילדים להתמודד עם סיטואציות רגשיות חברתיות שונות.
        </p>
      </div>

      <div style={styles.body}>
        <p style={{ fontWeight: 'bold', color: '#8A459B'}}>דרכי הכנת הסיפורים:</p>
        <ul style={styles.list}>
          <li>מחולל הסיפורים AI.</li>
          <li>התאמה אישית של אחת מעשרות התבניות שלנו.</li>
          <li>הכנת סיפור חדש מאפס.</li>
        </ul>
        <p>
          כל סיפור מותאם אישית לילד מסוים, עם התמונות שלו ותמונות אישיות של הסיטואציה עבורו. לכל כיתה קיים איזור אישי משלה, ובכך ניתן גם להעתיק סיפורים עבור כל ילדי הכיתה תוך דקות בודדות.
        </p>
      </div>

      <div style={styles.body}>
        <p>
          הצוות שלנו ידריך וילווה אתכן כדי לבנות סל חברתי אישי עבור התלמידים.
        </p>
      </div>
    </div>
  );
};