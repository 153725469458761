// import { t } from 'i18next'
import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useTranslation } from "react-i18next";
import { AiOutlineLock } from 'react-icons/ai';





export const PrivateModalDialog = ({shouldShow,handlePrivateStoryDialogHide,getDirection}) =>{


    const styleTitle={
        fontSize:'24px',
    }

    const {t} = useTranslation();
    return (
        <Modal 
            show={shouldShow} 
            keyboard={true}
            onHide = {handlePrivateStoryDialogHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dir={getDirection()}>
           <Modal.Header><Modal.Title style={styleTitle}>{t("private_modal_header")} <AiOutlineLock/></Modal.Title></Modal.Header>
            <Modal.Body>{t("private_modal_body")}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handlePrivateStoryDialogHide}>{t("private_modal_button_close")}</Button>
            </Modal.Footer>
        </Modal>
    )
}
