// import { t } from 'i18next'
import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useTranslation } from "react-i18next";





export const DeletePageModalDialog = ({shouldShow, onPageDeletion, handleDeletePageDialogHide,getDirection}) =>{

    const style = {
        height:'25px'
      };
          const {t} = useTranslation();
    return (
        <Modal 
            dir={getDirection()}
            show={shouldShow} 
            keyboard={true}
            onHide = {handleDeletePageDialogHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-container">
           <Modal.Header> <Modal.Title>{t("delete_page_modal_header")}</Modal.Title></Modal.Header>
            <Modal.Body>{t("delete_page_modal_body")}</Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onPageDeletion}>{t("delete_page_modal_button_delete")}</Button>
                <Button variant="secondary" onClick={handleDeletePageDialogHide}>{t("delete_page_modal_button_cancel")}</Button>
            </Modal.Footer>
        </Modal>
    )
}
