import React, { useEffect, useState } from 'react'
import { IoMdAdd } from "react-icons/io";
import EditStudentPage from './EditStudentPage';
import { getCurrentUser } from "../Common/LocalStorage";
import {getUserStudents, deleteUserStudent} from '../BookEdit/DBUtils'
import { Link } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import StudentsCarousel from './StudentsCarousel';
import { useTranslation } from "react-i18next";
import Card from 'react-bootstrap/Card'
import { UserPermission } from '../BookEdit/Consts';

import { MdAdd } from "react-icons/md";
import { UpgradeModalDialog } from '../BookSearch/UpgradeModalDialog';
import { SubMaxStudentsDialog } from './SubMaxStudentsDialog';
import { isPaidUser } from './UserAdmin';
import { isPaidConsumerUser } from '../BookEdit/FileStorageUtils';


const styles = {
    button:{
    cursor:'pointer',
      border:'none',
      fontSize:'18px',
      backgroundColor:'#8A459B',
      outline:"none",
      paddingBottom:'6px',
      marginTop:'5px',
      
    },
};

const StudentsPage = () => {

  const [user_students, set_user_students] = useState();
  const [user, setCurrentUser] = useState(null);
  const { i18n,t } = useTranslation();
  const getDirection = () => (i18n.language=== "he" ? "rtl" : "ltr");
  const [upgradeModalShow, setUpgradeModalShow] = useState(false);
  const [maxStudentsModalShow, setMaxStudentsModalShow] = useState(false);
  const [is_paid_user,set_is_paid_user] = useState(false);


    const showUpgradeModal = () => {
    setUpgradeModalShow(true)
  }
    const hideUpgradeModal = () => {
    setUpgradeModalShow(false)
  }
    const showSubMaxStudentsModal = () => {
    setMaxStudentsModalShow(true)
  }
    const hideSubMaxStudentsModal = () => {
    setMaxStudentsModalShow(false)
  }

  useEffect(() => {
    async function fetchData() {
      try {
          let currentUser = getCurrentUser();
          if (currentUser && currentUser.email){
            setCurrentUser (currentUser)
            console.log("Current User " + JSON.stringify(currentUser.email))
            const response = await getUserStudents(currentUser.email);
            console.log("mapping students");
            set_user_students(response);
          await isPaidConsumerUser().then((res)=>
            set_is_paid_user(res)
            )
            
        }
      } 
      catch (err) {
        console.error("failed to fetch user books.", err);
      }
    }
    fetchData();
  }, []);

   const generateStudentLink = () => {
    return "/editstudent/"
   }
  return (
         <div>
      <Container dir={getDirection()}>
        <h4 className={i18n.language=='he'? 'categoryHeaderHe' : 'categoryHeader'}>{t("your_students")}</h4>
            {user_students && (
                (is_paid_user && Object.keys(user_students).length < UserPermission.SUBSCRIBER_MAX_STUDENTS) ||
                (!is_paid_user && Object.keys(user_students).length < UserPermission.MAX_STUDENT) ? (
                    <Link to={generateStudentLink()}>
                        <Button style={styles.button} variant="outline-light" className="mb-4">
                            {t("add_new_student")}
                            <MdAdd/>
                        </Button>
                    </Link>
                ) : (
                    <Button
                        style={styles.button}
                        variant="outline-light"
                        className="mb-4"
                        onClick={is_paid_user ? showSubMaxStudentsModal : showUpgradeModal}
                    >
                        {t("add_new_student")}
                        <MdAdd/>
                    </Button>
                )
            )}
        {(user_students) && (Object.keys(user_students)).length > 0 ? (
          <StudentsCarousel 
              id="personal_user_students" 
              students={user_students}
              getDirection = {getDirection}
              deleteStudent = {(student_id) => deleteUserStudent(user.email, student_id)}
              set_user_students={set_user_students}
              />
        ) : (
          <div>no-students</div>
        )}
      </Container>
        <>
        <UpgradeModalDialog
        show={upgradeModalShow}
        handleDialogHide={hideUpgradeModal}
        getDirection={getDirection}
        />

        <SubMaxStudentsDialog
        show={maxStudentsModalShow}
        handleDialogHide={hideSubMaxStudentsModal}
        getDirection={getDirection}/>
        </>
    </div>
  )
}

export default StudentsPage