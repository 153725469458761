import React from 'react'
import {LocalStorageKeys} from '../BookEdit/Consts'
import firebase, { logout} from "../firebase";
import {get_user, readPage} from "../BookEdit/DBUtils";
import { GiCactusTap } from 'react-icons/gi';
export const useStateWithLocalStorage = (localStorageKey, defaultValue='') => {
  console.log("changing local storage state for:"+localStorageKey+". got defaultValue:"+defaultValue)  
  const [value, setValue] = React.useState(
      (defaultValue && defaultValue.length>0) ? defaultValue : localStorage.getItem(localStorageKey)
    );
  
    React.useEffect(() => {
      localStorage.setItem(localStorageKey, value);
    }, [value]);
  
    return [value, setValue];
  };

export const getCurrentUser = () =>{
  let return_value = false
  try
  {
    let localStoreUser = localStorage.getItem(LocalStorageKeys.userKey)
    localStoreUser && localStoreUser != 'null' ? 
        return_value =  JSON.parse(localStorage.getItem(LocalStorageKeys.userKey))
    : return_value = false
  }
  catch(err){
    console.error("failed to get user",err)
  }
  return return_value
}
//disable caching oz-216
/*export const readBookMetadataFromLocalStorage = (book_id) =>{
  let return_value = false
  let localStoreBookMetadata = localStorage.getItem(LocalStorageKeys.book_metadata)
  if(localStoreBookMetadata && localStoreBookMetadata[book_id]){
    return_value =  JSON.parse(localStoreBookMetadata[book_id])
  }else{
    return_value = false
  }
  return return_value
}*/

export const readPageFromLocalStorage = async (book_id,page_id) =>{
  let return_value = false
  //disable caching oz-216
  /*let key=LocalStorageKeys.book_page_id+"_"+page_id;
  console.log("attempting to read page:"+key+" locally")
  let localStorageBookPage = localStorage.getItem(key)
  if(localStorageBookPage){
    console.log("found page data locally:"+localStorageBookPage)
    return_value =  JSON.parse(localStorageBookPage)
  }else{*/
    await readPage(book_id, page_id).then(
      (response) =>{
       return_value=response;
       //oz-216, disable caching setBookPage(book_id,page_id,response)
      },
      (error) => {
          console.error("failed to read page:"+page_id+"'s content. book_id:"+book_id,error)
      }
    )
  //}
  return return_value
}

export const setBookPage = async (book_id,page_id,data) =>{
  let key=LocalStorageKeys.book_page_id+"_"+page_id;
  let localStorageBookPage = localStorage.setItem(key,JSON.stringify(data))
  return localStorageBookPage
}

export const getStoriesPerStudents = () =>{
  let return_value = false
  let localStoreStories_per_students = localStorage.getItem(LocalStorageKeys.stories_per_students)
  localStoreStories_per_students ? 
      return_value =  JSON.parse(localStoreStories_per_students)
  : return_value = false
  return return_value
}

export const setStoriesPerStudents = (storiesPerStudents) =>{
  let return_value = false
  let localStoreStories_per_students = localStorage.setItem(LocalStorageKeys.stories_per_students,JSON.stringify(storiesPerStudents))
  return localStoreStories_per_students
}

export const logOut = async () => {
    logout()
    localStorage.removeItem(LocalStorageKeys.userKey)
    localStorage.removeItem(LocalStorageKeys.last_visited_page)
    localStorage.removeItem(LocalStorageKeys.current_book_id)
    localStorage.removeItem(LocalStorageKeys.stories_per_students)
    localStorage.removeItem(LocalStorageKeys.book_metadata)

}


export const UpdateLocalStorage = async (local_storage_item_key, item_property, value_to_update='') =>{ 
              // Update local storage here
            try{
              const localStorageData = JSON.parse(localStorage.getItem(local_storage_item_key));
              if (localStorageData)
                 {
                  localStorageData[item_property] = localStorageData[item_property] ? 0 : 1
                  // Assuming the response contains the updated student object
                  localStorage.setItem(local_storage_item_key, JSON.stringify(localStorageData));
              }
            }
            catch(err){
              console.error("failed to update: "+local_storage_item_key + "for property: "+item_property+ " with value: "+value_to_update, err)
            }
}