import React from "react";
import AvatarEditor from "react-avatar-editor";
import { AiOutlineRotateRight } from "react-icons/ai";
import "./student_image_editor.css";
import { t } from "i18next";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default class StudentImageEditor extends React.Component {
  state = {
    image: this.props.img_url,
    width: this.props.width,
    height: this.props.height,
    border: this.props.border,
    borderRadius: this.props.borderRadius,
    rotate: this.props.rotate,
    scale: this.props.scale,
    update_file: this.props.update_file,
    update_url: this.props.update_url,
    update_rect: this.props.update_rect,
    handleClose: this.props.handleClose,
    handleShow: this.props.handleShow,
    setImgModified: this.props.setImgModified,
    language: this.props.language,
  };

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  getDirection = () => (this.props.language === "he" ? "rtl" : "ltr");

  handleSave = async () => {
    const dataUrl = this.editor.getImage().toDataURL();
    const rect = this.editor.getCroppingRect();
    const res = await fetch(dataUrl);
    const blob = await res.blob();

    this.props.update_image(blob);
    this.props.update_rect(rect);
    this.props.handleClose();
    this.props.setImgModified(true);
  };

  handleDelete = () => {
    this.props.update_url(null);
    this.props.update_image(null);
    this.props.handleClose();
    this.props.setImgModified(true);
  };

  handleScale = (e) => {
    e.preventDefault();
    const scale = parseFloat(e.target.value);
    this.setState({ scale: scale });
  };

  rotateRight = (e) => {
    e.preventDefault();
    this.setState({
      rotate: (this.state.rotate + 90) % 360,
    });
    console.log("rotating, new rotate value:" + this.state.rotate);
  };

  setEditorRef = (editor) => {
    if (editor) this.editor = editor;
  };

  render() {
    return (
      <Modal
        className="modal"
        show={this.props.show}
        onHide={this.props.handleClose}
        backdrop="static"
        keyboard={false}
        dir={this.getDirection()}
      >
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title
            className={this.getDirection() === "rtl" ? "modal-title-he" : ""}
          >
            {t("edit_student_image")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div ref={this.wrapperRef}>
            <div className="buttonsRow image-buttonsRow">
              <span className="d-inline-block">
                <button
                  type="button"
                  className="hoverable float btn-circle"
                  onClick={this.rotateRight}
                >
                  <AiOutlineRotateRight />
                </button>
              </span>

              <span className="hoverable">
                <span className="d-inline-block">
                  <button className="float btn-range">
                    <input
                      className="range"
                      name="scale"
                      type="range"
                      onChange={this.handleScale}
                      min={"1"}
                      max="4"
                      step="0.05"
                      defaultValue="1"
                    />
                  </button>
                </span>
              </span>
            </div>
            <div>
              <AvatarEditor
                ref={this.setEditorRef}
                scale={this.state.scale}
                color={[0, 0, 0, 0.55]}
                disableBoundaryChecks={false}
                width={this.state.width}
                height={this.state.height}
                border={this.state.border}
                borderRadius={this.state.borderRadius}
                rotate={this.state.rotate}
                image={this.props.img_url}
                crossOrigin="anonymous"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="images_actions">
            <Button
              className="save_image_button"
              variant="primary"
              onClick={this.handleSave}
            >
              {t("save_student_image")}
            </Button>

            <a
              className="delete_image_link"
              href="javascript:void(0)"
              onClick={this.handleDelete}
            >
              {t("delete_student_image")}
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    ); //: null
  }
}
