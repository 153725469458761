import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams } from "react-router-dom";
import { Container } from 'react-bootstrap';
import {performOrgUserSearch} from '../Common/SearchAPI'
import { useTranslation } from "react-i18next";
import {TimestampConverter} from './Timestamp'

export const OrganizationGroupUser = ({userMetaString, groupName}) => {
    const [groupUsers, setGroupUsers] = useState([])
    const { i18n,t } = useTranslation();
    const getDirection = () => (i18n.language=== "he" ? "rtl" : "ltr");
    const params = useParams();
    console.log("groupName:"+groupName)

    useEffect (() => {
        async function fetchData(){
            let userObject = JSON.parse(userMetaString)
            if (userObject && userObject.permission && (userObject.permission == 'org-admin') &&
                userObject.organizations && userObject.organizations.id && 
                params.org_id && userObject.organizations.id == params.org_id){
                    await performOrgUserSearch(userObject.organizations.id).then(response=>{
                        if (response.hits) setGroupUsers(response.hits)
                    })
            }
        }
        fetchData()
    },[])

    return(
        <div>
            <Container dir={getDirection()}>
                <h4 className={i18n.language=='he'? "categoryHeaderHe" : "categoryHeader" }>{t("organizations_users_header")+groupName}</h4>
                <Row xs={2} sm={3} md={4} lg={7} xl={9} xxl={11} className="g-4">
                    <Col><h5>{t('organizations_users_email')}</h5></Col>
                    <Col><h5>{t('organizations_users_name')}</h5></Col>
                    <Col><h5>{t('organizations_users_lastenter')}</h5></Col>
                </Row>
                    {
                        groupUsers.map((user, index) => (
                            <Row key={user.email} xs={2} sm={3} md={4} lg={7} xl={9} xxl={11} className="g-4">
                                <Col key={user.email}>{user.email}</Col>
                                <Col key={user.full_name}>{user.full_name}</Col>
                                <Col key={user.lastmodified}><TimestampConverter timestamp={user.lastmodified}/></Col>
                            </Row>
                        )
                    )}
            </Container>
        </div>
    )
}

    /* ---- user example -----
    {
    "path": "users/idoz-oti@ozzystory.com",
    "email": "idoz-oti@ozzystory.com",
    "full_name": "ido oti",
    "updated_on": "Tue Nov 07 2023 20:16:30 GMT+0200 (Israel Standard Time)",
    "user_consent": {
        "privacy": [
            {
                "signed": "Tue Nov 07 2023 20:16:30 GMT+0200 (Israel Standard Time)",
                "version": "12-09-23"
            },
            {
                "signed": 1699627072497,
                "version": "12-09-23"
            },
            {
                "signed": 1699628100370,
                "version": "12-09-23"
            }
        ],
        "terms": [
            {
                "signed": "Tue Nov 07 2023 20:16:30 GMT+0200 (Israel Standard Time)",
                "version": "12-09-23"
            },
            {
                "signed": 1699627072497,
                "version": "12-09-23"
            },
            {
                "signed": 1699628100370,
                "version": "12-09-23"
            }
        ]
    },
    "lastmodified": 1699628100878,
    "organizations": {
        "groups": [
            {
                "group_id": "oti_1"
            }
        ],
        "id": "oti"
    },
    "objectID": "idoz-oti@ozzystory.com",
    "_highlightResult": {
        "path": {
            "value": "users/idoz-<em>oti</em>@ozzystory.com",
            "matchLevel": "full",
            "fullyHighlighted": false,
            "matchedWords": [
                "oti"
            ]
        },
        "email": {
            "value": "idoz-<em>oti</em>@ozzystory.com",
            "matchLevel": "full",
            "fullyHighlighted": false,
            "matchedWords": [
                "oti"
            ]
        },
        "full_name": {
            "value": "ido <em>oti</em>",
            "matchLevel": "full",
            "fullyHighlighted": false,
            "matchedWords": [
                "oti"
            ]
        },
        "updated_on": {
            "value": "Tue Nov 07 2023 20:16:30 GMT+0200 (Israel Standard Time)",
            "matchLevel": "none",
            "matchedWords": []
        },
        "user_consent": {
            "privacy": [
                {
                    "signed": {
                        "value": "Tue Nov 07 2023 20:16:30 GMT+0200 (Israel Standard Time)",
                        "matchLevel": "none",
                        "matchedWords": []
                    },
                    "version": {
                        "value": "12-09-23",
                        "matchLevel": "none",
                        "matchedWords": []
                    }
                },
                {
                    "signed": {
                        "value": "1699627072497",
                        "matchLevel": "none",
                        "matchedWords": []
                    },
                    "version": {
                        "value": "12-09-23",
                        "matchLevel": "none",
                        "matchedWords": []
                    }
                },
                {
                    "signed": {
                        "value": "1699628100370",
                        "matchLevel": "none",
                        "matchedWords": []
                    },
                    "version": {
                        "value": "12-09-23",
                        "matchLevel": "none",
                        "matchedWords": []
                    }
                }
            ],
            "terms": [
                {
                    "signed": {
                        "value": "Tue Nov 07 2023 20:16:30 GMT+0200 (Israel Standard Time)",
                        "matchLevel": "none",
                        "matchedWords": []
                    },
                    "version": {
                        "value": "12-09-23",
                        "matchLevel": "none",
                        "matchedWords": []
                    }
                },
                {
                    "signed": {
                        "value": "1699627072497",
                        "matchLevel": "none",
                        "matchedWords": []
                    },
                    "version": {
                        "value": "12-09-23",
                        "matchLevel": "none",
                        "matchedWords": []
                    }
                },
                {
                    "signed": {
                        "value": "1699628100370",
                        "matchLevel": "none",
                        "matchedWords": []
                    },
                    "version": {
                        "value": "12-09-23",
                        "matchLevel": "none",
                        "matchedWords": []
                    }
                }
            ]
        },
        "lastmodified": {
            "value": "1699628100878",
            "matchLevel": "none",
            "matchedWords": []
        },
        "organizations": {
            "groups": [
                {
                    "group_id": {
                        "value": "<em>oti</em>_1",
                        "matchLevel": "full",
                        "fullyHighlighted": false,
                        "matchedWords": [
                            "oti"
                        ]
                    }
                }
            ],
            "id": {
                "value": "<em>oti</em>",
                "matchLevel": "full",
                "fullyHighlighted": true,
                "matchedWords": [
                    "oti"
                ]
            }
        }
    }
}
    */