import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";


const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

// Set the language for your application
const selectedLanguageFromURL = new URLSearchParams(window.location.search).get("lng");
const LocalStorageLanguage = localStorage.getItem('i18nextLng');

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        //menu
        menu_search:"Home",
        change_lang:"Change language",
        menu_edit:"Compose",
        menu_signin:"Sign in",
        menu_yourstories:"Your Stories",
        menu_aistory:"Story Generator",
        logout:"Sign out",
        your_students:"Your Students",
        your_books:"Your Stories",
        your_profile:"Your profile",
        menu_contact_us:"Contact us",
        menu_about:"About",
        menu_privacy:"Privacy Policy",
        privacy_and_terms_header:"Privacy & Terms",
        privacy_and_terms_body:"In Ozzystory, we care for your privacy. please read and approve below",
        menu_terms:"Terms of use",
        organization_students:"Students",
        organization_stories:"Stories",
        menu_org_user:"Caregivers",
        //image picker
        img_picker_header:"Select Image",
        image_description:"Add image description",
        img_picker_private:"Private image",
        default_img_picker:"Enable Default Image",
        img_picker_button:"Submit",
        background_picker_header:"Select Background",
        background_color_picker:"Background Color",
        last_images_uploaded:"Your Images",
        web_search_results: "Search results by",
        web_search_box_text: "Search the web",
        web_search_image_credit:"By",
        upload_new_image:"Upload New Image",
        generate_image:"Generate Image",
        private_image_desc_validator:"Please insert description",
        default_image_url_validator:"Please insert default image url",
        img_picker_loading:"Loading your images",
        img_picker_private_placeholder:"Image Description",
        //sound picker
        sound_picker_header:"Select Sound",
        upload_sound_file:"Upload new sound",
        start_recording:"Start recording",
        stop_recording:"Stop recording",
        //generative-image-dialog
        generate_image_modal_header:"Generate Image",
        generate_image_form_desc:"Image Description",
        generate_image_missing_desc:"Image description missing",
        generate_image_form_story_submit:"Generate",
        generate_image_toast_header:"Image Generator",
        generate_image_toast_body:"Generating your image",
         //change image private state
         change_image_private_state_header:"Update Image Private State",
         change_image_private_state_image_description:"image description",
         change_image_private_state_private:"Private image",
         change_image_private_state_button:"Submit",
         //edit image buttons
        img_delete_btn:'Delete',
        img_copy_btn:'Copy',
        img_private_btn:'Make Private',
        img_public_btn:'Make Public',
        img_rotate_btn:'Rotate',
        img_increase_btn:'Increase',
        img_decrease_btn:'Decrease',
        img_add_border_btn:'Add Border',
        img_remove_border_btn:'Remove Border',
        img_zoom_btn:'Zoom',
        img_private_content_prefix:'Private content: ',
        //sound
        playRecording:'Play recording',
        close: 'Close',
        //loading image toast
        tst_load_img_header:"Loading",
        //draggale image item
        edit_book_done:"Save",
        //login
        login_sign_in:"Discover Ozzystory",
        login_sign_in_popup:"Start viewing and editing therapeutic stories with a free Ozzystory account",
        login_privacy_checkbox:"I have read and agree to the ",
        login_privacy:"privacy policy",
        login_terms:"terms of use",
        login_and:" and ",
        login_approve_terms_alert:"Please approve privacy policy and terms of use",
        login_toast_header:"Sign in to Ozzystory",
        login_toast_body:"Loading your Ozzystory experience",
        //org-validation
        org_valid_header:"Member Login for",
        org_valid_pass_lbl:"Password",
        org_valid_btn_lbl:"Submit",
        org_valid_code_failed:"Invalid code. Please contact the administrator at",
        //edit book
        default_book_name:"Untitled Story",
        publish_book_btn:"Save",
        delete_book_btn:"Delete",
        copy_book_btn:"Copy",
        remove_background_btn:"Remove Background",
        tailor_book_btn:"Make It Your Own",
        export_book_pdf_btn:"Export PDF",
        export_book_pdf_loader_header:"Export to PDF",
        export_book_pdf_loader:"Downloading",
        export_disable_btn:"Please review all pages first",
        page_indicator:"Page",
        add_page:"Add page",
        delete_page:"Delete page",
        page_navigation_toast:"Loading page",
        new_page_creation_toast:"Creating new page",
        page_deletion_toast:"Deleting page",
        textbox_placeholder:"Click to insert text",
        delete_book_modal_body:"Are you sure you want to delete this story?",
        delete_book_modal_header:"Confirm delete",
        delete_book_modal_button_delete:"Delete",
        delete_book_modal_button_cancel:"Cancel",
        default_toast_text:"Loading",
        //edit book sidebar
        edit_sidebar_img_caption:"Image",
        edit_sidebar_txt_caption:"Text",
        edit_sidebar_symbol_caption:"Symbol",
        edit_sidebar_background_caption:"Background",
        edit_sidebar_page_setup_caption: "Page setup",
        edit_sidebar_paste:"Paste",
        edit_sidebar_sound_caption:"Sound",
        //upload-book-dialog
        upload_book_dialog_header:"Publish story",
        submit_book_dialog_btn: "Save",
        book_name_label: "Story Name",
        age_label: "Age",
        kidname_label: "Kid Name",
        category_label: "Category",
        locale_label: "Locale",
        kid_image: "Kid Image",
        theme_image: "Theme Image",
        cat_conduct_change: "Conducting a change",
        cat_a_new_independance: "A new independance state",
        cat_one_time_occuasion: "A one time occuasion",
        cat_my_surroundings: "Me my surroundings",
        upload_book_name_validator: "Story name missing",
        upload_student_id_validator: "student choice missing",
        upload_kid_age_validator: "Kid age missing",
        upload_theme_image_validator: "Theme image missing",
        permission_label:"Permission",
        private_permission:"Private Story",
        public_permission:"Public Story",
        public_org_permission:"Shared with ",
        public_org_default_name:"my organization",
        publish_toast_header:"Save story",
        publish_toast_body:"Almost done",
        select_student:"Select Student",

        //organizations pages
        organization_books_header:"Stories by ",
        organization_students_header:"Students of ",
        organizations_users_header:"Caregivers of ",
        organizations_users_email:'Email',
        organizations_users_name:'Name',
        organizations_users_lastenter:'Last Login',
        //yourbooks-page
        your_books_header:"Your Stories",
        //yourstudents_page
        add_new_student:"Add New Student",
        //search-page
        search_box_caption:"Find a story",
        search_result_header:"Matching Social Stories",
        private_modal_header:"Private Story",
        private_modal_body:"Sorry, this story is private",
        private_modal_button_close:"Close",
        trending_books_title:"Trending Stories",
        new_story_caption:"New Story",
        new_ai_story_caption:"Story Generator",
        delete_book_toast_header:"Deleting Story",
        delete_book_toast_body:"Almost done",
        loading_stories:"Loading stories...",
        by_name:'By',
        //search-page-personal-story-with-ai
        my_ai_story_card_by:'written by you',
        my_ai_story_card_story_name:'Your Story',
        my_ai_story_card_story_body_main:'Your Story in Seconds',
        my_ai_story_card_story_body_sub_1:'Write a quick description',
        my_ai_story_card_story_body_sub_2:'and get your story with AI',
        //contact-us-form
        contact_name:"Name",
        contact_email:"Email",
        contact_jobtitle:"Job title",
        contact_organization:"Organization name",
        contact_phone:"Phone number",
        phone_error: "Invalid phone number format, please write valid phone number",
        contact_content:"Your thoughts",
        contact_submit_btn:"Submit",
        contact_toast_header:"Contact us",
        contact_toast_body:"Almost done",
        contact_fill_upgrade:"I’d like to upgrade my account to Ozzy Premium.",
        contact_post_submit:"Thank you for contacting Ozzystory",
        contact_post_bett_draw_submit:"Thank you! Draw results will be published on Monday at 15:30.\nWe're available at +972-54-4922270 or idoz@ozzystory.com.",
        contact_post_bett_draw_header:"Win a social lunch box and Ozzystory subscriptions.",
        contact_post_submit_calltoaction:"Back to homepage",
        //search-symbol-dialog
        symbol_search_title:"Symbol Search",
        symbol_search_box_caption:"Find a symbol",
        symbols_from_ARASAAC:"Symbols from ",
        symbols_from_Ozzystory:"Symbols from Ozzystory",
        no_symbol_found: "No symbol found",
        delete_page_modal_body:"Are you sure you want to delete this page?",
        delete_page_modal_header:"Confirm delete",
        delete_page_modal_button_delete:"Delete",
        delete_page_modal_button_cancel:"Cancel",
        //page-setup-dialog
        page_setup_title: "Page Setup",
        orientation_setting: "Orientation",
        portrait_checkbox_caption: "portrait",
        landscape_checkbox_caption: "landscape",
        save_orientation_submit_btn: "Submit",
        //edit-student
        edit_student_header:"Edit Student",
        upload_student_image:"Upload Image",
        delete_student_image: "Delete Image",
        save_student_image: "Save Changes",
        edit_student_image: "Edit Image",
        add_student_name_label:"First Name",
        add_student_age_label:"Age",
        add_student_concentration_level_label:"Concentration Level",
        add_student_language_comprehension_label:"Language comprehension",
        add_student_main_challenges_label:"Main challenges",
        add_student_eating_level_label:"Eating Function",
        add_student_toilet_level_label:"Toilet Function",
        add_student_hobbies_label:"Hobbies",
        add_student_locale_label:"Locale",
        add_student_edu_type_label:"Education Type",
        save_student_submit_btn:"Save",
        edit_student_toast_header:"Edit Student",
        edit_student_toast_body:"Almost done",
        add_student_gender_label:"Gender",

        //edit-student-fields
        less_than_a_min_option:"Less than a minute",
        up_to_two_min_option:"Up to 2 minutes",
        up_to_four_min_option:"Up to 4 minutes",
        up_to_ten_min_option:"Up to 10 minutes",
        ten_min_or_more_option:"10 minutes or more",
        Prefer_not_to_say:"Prefer not to say",
        understanding_situations_option:"Understanding everyday situations",
        basic_vocabulary_option:"Basic everyday vocabulary",
        vocabulary_noun_verbs_option:"A wide vocabulary that includes nouns and verbs",
        vocabulary_descriptive_words_option:"A wide vocabulary that includes many nouns, verbs, descriptive words",
        vocabulary_abstract_concepts_option:"A wide vocabulary that includes abstract concepts",
        daily_activities_option:"ADL - Activities Of Daily Living",
        social_skills_option:"Social skills",
        adapting_to_new_situation_option:"Adapting to a new situation",
        school_option:"School",
        aggressive_behavior_option:"Aggressive behavior",
        delete_student_modal_header:"Delete Student",
        delete_student_modal_body:"Are you sure you want to delete this student?",
        //personal-content
        personal_content_header:"Tailor Story",
        personal_content_button:"Create Story",
        book_can_match:"For",
        books_for_students_title:"For Your Students",
        view_without_tailoring_button:"View without tailoring",
        tailoring_story_toast:"Tailoring story to you",
        personal_content_default_name:"Ozzy",
        //upgrade dialog
        upgrade_modal_header:"Upgrade to Ozzy Premium",
        upgrade_modal_body:"Unlock the full Ozzy Premium version for AI-crafted stories tailored to your needs",
        upgrade_modal_button:"Purchase",
        upgrade_modal_link:"No, thanks",

        //subscribers-max-students-dialog
        max_students_modal_header:"Students Quota Reached",
        max_students_modal_body:"Support more students by upgrading to an organization subsciption",
        max_students_modal_btn:"Contact us",

        //generative-story
        generate_story_header:"Story Generator",
        generate_story_form_story_theme: "Story Theme",
        generate_story_form_story_descripion:"Short Description",
        generate_story_form_story_submit: "Generate Story",
        generate_story_form_language:"Language",
        generate_story_form_language_he:"Hebrew",
        generate_story_form_language_en:"English",
        generate_story_form_language_lbl:"Story Language",
        generate_story_toast_header: "Story Generator",
        generate_story_toast_body:"Generating your story",
        generate_story_form_story_desc_placholder:"Shawn is a big fan of football, but he struggles when he misses a play. Explore his emotions, suggest ways to enhance his performance, and get back into the game.",
        generate_story_form_story_theme_placholder:"I can't play football",
        generate_story_failed_modal_header:'Story generation failed',
        generate_story_failed_modal_details:'Details',
        generation_story_failed_modal_btn:"Close",
        generation_story_failed_modal_error: 'An error occurred while generating the story',
        use_student_image_checkbox:'Use student image',
        

        //accessibility-document
        accessibility_statement:"Accessibility Statement",
        accessibility_start:"The purpose of this document is to provide information and guidelines to users with various disabilities in order to ensure optimal accessibility of the website/application. Accessibility is an important principle that ensures that every person can easily and comfortably use the user interface.",
        accessibility_content:"Content",
        accessibility_navigation_header:"Navigation and User Interface:",
        accessibility_navigation:"The website/application is compatible with keyboards, voice assistants, and screen reader and in addition easy and clear navigation using appropriate tags and navigation buttons.",
        accessibility_color_header:"Colors and Backgrounds:",
        accessibility_color:"Option to change colors and backgrounds for easier readability for users with sensitivities and visual impairments.",
        accessibility_fonts_header:"Fonts:",
        accessibility_fonts:"Selection of clear and large fonts with the option to enlarge them as needed.",
        accessibility_links_header:"Links and Buttons:",
        accessibility_links:"Clear and accurate textual descriptions for each link and button.",
        accessibility_forms_header:"Forms:",
        accessibility_forms:"Includes textual descriptions for each field in the form, along with clear instructions for handling mandatory fields and error fields.",
        accessibility_summary:"This document provides detailed instructions for planning and designing a website/application to ensure maximum accessibility for all users, including those with various disabilities. To ensure optimal accessibility, it is important to follow the guidelines and utilize the available accessibility tools.",
        
        //payment-failed-page
        payment_failed_header:"Ozzystory subscription",
        payment_failed_body:"Payment failed, code:",
        payment_failed_btn:"Try again",
        payment_failed_err_contact_btn:"Contact us",

        //welcome-post-purchase-page
        welcome_page_header:"Welcome ",
        welcome_page_body:"Therapeutic AI is now in your hands.",
        welcome_page_btn:"Generate Stories With AI",
        welcome_page_payment_error:"Sorry, there was an issue with your payment. We're happy to assist, please contact us",
        welcome_page_payment_err_detail:"Error:",
        welcome_page_payment_err_cotact_btn:"Contact us",
      }
    },
    he: {
      translations: {
        //menu
        menu_search:"בית",
        menu_edit:"עריכה",
        menu_signin:"כניסה",
        menu_yourstories:"הסיפורים שלך",
        menu_aistory:"מחולל הסיפורים",
        logout:"יציאה",
        your_books:"הסיפורים שלך",
        your_students:"התלמידים שלך",
        menu_contact_us:"כתבו לנו",
        menu_about:"אודות",
        menu_privacy:"מדיניות פרטיות",
        privacy_and_terms_header:"מדיניות פרטיות ותנאי שימוש",
        privacy_and_terms_body:"חשוב לנו לשמור על פרטיותכם, אנא קראו ואשרו את הרשום מטה",
        menu_terms:"תנאי השימוש",
        menu_gefenprogram:"תוכנית אוזיסטורי - מכרז גפן",
        organization_students:"תלמידים",
        organization_stories:"סיפורים",
        menu_org_user:"אנשי צוות",
        //image picker
        img_picker_header:"בחרו תמונה",
        img_picker_private:"תמונה פרטית",
        default_img_picker:"תמונת ברירת מחדל",
        image_description:"הוסף תיאור לתמונה",
        change_lang: "שנה שפה",
        img_picker_button:"אישור",
        background_picker_header:"בחרו רקע",
        background_color_picker:"צבע רקע",
        last_images_uploaded:"התמונות שלך",
        web_search_results: "תמונות מהרשת באדיבות",
        web_search_box_text: "חיפוש ברשת",
        web_search_image_credit: "צולם ע״י",
        upload_new_image:"העלאת תמונה חדשה",
        generate_image:"מחולל התמונות",
        private_image_desc_validator:"הכניסו תיאור לתמונה",
        default_image_url_validator:"בחרו תמונת ברירת מחדל",
        img_picker_loading:"מכינים את התמונות שלך",
        img_picker_private_placeholder:"תיאור התמונה",
        //sound picker
        sound_picker_header:"בחרו שמע",
        upload_sound_file:"העלה קובץ סאונד",
        start_recording:"התחל הקלטה",
        stop_recording:"הפסק הקלטה",
        //generative-image-dialog
        generate_image_modal_header:"מחולל התמונות",
        generate_image_form_desc:"תיאור התמונה",
        generate_image_missing_desc:"תיאור תמונה חסר",
        generate_image_form_story_submit:"יצירת תמונה",
        generate_image_toast_header:"מחולל התמונות",
        generate_image_toast_body:"מחולל תמונה עבורך",
        //change image private state
        change_image_private_state_header:"עדכנו פרטיות התמונה",
        change_image_private_state_image_description:"תיאור התמונה",
        change_image_private_state_private:"תמונה פרטית",
        change_image_private_state_button:"אישור",
         //edit image buttons
        img_delete_btn:'מחיקה',
        img_copy_btn:'העתקה',
        img_private_btn:'תמונה פרטית',
        img_public_btn:'תמונה ציבורית',
        img_rotate_btn:'הפוך',
        img_increase_btn:'הגדלה',
        img_decrease_btn:'הקטנה',
        img_apply_border_btn:'הוספת מסגרת',
        img_remove_border_btn:'מחיקת מסגרת',
        img_zoom_btn:'זום',
        img_private_content_prefix:'תוכן פרטי: ',
        //sound
        playRecording:'נגן הקלטה',
        close: 'סגור',
        //loading image toast
        tst_load_img_header:"טעינה",
        edit_book_done:"שמירה",
        //login
        login_sign_in:" הכירו את העולם של אוזיסטורי",
        login_sign_in_popup:"התחברו כדי שנוכל לשמור את הסיפורים שכתבתם",
        login_privacy_checkbox:"קראתי והסכמתי ל",
        login_privacy:"מדיניות הפרטיות",
        login_terms:"תנאי השימוש",
        login_and:" וגם ל",
        login_approve_terms_alert:"אשרו בבקשה שקראתם את מדיניות הפרטיות ותנאי השימוש",
        login_toast_header:"כניסה לאוזיסטורי",
        login_toast_body:"טוענים את אוזיסטורי עבורך",
        //org-validation
        org_valid_header:"כניסה לחברי",
        org_valid_pass_lbl:"סיסמה",
        org_valid_btn_lbl:"אישור",
        org_valid_code_failed:"הקוד אינו תקין, אנא נסו שנית. אם אינכם בטוחים, פנו בבקשה לאחראי ב",
        //edit book
        default_book_name:"סיפור ללא שם",
        publish_book_btn:"שמירת סיפור",
        delete_book_btn:"מחיקת סיפור",
        copy_book_btn:"העתקת סיפור",
        remove_background_btn:"הסרת רקע",
        tailor_book_btn:"התאמה אישית",
        export_book_pdf_btn:"הורדה כקובץ",
        export_book_pdf_loader_header:"ייצוא לקובץ",
        export_book_pdf_loader:"מכין את",
        export_disable_btn:"לפני ייצוא, דפדפו בין כל העמודים",
        page_indicator:"עמוד ",
        add_page:"הוסף עמוד",
        delete_page:"מחק עמוד",
        page_navigation_toast:"מעביר דף",
        new_page_creation_toast:"יוצרים דף חדש",
        page_deletion_toast:"נפרדים מהעמוד שמחקת",
        textbox_placeholder:"לחצו כדי להתחיל לכתוב",
        delete_book_modal_body:"בטוחים שתרצו למחוק את הסיפור הזה?",
        delete_book_modal_header:"אישור מחיקת סיפור",
        delete_book_modal_button_delete:"מחיקה",
        delete_book_modal_button_cancel:"ביטול",
        default_toast_text:"טעינה",
        //edit book sidebar
        edit_sidebar_img_caption:"תמונה",
        edit_sidebar_txt_caption:"טקסט",
        edit_sidebar_symbol_caption:"סמל",
        edit_sidebar_background_caption:"רקע",
        edit_sidebar_page_setup_caption: "הגדרות עמוד",
        edit_sidebar_paste:"הדבק",
        edit_sidebar_sound_caption:"שמע",
        //upload-book-dialog
        upload_book_dialog_header:"שמירת סיפור",
        submit_book_dialog_btn: "סיימתי",
        book_name_label: "שם הסיפור",
        age_label: "גיל",
        kidname_label: "שם התלמיד",
        category_label: "קטגוריה",
        locale_label: "מדינה",
        kid_image: "תמונת התלמיד",
        theme_image: "תמונת הסיפור",
        cat_conduct_change: "מסתגלים למצב חדש",
        cat_a_new_independance: "עצמאות",
        cat_one_time_occuasion: "אירוע חד פעמי",
        cat_my_surroundings: "אני והסביבה",
        upload_book_name_validator: "הכניסו שם לסיפור",
        upload_student_id_validator: "בחרו תלמיד",
        upload_kid_age_validator: "הכניסו את גיל התלמיד",
        upload_theme_image_validator: "בחרו תמונת נושא לסיפור",
        permission_label:"הרשאה",
        private_permission:"סיפור פרטי",
        public_permission:"סיפור ציבורי",
        public_org_permission:"משותף עם ",
        public_org_default_name:"הארגון שלי",
        publish_toast_header:"מפרסם סיפור",
        publish_toast_body:"כבר מסיימים",
        select_student:"בחרו תלמיד",
        //yourbooks-page
        your_books_header:"הסיפורים החברתיים שלך",
        //organizations pages
        organization_books_header:"הסיפורים החברתיים של ",
        organization_students_header:"התלמידים של ",
        organizations_users_header:"אנשי הצוות של ",
        organizations_users_email:'כתובת מייל',
        organizations_users_name:'שם מלא',
        organizations_users_lastenter:'התחברות אחרונה',
        //yourstudents_page
        add_new_student:"הוספת תלמיד חדש",
        //search-page
        search_box_caption:"מצאו סיפור חברתי",
        search_result_header:"סיפורים רלוונטיים",
        private_modal_header:"סיפור פרטי",
        private_modal_body:"מצטערים, הסיפור הזה הוא פרטי",
        private_modal_button_close:"סגירה",
        trending_books_title:"רבי מכר",
        new_story_caption:"סיפור חדש",
        new_ai_story_caption:"מחולל הסיפורים",
        delete_book_toast_header:"מוחק סיפור",
        delete_book_toast_body:"כבר מסיימים",
        loading_stories:"טוען סיפורים...",
        by_name:'מאת',
        //search-page-personal-story-with-ai
        my_ai_story_card_by:'נכתב על ידך',
        my_ai_story_card_story_name:'הסיפור שלך',
        my_ai_story_card_story_body_main:'בחרו נושא',
        my_ai_story_card_story_body_sub_1:'רק תגידו לנו מה הנושא',
        my_ai_story_card_story_body_sub_2:'ותקבלו סיפור בעזרת AI',
        //contact-us-form
        contact_name:"שם",
        contact_email:"כתובת מייל",
        contact_jobtitle:"תפקיד",
        contact_organization:"שם החברה",
        contact_phone:"מספר פלאפון",
        phone_error:  "מספר פלאפון לא תקין, אנא הכנס מספר תקין",
        contact_content:"מה תרצו להגיד לנו?",
        contact_submit_btn:"סיימתי",
        contact_toast_header:"כתבו לנו",
        contact_toast_body:"כבר מסיימים",
        contact_fill_upgrade:"אשמח לשדרג לאוזי פרימיום",
        contact_post_submit:"תודה שפנית אלינו, נשמח ליצור קשר בהקדם",
        contact_post_bett_draw_submit:"תודה שנרשמתן, מוזמנות לפגוש אותנו בדוכן אוזיסטורי",
        contact_post_bett_draw_header:"מגרילים קופסת אוכל ומינויים לאוזיסטורי",
        contact_post_submit_calltoaction:"חזרה לדף הסיפורים",
        //search-symbol-dialog
        symbol_search_title:"חיפוש סמל",
        symbols_from_ARASAAC:"סמלים מבית ",
        no_symbol_found: "לא נמצאו סמלים",
        symbols_from_Ozzystory:"סמלים מבית אוזיסטורי",
        symbol_search_box_caption:"מצאו סמל מתאים",
        delete_page_modal_body:"בטוחים שתרצו למחוק את הדף הזה?",
        delete_page_modal_header:"אישור מחיקת דף",
        delete_page_modal_button_delete:"מחיקה",
        delete_page_modal_button_cancel:"ביטול",
        //page-setup-dialog
        page_setup_title: "הגדרות עמוד",
        orientation_setting: "אוריינטציה",
        portrait_checkbox_caption: "לאורך",
        landscape_checkbox_caption: "לרוחב",
        save_orientation_submit_btn:"שמירה",
        //edit-student
        edit_student_header:"הוספת תלמיד",
        edit_student_image: "עריכת תמונה",
        save_student_image: "שמירת תמונה",
        upload_student_image:"העלאת תמונה",
        delete_student_image: "מחיקת תמונה",
        add_student_name_label:"שם פרטי",
        add_student_age_label:"גיל",
        add_student_concentration_level_label:"רמת קשב",
        add_student_language_comprehension_label:"הבנה שפתית",
        add_student_main_challenges_label:"אתגרים עיקריים",
        add_student_eating_level_label:"תפקוד בזמן האוכל",
        add_student_toilet_level_label:"תפקוד בשירותים",
        add_student_hobbies_label:"תחביבים",
        add_student_locale_label:"ארץ",
        add_student_edu_type_label:"מסגרת חינוכית",
        add_student_gender_label:"מגדר",
        save_student_submit_btn:"שמירה",
        edit_student_toast_header:"הוספת תלמיד",
        edit_student_toast_body:"כבר מסיימים",
        //edit-student-fields
        less_than_a_min_option:"פחות מדקה",
        up_to_two_min_option:"עד 2 דקות",
        up_to_four_min_option:"עד 4 דקות",
        up_to_ten_min_option:"עד 10 דקות",
        ten_min_or_more_option:"יותר מ-10 דקות",
        
        Male:"זכר",
        Female:"נקבה",
        Prefer_not_to_say:"לא רוצה לציין",

        understanding_situations_option:"הבנת סיטואציות יום יומיות",
        basic_vocabulary_option:"אוצר מילים בסיסי",
        vocabulary_noun_verbs_option:"אוצר מילים רחב הכולל פעלים ושמות עצם",
        vocabulary_descriptive_words_option:"אוצר מילים רחב הכולל שמות עצם, פעלים ושמות תואר רבים",
        vocabulary_abstract_concepts_option:"אוצר מילים רחב הכולל הבנה של מושגים מופשטים",
        daily_activities_option:"פעולות יום יומיות",
        social_skills_option:"כישורים חברתיים",
        adapting_to_new_situation_option:"הסתגלות למצב חדש",
        school_option:"השתלבות במסגרת",
        aggressive_behavior_option:"התנהגויות אלימות",

        delete_student_modal_header:"מחיקת תלמיד",
        delete_student_modal_body:"בטוחים שתרצו למחוק את התלמיד?",
        
        //personal-content
        personal_content_header:"התאמת הסיפור",
        personal_content_button:"יצירת סיפור",
        book_can_match:"עבור",
        books_for_students_title:"לתלמידים שלך",
        view_without_tailoring_button:"צפייה בסיפור כפי שהוא",
        tailoring_story_toast:"מתאימים את הסיפור עבורך",
        personal_content_default_name:"נועם",
        //upgrade dialog
        upgrade_modal_header:" שדרוג לאוזי פרימיום",
        upgrade_modal_body:"שדרגו לגרסת אוזי פרימיום שבה בינה מלאכותית תכתוב עבורכם סיפורים בכל נושא שתבחרו",
        upgrade_modal_button:"רכישה",
        upgrade_modal_link:"אולי מאוחר יותר",

        //subscribers-max-students-dialog
        max_students_modal_header:"הגעת למכסת התלמידים",
        max_students_modal_body:"שדרגו למינוי ארגוני כדי לתמוך ביותר תלמידים",
        max_students_modal_btn:"יצירת קשר",

        //generative-story
        generate_story_header:"מחולל הסיפורים",
        generate_story_form_story_theme: "נושא הסיפור",
        generate_story_form_story_descripion: "תיאור קצר",
        generate_story_form_story_submit: "יצירת סיפור",
        generate_story_form_language:"שפה",
        generate_story_form_language_he:"עברית",
        generate_story_form_language_en:"אנגלית",
        generate_story_form_language_lbl:"שפת הסיפור",
        generate_story_toast_header: "מחולל הסיפורים של אוזיסטורי",
        generate_story_toast_body:"מכינים את הסיפור עבורך",
        generate_story_form_story_desc_placholder:"נועם אוהב לאכול פיצה אבל קשה לו לחכות עד שהיא תהיה מוכנה. תאר מה הוא עלול להרגיש ומה הוא יכול לעשות כדי להתמודד. סיים במשפט חיובי.",
        generate_story_form_story_theme_placholder:"קשה לחכות לפיצה",
        generate_story_failed_modal_header:'יצירת סיפור נכשלה',
        generate_story_failed_modal_details:'פרטים נוספים',
        generation_story_failed_modal_btn:'סגירה',
        generation_story_failed_modal_error: 'אירעה שגיאה בעת יצירת הסיפור',
        use_student_image_checkbox:'שימוש בתמונת התלמיד',

        //accessibility-document
        accessibility_statement:"הצהרת נגישות",
        accessibility_start:"מטרת מסמך זה היא לספק מידע והנחיות למשתמשים עם מגבלות שונות, כדי להבטיח נגישות מיטבית של האתר/האפליקציה. הנגישות היא עקרון חשוב המבטיח כי כל אדם יכול להשתמש בממשק המשתמש בקלות ובנוחות.",
        accessibility_content:"תוכן",
        accessibility_navigation_header:"ניווט וממשק משתמש:",
        accessibility_navigation:"האתר/האפליקציה מתאים לשימוש עם מקלדות, תומך קול וגם עם עזרי מסך ובנוסף הניווט קל וברור עם שימוש בתגיות מתאימות וכפתורי ניווט",
        accessibility_color_header:"צבעים ורקעים:",
        accessibility_color:"אפשרות לשנות את הצבעים והרקעים כך שיהיה קל לקריאה עבור משתמשים עם רגישות ובעיות ראיה.",
        accessibility_fonts_header:"פונטים:",
        accessibility_fonts:"בחירה בפונטים ברורים וגדולים עם אפשרות להגדילם לפי הצורך.",
        accessibility_links_header:"קישורים וכפתורים:",
        accessibility_links:"הגדרת תיאורים טקסטואליים ברורים ומדוייקים לכל קישור וכפתור.",
        accessibility_forms_header:"טפסים:",
        accessibility_forms:"כולל תיאורים טקסטואליים לכל שדה בטופס, והסברים ברורים על טיפול בשדות חובה ושדות שגיאה.",
        accessibility_summary:"מסמך זה מספק הנחיות מפורטות לתכנון ועיצוב אתר/אפליקציה באופן המבטיח נגישות מקסימלית לכל משתמשים, לרבות אלו עם מגבלות שונות. על מנת להבטיח נגישות מיטבית, יש לעקוב אחר ההנחיות ולהשתמש בכלים הזמינים לנגישות.",
      
        //payment-failed-page
        payment_failed_header:"רכישת מינוי לאוזיסטורי",
        payment_failed_body:"מתנצלים, חיוב הכרטיס שלך נכשל עקב:",
        payment_failed_btn:"ניסיון חוזר",
        payment_failed_err_contact_btn:"צרו קשר",
      
        //welcome-post-purchase-page
        welcome_page_header:"שלום ",
        welcome_page_body:"מהיום, יש לך אפשרות ליצור אין ספור סיפורים בעזרת בינה מלאכותית",
        welcome_page_btn:"מחולל הסיפורים",
        welcome_page_payment_error:"מתנצלים, עקב תקלה, תהליך הרישום לא הושלם, נשמח לעזור, צרו איתנו קשר",
        welcome_page_payment_err_detail:"פירוט:",
        welcome_page_payment_err_cotact_btn:"כתבו לנו",
      
      }
    },
  },
  lng: selectedLanguageFromURL ? selectedLanguageFromURL : LocalStorageLanguage,
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  },

});

export default i18n;