import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

export const GenerationFailedDialog = ({ show, getDirection, errorMessage, handleGenerationFailedDialogHide}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };


  

  return (
    <Modal 
      show={show}
      onHide = {handleGenerationFailedDialogHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-container"
      dir={getDirection()}
    >
      <Modal.Header>
        <Modal.Title>{t('generate_story_failed_modal_header')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('generate_story_failed_modal_header')}
        <div onClick={handleToggle} style={{ cursor: 'pointer', marginTop: '10px' }}>
          {t('generate_story_failed_modal_details')}
          {isExpanded ? ' ▲' : ' ▼'}
        </div>
        {isExpanded && (
          <div style={{ marginTop: '10px', border: '1px solid #ccc', padding: '10px' }}>
            {errorMessage}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleGenerationFailedDialogHide}>{t('generation_story_failed_modal_btn')}</Button>
      </Modal.Footer>
    </Modal>
  );
};