import React, {useState, useEffect, memo} from 'react';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { Link } from "react-router-dom";
import { EditBookScreen } from '../BookEdit/EditBookScreen';
import { t } from 'i18next';
import '../Common/bookCard.css';
import { GiSewingMachine } from "react-icons/gi";
import { useTranslation } from 'react-i18next';


const BooksForStudentsStripe = ({books}) => {
  const {i18n} = useTranslation();

  const styles = {
    row: {
      /*marginBottom: '15px'*/
    },
    imgcolumn:{
      padding:0,
   //   width:'70px',
    },
    column:{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
    book_offer: {
      backgroundColor: '#efebf0',
      /*border:'1px solid #8A459B',*/
      borderRadius: '22px',
      marginLeft: i18n.language === 'he' ? '20px' : '',
      marginRight: i18n.language !== 'he' ? '20px' : '',
    },
    card: {
      backgroundColor: 'transparent',
      /*borderRadius: 55,*/
      border:'none',
      cursor:'pointer'
    },
    cardImage: {
      objectFit: 'cover',
      //objectPosition: '0px 20%', //20% from top 
      borderRadius: 20,
      height:'100px',
    },
    cardBody:{
      textDecoration: 'underline #eddff0',
      color: 'rgba(0, 0, 0, 0.698)',
      padding:'0px',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      border:'none'
      //padding:10,
     /* -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-box-flex: 1;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      align-items: center;
      color: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1;
      flex: 1;
      justify-content: space-between;*/
    },
    center:{
      display: 'flex',      
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnicon:{
      color:'#8A459B',
      fontSize:'30px'

    },
    title:{
      fontSize:'16px',
      width:'100%',
      fontWeight:'900',
    },
    text:{
      fontSize:'14px',
      color:'rgba(0, 0, 0, 0.8)',
      fontWeight:'700',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      /*whiteSpace: 'nowrap',*/
      /*wordBreak: 'break-all',*/
      /*width:'200px',*/
    },
  }

  const generateBookLink = (book_id,user_email) => {
    return "/edit/"+user_email+"/"+book_id
  }
  const [oneToShow, setOneToShow] = useState(-1);

  return (
    <div>
      {books && (typeof books === "object") && (Object.keys(books).length > 0) ? 
      <h4>{t("books_for_students_title")}</h4> : ""}
      <Container>
        <Row xs={1} sm={3} md={3} lg={3} xl={4} xxl={4} className="g-4" style={styles.row}>
          {
          Object.keys(books).map((book, index) => (
              <Link to={generateBookLink(book, books[book].creator_email)} style={styles.book_offer} component={EditBookScreen}    onMouseEnter={() => {setOneToShow(index)}} onMouseLeave={() => {setOneToShow(-1)}}>
                <Row xs={2} sm={1} md={2} lg={2} xl={2} xxl={2} className="book_card book_offer_card">
                  <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={styles.imgcolumn}>
                    <Card style={styles.card}>
                      <Card.Img variant="bottom" src={books[book].theme_img_url} style={styles.cardImage}/>
                    </Card>
                  </Col>
                  <Col  xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} style={styles.column}>
                    <Card style={styles.cardBody}>
                      {/*<Card.Body style={styles.cardBody}>*/}
                        <Card.Title style={styles.title} className="g-1">{books[book].book_name}</Card.Title>
                        <Card.Text style={styles.text}>
                        {t("book_can_match")+" "} {books[book].students.map((student, index) => ((index>0 ? ", " : "") + student.name))}
                        </Card.Text>
                      {/*</Card.Body>*/}
                    </Card>
                  </Col>
                {oneToShow==index && (<Col  xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={styles.center}>
                    <GiSewingMachine style={styles.btnicon} />
                </Col>)}
              </Row>
            </Link>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default BooksForStudentsStripe;
