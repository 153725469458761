import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import { t } from 'i18next';
import { storage } from "../firebase";
import { uploadToFileStorage } from "./FileStorageUtils";
import { useTranslation } from "react-i18next";
import { getCurrentUser} from "../Common/LocalStorage";
import { Locations, DefaultImageMetrics } from './Consts';
import CloseModalButton from "../Common/CloseModalButton";
import {updateSinglePageMetadataProperty } from "./DBUtils";

const UploadBackgroundDialog = ({show,onHide,parentOfImgPickerCallback,pageBackground,setPageBackground,getDirection,book_id,current_page_id}) => {
 const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState("");
  const { t,i18n } = useTranslation();
const [selectedColor, setSelectedColor] = useState('');
  //console.log("imageAsFile.name="+imageAsFile.name)
const style = {
  space:{
    marginLeft:'7px',
    marginRight:'7px',
  }
}
  //on image browse
  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile((imageFile) => image);
    console.log("image name on browse=" + image.name);
  };

  const handleFormHide = (e) => {
    console.log("calling onHide with filename. filename = " + imageAsFile.name);
    if (imageAsFile) {
      onHide(imageAsFile.name);
    }
    //setimagePrivate(false)
  };

  //reset dialog on show paramater change
  useEffect(() => {
    return function cleanup(){
      setImageAsFile("")
      console.log("run image picker cleanup")
    }
  },[show])

  const handleFormClose = () => 
  {
    onHide();
  };

    const getImageDimensions = (imageURL) =>
    {
        let backgroundAttributes = {}
        let img = new Image();
        img.src = imageURL;
        //console.log("nat-width:"+img.naturalWidth)
        img.onload = function (){
          console.log("nat-width:"+this.naturalWidth+" nat-height:"+this.naturalHeight)
          backgroundAttributes = {
          "nat-height": this.naturalHeight,
          "nat-width": this.naturalWidth,
                  };
          parentOfImgPickerCallback(imageURL, imageAsFile.name, this.naturalWidth, this.naturalHeight,backgroundAttributes)
        }
    }
    const removeBackground = () => {
            setPageBackground('');
            setSelectedColor('');
            onHide();
        }
    const uploadImageToFirebase = async (e) => {
      e.preventDefault()
      if (!imageAsFile && selectedColor) {
          setPageBackground(selectedColor)
          handleFormClose();
          return;
      }else if (imageAsFile == "") {
        console.error(
          "[not an image, the image file is a ${typeof(imageAsFile)}"
        );
      }
        await uploadToFileStorage(imageAsFile,Locations.IMAGE_FOLDER+getCurrentUser().uid, null,DefaultImageMetrics.BACKGROUND_IMAGE_HEIGHT).then(
          (responseURL) => {
            if (responseURL) { 
                getImageDimensions(responseURL)
                //send uploaded image's url to parent
                //parentOfImgPickerCallback(responseURL, imageAsFile.name,imagePrivate,imageDescription)
                //reset selected image name
                setImageAsFile(""); 
            }
            (error) => {
              console.log("failed to upload image to file storage", error);
            }
        });
    }

  return (
     <Modal
     
      //{...props}
      show={show}
      onHide={handleFormClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dir={getDirection()}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("background_picker_header")}
        </Modal.Title>
          <CloseModalButton onClick={handleFormClose}/>
      </Modal.Header>
      <Form onSubmit={uploadImageToFirebase} >
        <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageAsFile}
              />
          </Form.Group>              
        </Modal.Body>
        <Modal.Footer  style={{display: 'flex', justifyContent: 'space-between',alignItems: "center"}}>
        <div style={{ marginRight: "16px",display: 'flex', alignItems: 'center'}}>
        <span>{t("background_color_picker")}:  </span><input type="color" value={selectedColor} style={style.space} onChange={(e) => setSelectedColor(e.target.value)} />
        </div>
        <div style={{display: 'inline-flex',alignItems: 'center'}}>
                 {pageBackground && (
          
            <a href="#" onClick={removeBackground} style={style.space}>
              {t("remove_background_btn")}
            </a>   
        )}
          <Button
            type="submit"
            onClick = {handleFormHide}
          >
            {t("img_picker_button")}
          </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
    )
}

export default UploadBackgroundDialog


