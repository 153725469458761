
import React from 'react';

const AuthorName = ({ firstName, lastName }) => {
    if (!firstName && !lastName) {
        return null; // Don't render anything if authorName is null
    }

    const styles = {
        position: 'absolute',
        bottom: '-9px', 
        left: '1px', 
        color: 'grey',
        fontSize: '10px',
        fontFamily: 'Lato, sans-serif',
        padding: '2px 5px',
    };

    console.log("AuthorName component received:", { firstName, lastName });

    return (
        <div style={styles}>
            by {firstName} {lastName}
        </div>
    );
};

export default AuthorName;

