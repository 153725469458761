
import React, { useEffect, useState } from 'react';

import { createPayment, update_user_properties } from '../BookEdit/DBUtils';
import { Locations } from '../BookEdit/Consts';
import { VerifyPaymentUtils } from './VerifyPaymentUtils';
import { useTranslation } from "react-i18next";
import { Button, Col } from 'react-bootstrap';
import { GenerativeStoryScreen } from '../GenerativeStory/GenerativeStoryScreen';
import { Link, Navigate } from 'react-router-dom';
import { MdAutoFixHigh } from 'react-icons/md';
import video from '../Common/Videos/Website_AI_DEMO.mp4'
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

const WelcomePostPurchasePage = ({user}) => {
    const [paymentVerified, setPaymentVerified] = useState(false);
    const [error, setError] = useState(null);
    const [user_email,setUserEmail] = useState(JSON.parse(user).email)
    const { t, i18n } = useTranslation();
    const getDirection = () => (i18n.language === "he" ? "rtl" : "ltr");

    const styles = {
    page: {
      textAlign: (i18n.language=='he')?'right':'left',
      direction: (i18n.language=='he')?'rtl':'ltr',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      maxWidth: '800px',
      margin: '0 auto',
    },
    header: {
      fontSize: '32px',
      fontWeight: 'bold',
      marginBottom: '40px',
      marginTop: '80px',
      color: '#8A459B' ,
      paddingBottom: '10px',
      textAlign:'center',
    },
    body: {
      fontSize: '18px',
      marginBottom: '20px',
      lineHeight: '1.5',
      textAlign:'center',
    },
    btn: {
      width: '250px',
      textAlign:'center',
      margin: '0 auto',
    },
    error: {
      fontSize: '18px',
      marginBottom: '30px',
      marginTop:'50px',
      paddingBottom: '10px',
      textAlign:'center',
      lineHeight:"35px",
    },
    video:{
      marginTop:'40px',
      width: '100%'
    },
    button:{
      marginTop:'5px',
    }
  };


    useEffect(() => {
        const verifyPayment = async () => {
            const currentURL = window.location.href;
            const searchParams = new URLSearchParams(currentURL);
            console.log("searchParams: ", searchParams);
            const external_id = searchParams.get('OG-ExternalIdentifier');
            if (external_id && JSON.parse(user).uid==external_id) {
                try {
                    const result = await VerifyPaymentUtils(external_id,user_email);
                    if (result.status=='Valid') {
                        // Payment is verified, update state
                        firebase.analytics().logEvent('Payment_verified');
                        setPaymentVerified(true);
                        console.log("RESULT OF PAYMENT: "+result)
                    } else {
                        setError('User payment not valid');
                        console.error("Failed to open generator for user: "+user_email+" user json: "+JSON.parse(user),error)
                    }
                } catch (error) {
                    setError('Error verifying payment: ' + error);
                    console.error("Failed to verify payment for user: "+user_email+" user json: "+JSON.parse(user),error)
                }
            } else {
                setError('User payment not valid');
            }
        };
        verifyPayment();
    }, []);

    return (
        <div>
            {error ? (
                <div style={styles.error}>
                  <div>{t("welcome_page_payment_error")}</div>
                  <div>{t("welcome_page_payment_err_detail:")} {error}</div>
                  <Button style={styles.button}>{t("welcome_page_payment_err_cotact_btn")}</Button>
                </div>
            ) : paymentVerified ? (
        <div style={styles.page}>
      <div style={styles.header}>
        {t("welcome_page_header")+(JSON.parse(user).displayName).split(' ')[0]}
      </div>

      <div style={styles.body}>
        <p>
        {t("welcome_page_body")} 
        </p>
      </div>
      <div xs="auto" className="add_story_col" style={styles.btn}>
        <Link to= {"/ai-story"}>
                <MdAutoFixHigh className={"icon add_story_btn"+(getDirection()=="rtl" ? "_rtl" : "")}/>
                <span>{t('welcome_page_btn')}</span>
              </Link>
        </div>
            <video autoPlay muted loop className="my-16" style={styles.video}>
              <source src={video} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
          </div>

            ) : (
                <h3 style={styles.error}>Verifying payment...</h3>
            )}
        </div>
    );
};

export default WelcomePostPurchasePage;





