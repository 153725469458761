import React, { useState, useEffect, useRef } from 'react'
import { useParams,useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card';
//import { t } from "i18next";
import { useTranslation } from 'react-i18next';
import countryList from 'react-select-country-list'
import { useMemo } from 'react'
import { Container } from 'react-bootstrap'
import { RiUploadCloudFill } from "react-icons/ri";
import { readStudentData, createStudent, getUserStudents, updateStudent, createOrganizationStudent, get_user, readOrganizationStudentMetadata, updateOrganizationStudent } from '../BookEdit/DBUtils'
import { getCurrentUser } from '../Common/LocalStorage'
import './editstudent.css'
import '../BookEdit/PublishBookDialog';
import { uploadToFileStorage } from '../BookEdit/FileStorageUtils'
import { Locations } from '../BookEdit/Consts'
import { ToastMessage } from "../BookEdit/ToastMessage";
import { getStudent } from '../BookEdit/DBUtils'
import Select from 'react-select'
import StudentImageEditor from "./StudentImageEditor";

const EditStudentPage = ({noUserCallback, postAddViaDialogActions}) => {
    const styles = {
      display:'flex',
      justifyContent:'center',
      height:'2rem',
      width:'2rem',
    }
    const params = useParams();
    const queryParams = new URLSearchParams(location.search);
    const addNewStudentTogroupIdQuery = queryParams.get('at');
    const useOrganizationFunctions = params.groupid || addNewStudentTogroupIdQuery
    const updateStudentCallback = useOrganizationFunctions ? updateOrganizationStudent : updateStudent
    const createStudentCallback = useOrganizationFunctions ? createOrganizationStudent : createStudent
    const getStudentCallBack = useOrganizationFunctions ? readOrganizationStudentMetadata : getStudent
    const studentPagePath = useOrganizationFunctions ? "/organizationstudents" : "/students"
    const { t, i18n } = useTranslation();
    const [progressVisable, setProgressVisable] = useState(false);
      let language=i18n.language;
    const getDirection = () => (language === "he" ? "rtl" : "ltr");
    const options = useMemo(() => countryList().getData(), [])
    const numoptions = [1,2,3,4,5];
    const DEFAULT_NUMBER_VALUE = 3
    const navigate = useNavigate();
    const [student_props, setStudentProps] = useState({name:"",
                                                      age:'',
                                                      gender:"",
                                                      locale: language == 'he' ? "IL" : "US", 
                                                      concentration_level:'',
                                                      language_comprehension:'',
                                                      image:'',                         
                                                      })
    const [owner_id, setOwnerId] = useState(null)
    const [user_data, setUserData] = useState(null)
    const [file,setFile]=useState();
    const [rect, setRect] = useState();
    const [previewUrl,setPreviewUrl]=useState();
    const [user, setCurrentUser] = useState(null);
    const [submitBtndisabled, setSubmitbtnDisable] = useState(false) 
    const [imgModified, setImgModified] = useState(false);
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const selectoptions = [
  { value: '1', label: t('daily_activities_option') },
  { value: '2', label: t('social_skills_option') },
  { value: '3', label: t('adapting_to_new_situation_option') },
  { value: '4', label: t('school_option') },
  { value: '5', label: t('aggressive_behavior_option') }
]
    const customStyles = {
    control: (base, state) => ({
    ...base,
                '&:hover': { borderColor: '#8A459B' }, // border style on hover
            border: '1px solid lightgray', // default border color
            boxShadow: 'none',  // no box-shadow
              }),
   placeholder: (base) => ({
      ...base,
      textAlign: (i18n.language=='en')?"left":"right",
    }),
    option:(base)=>({
      ...base,
      textAlign: (i18n.language=='en')?"left":"right",
    })
};
    const filePickerRef=useRef();
    const pickedHandler = (event)=>{
      console.log("CHANGED")
      let pickedFile;
      // let updatedProps = student_props
      if(event.target.files && event.target.files.length===1){
        pickedFile=event.target.files[0];
        setFile(pickedFile);
      }
    }
    const pickedImageHandler =()=>{
      filePickerRef.current.click();
    }
    const firebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
    const getRandomImageForGender = (gender) => {
      const maleImages = [
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_MALE_IMAGE_1}`,
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_MALE_IMAGE_2}`,
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_MALE_IMAGE_3}`,
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_MALE_IMAGE_4}`,
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_MALE_IMAGE_5}`,
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_MALE_IMAGE_6}`,
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_MALE_IMAGE_7}`
        // Add the rest of the male image URLs
      ];

      const femaleImages = [
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_FEMALE_IMAGE_1}`,
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_FEMALE_IMAGE_2}`,
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_FEMALE_IMAGE_3}`,
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_FEMALE_IMAGE_4}`,
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_FEMALE_IMAGE_5}`,
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_FEMALE_IMAGE_6}`,
        `https://firebasestorage.googleapis.com/v0/b/${firebaseStorageBucket}/o/${process.env.REACT_APP_FEMALE_IMAGE_7}`,
        // Add the rest of the female image URLs
      ];

      const selectedImages = (gender == '1') ? maleImages : femaleImages;
      const randomIndex = Math.floor(Math.random() * selectedImages.length);

      return selectedImages[randomIndex];
    };





    useEffect(()=>{
      if(!file){
        return;
      }
      const fileReader = new FileReader();
      fileReader.onload=()=>{
        setPreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    },[file]);
    const convertMainChallengesValues = (values) => {
      let result = []
      if (values) {
        return values.map((value) => {
          const option = selectoptions.find((option) => option.value === value.toString());
          return {
            value: option ? option.value : value.toString(),
            label: option ? option.label : value.toString(),
          };
        });
      }
      else return result
    };
  const [defaultMainChallengesValues, setDefaultMainChallengesValues] = useState([]);
    useEffect(() => {
      async function fetchData(){
          //set current user
          let currentUser = getCurrentUser()
          setCurrentUser(currentUser)
          console.log("user:"+(currentUser))
          if (!(currentUser && currentUser.email))
              noUserCallback()
          else
          {
            if (addNewStudentTogroupIdQuery) 
              setOwnerId(addNewStudentTogroupIdQuery)
            else if(params.groupid)
                  setOwnerId(params.groupid)
                else
                  setOwnerId(currentUser.email)
            if(params.studentid)
            {
              console.log("got studentid:"+params.studentid+" for user:"+currentUser.email)
              await getStudentCallBack(currentUser.email, params.studentid).then(response => {
                console.log("got student details:"+JSON.stringify(response))
                setPreviewUrl(response.image)
                setStudentProps(response)
                const convertedMainChallenges = convertMainChallengesValues(response.main_challenges);
                setDefaultMainChallengesValues(convertedMainChallenges);
                //setDefaultMainChallengesValues(convertMainChallengesValues(response.main_challenges));
              })
            }
        }
      }
      fetchData()
    },[])
    const onFormFieldUpdate = (e,property) => {
      // console.log("attempting to update " + property + " :" + e.target.value)
      let updatedProps = {...student_props}
    if (property==='main_challenges') {
        // Handle multiple selection
            updatedProps[property] = e.map(item => +item.value);
            setDefaultMainChallengesValues(convertMainChallengesValues(updatedProps[property]))
    } else {
        // Handle single selection
      updatedProps[property] = (isNaN(e.target.value)) ? e.target.value : +e.target.value;
    }
      console.log("updated student_props:" + JSON.stringify(updatedProps))
      setStudentProps(updatedProps)
    }


  const setDefaultValues = (props) => {
  props.gender = props.gender || 1;
  props.concentration_level = props.concentration_level || 1;
  props.language_comprehension = props.language_comprehension || 1;
  props.main_challenges = props.main_challenges || [];
};

  const onCreateSubmit = async (event) => {
    setSubmitbtnDisable(true);
    setProgressVisable(true);
    event.preventDefault(); //prevent page refresh
    event.stopPropagation();
    let updatedProps = student_props;
    setDefaultValues(updatedProps);

      updatedProps = { ...student_props };

      if (!file && !updatedProps.image) {
        const randomImageUrl = getRandomImageForGender(updatedProps.gender);

        updatedProps.image = randomImageUrl;
      }

      if (file) {
        await uploadToFileStorage(file, Locations.STUDENT_IMAGE_FOLDER + '/' + user.uid).then((uploadURL) => {
          if (uploadURL) {
            updatedProps['image'] = uploadURL;
            setStudentProps(updatedProps);
          }
        });
      }

      try {
        if (params.studentid && !addNewStudentTogroupIdQuery) {
          console.log("attempting to edit student, for owner:" + owner_id + " student:" + params.studentid);
          await updateStudentCallback(owner_id, params.studentid, updatedProps).then((response) => {
            console.log("edit student:" + JSON.stringify(updatedProps) + ". got response:" + JSON.stringify(response));
            setProgressVisable(false);
          });
        } else {
          console.log("attempting to submit new student, for owner:" + owner_id + " student:" + JSON.stringify(updatedProps));
          await createStudentCallback(owner_id, updatedProps).then((response) => {
            console.log("created student:" + JSON.stringify(updatedProps) + ". got response:" + JSON.stringify(response));
            setProgressVisable(false);

            // Update local storage here
            try{
              const localStorageData = JSON.parse(localStorage.getItem('user-group-name'));
              if (localStorageData && localStorageData.students) {
                  // Assuming the response contains the updated student object
                  localStorageData.students[updatedProps.key] = updatedProps;
                  localStorage.setItem('user-group-name', JSON.stringify(localStorageData));
              }
            }
            catch(err){
              console.error("failed to fetch students from local storage iteam user-group-name.",err)
            }

          });
        }

        if (window.location.pathname.includes("/editstudent")) {
          navigate(studentPagePath);
        } else {
          postAddViaDialogActions(owner_id, updatedProps.key);
        }
      } catch (err) {
        setProgressVisable(false);
        console.error("failed to create/update student", err);
      }
    };

  console.log("defaulMainChallengesValues: "+JSON.stringify(defaultMainChallengesValues))
  return (
    <Container className='form_container form-container-center'>
    <Form className='page_form'  onSubmit={onCreateSubmit} dir={getDirection()}>
        <Modal.Title className='page_title'>{t('edit_student_header')}</Modal.Title>
              <input
              ref={filePickerRef}
              style={{display:"none"}}
              type="file"
              accept='.jpg,.png,.jpeg'
              onChange={pickedHandler}
              />
              {/* <div className={`image-upload "center"}`}> */}
                <div className='image-upload_preview'>
                  {previewUrl && <img src={previewUrl} alt="preview" onClick={pickedImageHandler} />}
                {!previewUrl &&(
                    <div>
                      <Button className={(language=='he')?'image-upload-button-he':'image-upload-button-en'} type="button" variant='outline-light' onClick={pickedImageHandler}><RiUploadCloudFill style={styles}/></Button>
                      <p>{t('upload_student_image')}</p>
                      </div>
                  )}
                </div>
              {/* </div> */}
              {previewUrl && (
                <>
                  {" "}
                  <a
                    className="edit_link"
                    href="javascript:void(0)"
                    onClick={handleShow}
                  >
                    {t("edit_student_image")}
                  </a>{" "}
                  <p></p>
                </>
              )}

          <StudentImageEditor
            img_url={previewUrl}
            width={300}
            height={300}
            border={70}
            borderRadius={0}
            rotate={0}
            scale={1}
            update_image={setFile}
            update_url={setPreviewUrl}
            update_rect={setRect}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            setImgModified={setImgModified}
            language={language}
          />
          <FloatingLabel
              controlId="floatingInput"
              label={t('add_student_name_label')}
              value="name"
              className="mb-2">
            <Form.Control required 
                          type="text" 
                          autoFocus
                          defaultValue={student_props.name}
                          onChange={(event) => onFormFieldUpdate(event, "name")}
                           />
          </FloatingLabel>
          <FloatingLabel
              controlId="floatingInput"
              label={t('add_student_age_label')}
              className="mb-2"
            >
              <Form.Control type="number" 
                            required
                            min={'1'}
                            defaultValue = {student_props.age}
                            onChange={(event) => onFormFieldUpdate(event, "age")}
                            />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label={t('add_student_gender_label')}
              className="mb-2">
              <Form.Select value={student_props.gender} onChange={(event) => onFormFieldUpdate(event, "gender")}>
                <option value="1">{t('Male')}</option>
                <option value="2">{t('Female')}</option>
                <option value="3">{t("Prefer_not_to_say")}</option>
              </Form.Select>
            </FloatingLabel>
                      <FloatingLabel
              controlId="floatingInput"
              label={t("locale_label")}
              className="mb-2">
              <Form.Select value={student_props.locale}  onChange={(event) => onFormFieldUpdate(event, "locale")}>
              <option key={(language=='he')?'IL-def':'US-def'} value={(language=='he')?'IL':'US'}>{(language=='he')?'Israel':'United States'}</option>
              {options.map((locale) => <option key={locale.value} value={locale.value}>{locale.label}</option>)}
              </Form.Select>
          </FloatingLabel>
          <FloatingLabel
              controlId="floatingInput"
              label={t('add_student_concentration_level_label')}
              className="mb-2"
              >
              <Form.Select value={student_props.concentration_level}
               onChange={(event) => onFormFieldUpdate(event, "concentration_level")}
               >
                <option value="1">{t("less_than_a_min_option")}</option>
                <option value="2">{t("up_to_two_min_option")}</option>
                <option value="3">{t("up_to_four_min_option")}</option>
                <option value="4">{t("up_to_ten_min_option")}</option>
                <option value="5">{t("ten_min_or_more_option")}</option>
              </Form.Select>
            </FloatingLabel>
             <FloatingLabel
              controlId="floatingInput"
              label={t('add_student_language_comprehension_label')}
              className="mb-2">
              <Form.Select value={student_props.language_comprehension} onChange={(event) => onFormFieldUpdate(event, "language_comprehension")}>
                <option value="1">{t('understanding_situations_option')}</option>
                <option value="2">{t('basic_vocabulary_option')}</option>
                <option value="3">{t('vocabulary_noun_verbs_option')}</option>
                <option value="4">{t('vocabulary_descriptive_words_option')}</option>
                <option value="5">{t('vocabulary_abstract_concepts_option')}</option>
              </Form.Select>
            </FloatingLabel>
              <Select value={defaultMainChallengesValues} placeholder={t('add_student_main_challenges_label')} styles={customStyles} className="mb-2" isMulti options={selectoptions}
              onChange={(event) => onFormFieldUpdate(event, "main_challenges")}
            />
          <Button className='submit_button' type="submit" disabled = {submitBtndisabled}>{t('save_student_submit_btn')}</Button>
          {progressVisable ? (
          <ToastMessage
            header_text={t("edit_student_toast_header")}
            body_text={t("edit_student_toast_body")}
            position="bottom-start"
          />
        ) : null}
      </Form>
      </Container>
  )
}
export default EditStudentPage