import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from 'react-bootstrap/FloatingLabel'
// import { t } from 'i18next';
import { storage } from "../firebase";
import { uploadToFileStorage } from "./FileStorageUtils";
import { useTranslation } from "react-i18next";
import { getCurrentUser} from "../Common/LocalStorage";
import { Locations } from './Consts';
import CloseModalButton from "../Common/CloseModalButton";

//export default function ImagePickerDialog(props) {
export const ChangeImagePrivateStateDialog= ({show,onHide, setImgNonPrivate, setImgPrivate,setImgDescription,imageDescription,isPrivate,imgID,getDirection}) => {

  const { t } = useTranslation();
  const [imagePrivateToSave, setimagePrivate] = useState(false)
  const [imageDescriptionToSave, setimageDescription] = useState('')
  const [isDescRequired, setisDescRequired] = useState(true)
  //console.log("imageAsFile.name="+imageAsFile.name)

  //on image browse


    const handlePrivateImage = (e) => {
      if(e.target.checked){
        setimagePrivate(true);
        setisDescRequired(true)
      }
      else{
        setimagePrivate(false);
        setisDescRequired(false)
      }
    }

  const handleFormHide = (e) => {
      onHide();
  };

  //reset dialog on show paramater change
  useEffect(() => {
    return function cleanup(){
      setimagePrivate(false)
      setimageDescription('')
      console.log("run ChangeImagePrivateStateDialog cleanup")
    }
  },[show])

  const handleFormClose = () => 
  {
    onHide();
  };

  const handleFormShow = () => 
  {
    setimagePrivate(isPrivate);
    setimageDescription(imageDescription);
    if(isPrivate){
      setisDescRequired(true)
    }
    else{
      setisDescRequired(false)
    }

  };




    const ChangeImagePrivateSubmit = async (e) => {
      e.preventDefault();
      //image Private true and didnt change
      if(isPrivate && imagePrivateToSave){
        if(imageDescription!=imageDescriptionToSave){
          setImgDescription(imgID,imageDescription,imageDescriptionToSave)
          handleFormHide();
        }
        else{
          handleFormHide();
        }
      }
      //image Public and change to private
      if(!isPrivate && imagePrivateToSave){
        setImgPrivate(imgID,imageDescriptionToSave)
        handleFormHide();
      }
      //image private and change to public
      if(isPrivate && !imagePrivateToSave){
         setImgNonPrivate(imgID,imageDescription)
         handleFormHide();
      }
      //image Public true and didnt change
      if(!isPrivate && !imagePrivateToSave){
        handleFormHide();
     }
      
    }

  return (
    <Modal
      //{...props}
      show={show}
      onHide={handleFormClose}
      onShow={handleFormShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dir={getDirection()}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("change_image_private_state_header")}
        </Modal.Title>
        <CloseModalButton onClick={handleFormClose}/>
      </Modal.Header>
      <Form onSubmit={ChangeImagePrivateSubmit} >
        <Modal.Body>
          <div className="commoncheckbox">
          <Form.Check 
              type="checkbox"
              id={`img_picker_private`}
              onChange={handlePrivateImage}
              defaultChecked={isPrivate}
              />
              <span>{t("change_image_private_state_private")}</span>
              </div>
      <FloatingLabel
              controlId="imageDescription"
              label={t("change_image_private_state_image_description")}
              className="mb-2">
            {isDescRequired?<Form.Control  required
                          placeholder="Enter image description"
                          type="text" 
                          defaultValue={imageDescription}
                          onChange={e => setimageDescription(e.target.value )}
                           />:<Form.Control  
                           placeholder="Enter image description"
                           type="text" 
                           defaultValue={imageDescription}
                           onChange={e => setimageDescription(e.target.value )}
                            />}
          </FloatingLabel>

           {/*<Form.Group className="mb-3" controlId="imageDescription">
            <Form.Label>{t("change_image_private_state_image_description")}</Form.Label>
            <Form.Control type="text" placeholder="Enter image description" defaultValue={imageDescription}  onChange={e => setimageDescription(e.target.value )}/>
    </Form.Group>*/}

           
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
          >
            {t("change_image_private_state_button")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}