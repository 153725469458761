import React, {useState, useEffect} from 'react'
import SelectStudent from '../UserMgmt/SelectStudent'
import Select from 'react-select';
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {getStudent, getSingleGroupStudent, getGroupStudents, getUserStudents, get_user} from '../BookEdit/DBUtils'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { customStyles, categoriesOptions } from '../Common/Utils/FormUtils'
import { ToastMessage } from '../BookEdit/ToastMessage'
import './aistory.css';
import { getIdToken } from "firebase/auth"
import {getFirebaseUser} from "../firebase"
import { isPaidUser, getUserGroup, CanGenerateStory } from '../UserMgmt/UserAdmin'
import {UpgradeModalDialog } from "../BookSearch/UpgradeModalDialog";
import { UpdateLocalStorage, UpdateLocalStorageUser, getCurrentUser, useStateWithLocalStorage } from '../Common/LocalStorage';
import { LocalStorageKeys } from '../BookEdit/Consts';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { GenerationFailedDialog } from './GenerationFailedDialog';

export const GenerativeStoryScreen = ({userMetaString, userString, postAiStoryGeneration, noUserCallback}) =>{
    const { i18n,t } = useTranslation();
    const params = useParams();
    const getDirection = () => (i18n.language=== "he" ? "rtl" : "ltr");
    const getStudentData = params.groupid ? getSingleGroupStudent : getStudent
    const getStudentsForUser = params.groupid ? getGroupStudents : getUserStudents
    const [storyTheme, setStoryTheme] = useState("")
    const [studentList, setStudentList] = useState({})
    const [errors, setError] = useState({ story_theme: "",student_id:"" });
    const [showToast, setShowToast] = useState(false);
    const [language, setLanguage] = useState(i18n.language)
    const [selectedStudentId, setSelectedStudentId] = useState(null)
    const [userObj, setUserObj] = useState(null)
    const [userBaseObj, setUserBase] = useState(null)
    const [isPermittedUser, setIsPermittedUser] = useState(false)
    const [upgradeModalShow, setUpgradeModalShow] = useState(false);
    const [storyDescription,setStoryDescription] = useState("")
    const [userEmail,setUserEmail] = useState(getCurrentUser().email)
    const [userMetadata, setUserMetadata] = useStateWithLocalStorage(LocalStorageKeys.USER_GROUP_NAME)
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [useRefImage,setUseRefImage] = useState(true);

    const showUpgradeModal = () => {
        setUpgradeModalShow(true)
      }

      const hideUpgradeModal = () => {
        setUpgradeModalShow(false)
      }

    const convertStudentsToList = (students_obj) => {
        let result = []
        if (typeof students_obj === 'object')
        {
            result = Object.keys(students_obj).map((key)=>({
            value: params.groupid ? students_obj[key].key.toLowerCase() : key.toLowerCase(),
            label: students_obj[key].name ? students_obj[key].name : key,
          }))
          return result
        }  
    } 

    const getUserData = async () => {
        let user_data = {}
        let user_email = getCurrentUser().email
        try {
            if (userMetaString === '' || userMetaString === 'null') {
                 user_data = await get_user(user_email);
            } else {
                const localStorageData = localStorage.getItem('user-group-name');
                if (localStorageData) {
                    user_data = JSON.parse(localStorageData);
                } else {
                    user_data = await get_user(user_email);
                }
            }
        }catch(error){
        console.error("failed to parse usermetadatastring",error)
        }
        return user_data
    }

    const fetchUserData = async ()  => {
        let is_eligable_for_ai = false
        try 
            {
              let userObject = await getUserData()
              let userData = userObject
              let group_id = null
              let studentsObject = []
              let user_permission = userObject.permission ? userObject.permission : null
              let freeGenerationQuota = null

              if (userObject)
                  setUserObj(userObject)

              let userBaseParsed = JSON.parse(userString)
              if (userBaseParsed)
                  setUserBase(userBaseParsed)

              let userGroup = getUserGroup(userObject)
              if (userGroup && userGroup.permission){
                  user_permission = userGroup.permission
              }
              if(userData.free_generation_quota != null){
                  freeGenerationQuota = userData.free_generation_quota;
              }

              is_eligable_for_ai = await CanGenerateStory(user_permission,freeGenerationQuota)
              setIsPermittedUser(is_eligable_for_ai)

              if (userGroup && userGroup.group_id){
                  await getGroupStudents(userGroup.group_id).then((async (response) => {
                  studentsObject = convertStudentsToList(response)
                  setStudentList(studentsObject)
              }))
              }
              else{
                  if (userData.students){
                      studentsObject = convertStudentsToList(userData.students)
                      setStudentList (studentsObject)
                  }
              }
              setSelectedStudentId(studentsObject && studentsObject.length>0 && studentsObject[0].value ? studentsObject[0].value : null)
          }  
        catch (err) {
          console.error("failed to fetch students.", err);
        }
        return is_eligable_for_ai
      }

    useEffect(() => {
        fetchUserData();
    }, [,userMetaString]);

    useEffect(() => {
        const storedStoryTheme = sessionStorage.getItem('story_theme');
        const storedStoryDescription = sessionStorage.getItem('story_description');

        if (storedStoryTheme) {
            setStoryTheme(storedStoryTheme);
        }

        if (storedStoryDescription) {
            setStoryDescription(storedStoryDescription);
        }
    }, []);

    useEffect(() => {
        if (storyTheme && storyTheme!='') sessionStorage.setItem('story_theme', storyTheme);
        if (storyDescription && storyDescription!='') sessionStorage.setItem('story_description', storyDescription);
    }, [storyTheme, storyDescription]);

    const languageOptions = [
    { value: 'en', label: t('generate_story_form_language_en') },
    { value: 'he', label: t('generate_story_form_language_he') }
    ]

    const extractImgFileName = (url) => {
        let result = url
        try{
            const parts = url.split('/');
            let filenameWithDirectories = parts[parts.length - 1];
            const filenameParts = filenameWithDirectories.split('?');
            let filename = filenameParts[0];
            filename = decodeURIComponent(filename);
            result = filename.substring(filename.lastIndexOf('/') + 1);
        }
        catch(error){
            console.log("error extracting image file name",error)
        }
        return result
    }

    const handleGenerationFailedDialogHide = () => {
        setShowErrorDialog(false)
    }

    const handleUseRefImageChange = () => {
        setUseRefImage(!useRefImage);

};

    const isEligableForAiCreation = async () =>{
        let result = isPermittedUser
        if (!isPermittedUser && (userMetaString === '' || userMetaString === 'null')){
            await fetchUserData().then(response =>{
                result = response
            })
        }
        return result
    }

    const onGenerateStorySubmit = async (event) =>{
        event.preventDefault()
        firebase.analytics().logEvent('Generate_AI_btn_click')
        try{
            let is_eligable_for_ai = await isEligableForAiCreation()
            if (!is_eligable_for_ai){
                showUpgradeModal()
            }
            else if (selectedStudentId)
            {
                setShowToast(true)
                await getStudentData(userObj.email, selectedStudentId).then(async (student_details) => {
                    if (student_details)
                    {
                        let image = {
                            'name':extractImgFileName(student_details.image),
                            'url':student_details.image,
                            'nat-width':'150',
                            'nat-height':'200'
                        }

                        let input={
                            name: String(student_details.name),
                            age: String(student_details.age),
                            gender: String(student_details.gender),
                            locale: String(student_details.locale),
                            att_span: String(student_details.concentration_level),
                            vocab: String(student_details.language_comprehension),
                            main_chal: String(student_details.main_chal),
                            title: String(storyTheme),
                            story_description:String(storyDescription),
                            uid: String(userBaseObj.uid),
                            student_id: selectedStudentId,
                            book_lang: String(language && language == 'en' ? 'EN' : 'IL'),
                            creator_email: String(userObj.email),
                            student_image: JSON.stringify(image),
                            updated_on : Date().toLocaleString(),
                            is_ref_image: useRefImage
                        }
                        await generateStoryWithAI(input).then(result=>{
                            console.log("generated story with ai. got book_id:"+result)
                            UpdateLocalStorage(LocalStorageKeys.USER_GROUP_NAME,LocalStorageKeys.has_free_generation)  
                            setShowToast(false)
                            if (result)
                                postAiStoryGeneration(result)
                        })
                    }
                    else 
                        setShowToast(false)
                })
            }
        }
        catch(error){
            console.log("failed to generate story",error)
            firebase.analytics().logEvent('failed_to_generate');
            setShowToast(false)
        }

    }

    const generateStoryWithAI = async (input) => 
    {
        let result = false
        try{
            const generateURL = process.env.REACT_APP_GENERATE_STORY_AI;
            let currentUser = getFirebaseUser()
            let token_id = await getIdToken(currentUser)
            await fetch(generateURL, {
                method:'POST',
                body: JSON.stringify(input),
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token_id}`
                },
            })
            .then(response => response.json())
            .then(result_json => {
                result = result_json && result_json.generated_book_id ? result_json.generated_book_id : false;
                console.log("Generated story, got result:", result);
            })
            .catch(error => {
                console.error('Failed to generate story',error);
                result = false
                firebase.analytics().logEvent('failed_to_generate');
                setErrorMessage(error.message || 'An error occurred');
                setShowErrorDialog(true);
            })
        } 
        catch (error) {
            console.error('Failed to generate story with AI', error);
            return false;
        }
        return result
    }

    const onFormFieldUpdate = (e,property) => {
        switch(property){
            case 'story_theme':
                setStoryTheme(e.target.value)
                break;
            case 'story_description':
                setStoryDescription(e.target.value) 
                break;   
            case 'language':
                setLanguage(e.target.value)
                break;
            case 'student_id':
                setSelectedStudentId(e.target.value)
                break;
        }
    }

    const postNewUserCreation = (newStudentId, updatedStudentList) => {
        setSelectedStudentId(newStudentId)
        setStudentList(updatedStudentList)
    }

    return(
        <Container dir={getDirection()}>
            <h4 className={i18n.language=='he'? 'categoryHeaderHe' : 'categoryHeader'}>{t("generate_story_header")}</h4>
            <Form onSubmit={onGenerateStorySubmit}>
                <FloatingLabel
                    controlId="generativeStoryForm.ControlStoryNameInput"
                    label={t("generate_story_form_story_theme")}
                    className="mb-2"
                    >
                    <Form.Control
                    required
                    type="text"
                    defaultValue={storyTheme}
                    onChange={(event) => onFormFieldUpdate(event, "story_theme")}
                    />
                    {storyTheme === '' && (
                        <div className={i18n.language=='he' ? 
                                        "input_field_placeholder input_field_placholder_align_he" : 
                                        "input_field_placeholder input_field_placholder_align_en"}>
                            {t('generate_story_form_story_theme_placholder')}
                        </div>
                    )}
                    {errors["story_theme"] && (
                        <span style={styles.validation_error}>{errors["story_theme"]}</span>
                    )}
                </FloatingLabel>
                <FloatingLabel
                    controlId="generativeStoryForm.ControlStoryNameInput"
                    label={t("generate_story_form_story_descripion")}
                    className="mb-2"
                    >
                    <Form.Control
                    as="textarea"
                    rows={4}
                    style={{ height: "120px" }}
                    type="text"
                    defaultValue={storyDescription}
                    onChange={(event) => onFormFieldUpdate(event, "story_description")}
                    />
                    {storyDescription === '' && (
                        <div className={i18n.language=='he' ? 
                                        "input_field_placeholder input_field_placholder_align_he" : 
                                        "input_field_placeholder input_field_placholder_align_en"}>
                            {t('generate_story_form_story_desc_placholder')}
                        </div>
                    )}
                </FloatingLabel>

                <SelectStudent
                    student_list={studentList}
                    current_student_id={selectedStudentId}
                    onChange={(event) => onFormFieldUpdate(event, "student_id")}
                    onNewStudentCreation={(studentId, updatedStudentList) => postNewUserCreation(studentId, updatedStudentList)}
                    getStudents={getStudentsForUser}
                    noUserCallback = {noUserCallback}
                />
                    {errors["student_id"] && (
                    <span style={styles.validation_error}>{errors["student_id"]}</span> 
                    )}


                <FloatingLabel
                    controlId="generativeStoryForm.ControlStoryNameInput"
                    label={t("generate_story_form_language_lbl")}
                    className="mb-2"
                    >
                        <Form.Select
                            className="mb-2" options={languageOptions}
                            //value={languageOptions.find(option => option.value === language)} // Set value based on selectedLanguage
                            value={language}
                            onChange={(event) => onFormFieldUpdate(event, "language")}>
                            {languageOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                            ))}
                        </Form.Select>
                </FloatingLabel>
            {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <div className="commoncheckbox">
                <Form.Check type="checkbox" 
                defaultChecked={useRefImage}
                onChange={handleUseRefImageChange} 
                />
                <span>{t("use_student_image_checkbox")}</span>
            </div>
            </Form.Group> */}

                            

                <div className="generate-story-btn"> 
                    <Button type="submit" 
                        disabled = {showToast}>
                        {t("generate_story_form_story_submit")}
                    </Button>
                </div>
            </Form>
            {showToast ? (
                <ToastMessage
                    header_text={t("generate_story_toast_header")}
                    body_text={t("generate_story_toast_body")}
                    position="bottom-start"
                />
            ) : null}

            <UpgradeModalDialog
                show={upgradeModalShow}
                handleDialogHide={hideUpgradeModal}
                getDirection={getDirection}
            />

            <GenerationFailedDialog
            show={showErrorDialog}
            getDirection={getDirection}
            errorMessage={errorMessage}
            handleGenerationFailedDialogHide={handleGenerationFailedDialogHide}
        />
        </Container>
    )
}