import {get_students,store_books_for_students,get_books_for_students, getGroupStudents } from '../BookEdit/DBUtils'
import {setStoriesPerStudents} from '../Common/LocalStorage'
import {getCurrentUser} from "../Common/LocalStorage";


export const performAutoMatch = async (user_email,user_groupid) =>
{  
  const autoMatchURL = process.env.REACT_APP_AUTO_MATCH_URL;

    let results=[];
    try{
        console.log("performing auto match for:"+user_email)
        await getGroupStudents(user_groupid).then(
            async(students)=>{
                        const requestBody ={
                        studentsString: JSON.stringify(students),
                        userEmail: user_email
                      };
                fetch(autoMatchURL,{
                    method: 'POST',
                    //body:  JSON.stringify({"idoz@ozzystory.com_bill":{"name":"bathen","age":"10","locale":"def-locale"}, "idoz@ozzystory.com_bob":{"name":"ido","age":"10","locale":"US"}}),
                    body:JSON.stringify(requestBody),
                    headers: {
                       'Content-type': 'application/json; charset=UTF-8',
                       'Accept': 'application/json, text/plain, */*'
                    },
                    })
                .then(res => res.json())
                .then(data => {
                // do something with data
                // console.log("performed auto match for students:"+students_string+" data:"+JSON.stringify(data))
                store_books_for_students(user_email,data);
                setStoriesPerStudents(data);
                }).catch(err => {
                    console.log("failed to performe auto match for students:",err);
                    console.log("try update information from db");
                    getAutoMatchFromDB();

                });
      

            },         
            (err) => {
                //catches the errors
                console.log("failed to get students for user:"+user_email,err)
                console.log("try update information from db");
                getAutoMatchFromDB();

            }
        );

    
        
    }
    catch(err){
        console.log("error in performAutoMatch for students:"+students,err)
        console.log("try update information from db");
        getAutoMatchFromDB();
    }
    return results;
}

const getAutoMatchFromDB = async () =>
{
  console.log("getAutoMatchFromDB")
  let currentUser = getCurrentUser()
  await get_books_for_students(currentUser.email).then(async(auto_match_response) =>{
    if (auto_match_response){
      setStoriesPerStudents(auto_match_response);
    }
  })
}

const dummy = async () =>
{
  
    let results = {};
      results["ayalaozzy@gmail.com_2"]={
        "book_name": "Back to school",
        "students": [
          {
            "key": "idoz@ozzystory.com_bob",
            "name": "ido"
          }
        ],
        "theme_img_url": "https://firebasestorage.googleapis.com/v0/b/ozi-story.appspot.com/o/images%2Fback-to-school-g5f9aa5d8a_640.jpg?alt=media&token=75661f92-ca3e-4afd-a965-bc247690d10a"
      };
    results["idoz@ozzystory.com_9"]={
        "book_name": "Working with my parents",
        "students": [
          {
            "key": "idoz@ozzystory.com_bob",
            "name": "ido"
          }
        ],
        "theme_img_url": "https://firebasestorage.googleapis.com/v0/b/ozi-story.appspot.com/o/images%2Fpeople-854005_640.jpg?alt=media&token=04e9437c-5d87-43ad-8727-f7793e67637c"
      };

    return results;
}