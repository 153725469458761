import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import { t } from 'i18next';
import { storage } from "../../firebase";
import { uploadToFileStorage, UploadWebImageToFirebase, generateRandomString } from "../FileStorageUtils";
import { useTranslation } from "react-i18next";
import { getCurrentUser} from "../../Common/LocalStorage";
import { Locations } from '../Consts';
import ImageRepositoryBrowser from "./ImageRepositoryBrowser";
import "./imagepicker.css"
import WebImageSearch from './WebImageSearch';
import CloseModalButton from "../../Common/CloseModalButton";
import { isPaidUser, getUserGroup } from '../../UserMgmt/UserAdmin'
import AuthorName from './AuthorName';

//export default function ImagePickerDialog(props) {
export const ImagePickerDialog = ({parentOfImgPickerCallback, show, onHide, enablePrivateImage, 
                                    getDirection, preLoadedPrivateImageDescription, user_data, 
                                    setUpgradeModalShow, hideUpgradeModal}) => {
  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState("");
  const [defaultImageAsFile, setDefaultImageAsFile] = useState("");
  const { t } = useTranslation();
  const [imagePrivate, setimagePrivate] = useState(true)
  const [imageDefault, setimageDefault] = useState(false)
  const [imageDescription, setimageDescription] = useState('')
  const [selectedImage, setSelectedImage] = useState("");
  const [isSelectedImageExternal, setIsSelectedImageExternal] = useState(false);
  const [selectedDefaultImage, setSelectedDefaultImage] = useState("");
  const [externalImages, setExternalImages] = useState([]);
  const [lastAction, setLastAction] = useState("");
  const [refreshImageRepository, setRefreshImageRepository] = useState(false)
  const [errors, setError] = useState({ private_image_desc: "",default_image_url:""});
  const [duringDefaultImageUpload, setDuringDefaultImageUpload] = useState(false)
  const [numberOfStoredImages, setNumberOfStoredImages] = useState(0)
  const [duringNewImageUpload, setDuringNewImageUpload] = useState(false)
  const [isPermittedUser, setIsPermittedUser] = useState(false)
  const [query, setQuery] = useState('');
  const [authorName, setAuthorName] = useState(null);


  const styles = {
    validation_error:{
      color:'#dd2c00',
      marginLeft:'5px',
    }
  }

const isFormValid = () => {
  let isValid = true;
  const newErrors = {};

  if (imagePrivate && !imageDescription && !preLoadedPrivateImageDescription) {
    newErrors.private_image_desc = t("private_image_desc_validator");
    isValid = false;
  }

  if (imageDefault && !selectedDefaultImage && !duringDefaultImageUpload) {
    newErrors.default_image_url = t("default_image_url_validator");
    isValid = false;
  }

  setError(newErrors);
  return isValid;
};


  //on image browse
  const handleImageAsFile = async (e, should_encrypt = false) => {
    const image = e.target.files[0];
    setImageAsFile(image);
    setLastAction('upload')
    setDuringNewImageUpload(true)
    console.log("image name on browse=" + image.name);
    const [imageName, imageExtension] = image.name.split('.');
    const custom_file_name = should_encrypt ? `${imageName}___enc___.${imageExtension}` : `${imageName}.${imageExtension}`; 
    console.log("custom_file_name=" + custom_file_name);
    await(uploadImageToFirebase(e, image, 'upload',null,custom_file_name)).then((response)=>{
      console.log("attempted to upload image, response:"+response)
      setRefreshImageRepository(Date.now())
      //handleFormHide(e, image)
      setDuringNewImageUpload(false)
      setImageAsFile("")
    })
  };
  const handleDefaultImageAsFile = async (e) => {
    const image = e.target.files[0];
    setDuringDefaultImageUpload(true)
    setDefaultImageAsFile(image)
    setLastAction('upload')
    console.log("image name on browse=" + image.name);
    await(uploadImageToFirebase(e, image, 'upload','default')).then((response)=>{
      console.log("attempted to upload image, response:"+response)
      // setRefreshImageRepository(Date.now())
      //handleFormHide(e, image)
    })
  };

  useEffect(()=>{
    async function setPermission()
    {
      let user_permission = ''
      let userGroup = getUserGroup(user_data)
      if (userGroup && userGroup.permission){
          user_permission = userGroup.permission
      }
      let is_paid_user = await isPaidUser(user_permission)
      setIsPermittedUser(is_paid_user)
    }
    setPermission()
  },[])

  useEffect(()=>{
    let user = getCurrentUser()
    let userEmail = user && user.email ? user.email : null
    let userUID = user && user.uid ? user.uid : null
    if (userEmail && userUID)
    {
      const userImagesRefEmail = storage.ref(`/images/${userEmail}`);
      const userImagesRefUID = storage.ref(`/images/${userUID}`);

      /*userImagesRefEmail.listAll().then(async imagesList => {
        setNumberOfStoredImages(imagesList.items.length)})*/

      userImagesRefEmail.listAll()
      userImagesRefUID.listAll()

      Promise.all([userImagesRefEmail,userImagesRefUID]).then(
        (emailFolderImages, idFolderImages) => {
          const emailImagesCount = emailFolderImages.items.length
          const idImagesCount = idFolderImages.items.length
          setNumberOfStoredImages(emailImagesCount + idImagesCount)
        })
        .catch(error => {
          console.log("Error fetching image counts", error)
        })
    }
  },[])

  useEffect(()=>{
    if(preLoadedPrivateImageDescription){
      console.log("SHOW IMAGE PICKER "+ preLoadedPrivateImageDescription)
      setimagePrivate(true)
      //setimageDefault(true)
    }
  },[preLoadedPrivateImageDescription])
    const handlePrivateImage = (e) => {
      if(e.target.checked){
        setimagePrivate(true);
      }
      else{
        setimagePrivate(false);
        setError(prevError => ( { ...prevError , default_image_url: '' }));
      }
    }
    const handleDefaultImage = (e) => {
      if(e.target.checked){
        setimageDefault(true);
      }
      else{
        setimageDefault(false);
        setError({ private_image_desc: '', default_image_url: '' });
      }
    }

const handleFormHide = (e, image=null) => {
    if(isFormValid()){
      getImageDimensions(selectedImage,selectedDefaultImage,preLoadedPrivateImageDescription);

      onHide(imageAsFile && imageAsFile.name ? imageAsFile.name : image);
   }else{
      console.log("errors.private_image_desc: ", errors["private_image_desc"])
      console.log("errors.default_image_url: ", errors["default_image_url"])
   }
};

  //reset dialog on show paramater change
  useEffect(() => {
    return function cleanup(){
      setimagePrivate(true)
      setimageDefault(false)
      setimageDescription('')
      setImageAsFile("")
      setSelectedDefaultImage("")
      setDefaultImageAsFile("")
      setSelectedImage("");
      setQuery("");
      console.log("run image picker cleanup")
      setError({ private_image_desc: '', default_image_url: '' });

    }
  },[show])

  const handleFormClose = () => 
  {
    onHide();
  };

    const getImageDimensions = (imageURL,defaultImageURL,showImagePickerFollowingPencilClick) =>
    {
        let img = new Image();
        img.src = imageURL=='' ? defaultImageURL : imageURL;
        //console.log("nat-width:"+img.naturalWidth)
        img.onload = function (){
          (lastAction === 'select') ?  
          parentOfImgPickerCallback(imageURL, '', imagePrivate, imageDescription, this.naturalWidth, this.naturalHeight, '', showImagePickerFollowingPencilClick, authorName) 
          :
          parentOfImgPickerCallback(imageURL, imageAsFile.name, imagePrivate, imageDescription, this.naturalWidth, this.naturalHeight, defaultImageURL, showImagePickerFollowingPencilClick, authorName)
        }
    }

    const onSubmit = async (e, image, action) => {
      e.preventDefault();
      if(isSelectedImageExternal){
        setIsSelectedImageExternal(false);
        console.log("selected external!")
        const image_name = `web_image_${query}_${generateRandomString(16)}.jpg`;
        await UploadWebImageToFirebase(selectedImage, image_name, Locations.IMAGE_FOLDER);
      }
    }
  
    const uploadImageToFirebase = async (e, image=null, action='upload',type, custom_file_name= null) => {
      let result = false
      const imageToUpload = (type==='default') ? defaultImageAsFile || image : imageAsFile || image
      e.preventDefault();
      if (action === "upload") {
        if (imageToUpload == "") {
          console.error("[not an image, the image file is a ${typeof(imageToUpload)}");
        }
         await uploadToFileStorage(imageToUpload, Locations.IMAGE_FOLDER + getCurrentUser().uid, custom_file_name).then(
          (responseURL) => {
            setDuringDefaultImageUpload(false)
            if (responseURL) {
              //getImageDimensions(responseURL);
              if(type==='default'){
                setSelectedDefaultImage(responseURL)
              }else{
                setSelectedImage(responseURL)
                }
              setLastAction('upload')
              //reset selected image name
              setImageAsFile(imageToUpload || "");
              result = true
            }
          },
          (error) => {
            console.log("failed to upload image to file storage", error);
          }
        );
        }
        //setSelectedImage("");
        return result
    };
    
  
  const fileInputRef = useRef(null);
  const handleNewUploadClick = () => {
    fileInputRef.current.click();
  }
  const defaultFileInputRef = useRef(null)


  const showUpgradeModal = ()=>{
    console.log("disabled tailor button clicked")
    setUpgradeModalShow()
  }

  const handleImageClick = (image, isExternal,author = null) => {
    if (selectedImage === image) {
      setSelectedImage(null);
      setIsSelectedImageExternal(false);
      setAuthorName(null);
    } else {
      if (isExternal) {
          setIsSelectedImageExternal(true);
          setAuthorName(author);
          console.log("Author set:", author);
        }
       else {
        setIsSelectedImageExternal(false);
        setAuthorName(null);
      }
      setSelectedImage(image);
      setLastAction('select');
    }
  };

  return (
<Modal
  //{...props}    
  show={show}
  onHide={handleFormClose}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered
  dir={getDirection()}
>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("img_picker_header")}
        </Modal.Title>
        <CloseModalButton onClick={handleFormClose}/>
      </Modal.Header>
      <Form onSubmit={onSubmit} >
        <Modal.Body>
        <div className="search-and-button-container">
  <Button className="link_btn" variant="link" onClick={handleNewUploadClick}>
    {t("upload_new_image")}
  </Button>
  <WebImageSearch 
    onImageSelect={handleImageClick} 
    selectedImage={selectedImage} 
    externalImages={externalImages} 
    setExternalImages={setExternalImages}
    query={query}
    setQuery={setQuery}
  />
</div>
        <Form.Group controlId="formFile" className="mb-3">
            <Form.Control
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageAsFile}
              style={{ display: "none" }}
              />
          </Form.Group>
          <ImageRepositoryBrowser
              setSelectedImage={setSelectedImage} 
              selectedImage={selectedImage}
              lastAction={lastAction}  
              setLastAction={setLastAction} 
              key={refreshImageRepository}
              numberOfStoredImages={numberOfStoredImages}
              duringNewImageUpload={duringNewImageUpload}
              isSelectedImageExternal={isSelectedImageExternal}
              setIsSelectedImageExternal={setIsSelectedImageExternal}
              handleImageClick={handleImageClick}
              externalImages={externalImages}
              setExternalImages={setExternalImages}              
              />
          {enablePrivateImage ?
            <div className="private_image">
              <div className="commoncheckbox">
                <Form.Check 
                  type="checkbox"
                  id={`img_picker_private`}
                  // label={t("img_picker_private")}
                  onChange={handlePrivateImage}
                  defaultChecked={imagePrivate}
                  disabled={preLoadedPrivateImageDescription}
                />
                <span>{t("img_picker_private")}</span>
                </div>
            <Form.Group hidden={!imagePrivate} className="mb-3" controlId="imageDescription">
            {!preLoadedPrivateImageDescription ? (
                <Form.Control
                  type="text"
                  placeholder={t("img_picker_private_placeholder")}
                  // value={imageDescription}
                  onChange={e => setimageDescription(e.target.value)}
                />
              ) : (
                <Form.Control
                  type="text"
                  value={preLoadedPrivateImageDescription}
                  readOnly
                />
          )}
          {errors["private_image_desc"] &&(
              <span style={styles.validation_error}>{errors["private_image_desc"]}</span>
            )}
            </Form.Group>
             {(user_data && user_data.permission && user_data.permission =='ozzy-publisher') && imagePrivate && (
              <div>
               <div className="commoncheckbox">
                <Form.Check 
                type="checkbox"
                id={`default_img_picker`}
                // label={t("default_img_picker")}
                onChange={handleDefaultImage}
                //defaultChecked={preLoadedPrivateImageDescription}
                //disabled={preLoadedPrivateImageDescription}
                />
                <span>{t("default_img_picker")}</span>
                </div>
                  <Form.Group hidden={!imageDefault} controlId="formFile" className="mt-2">
                    {/* <Button onClick={handleDefaultClick}>{'Browse'}</Button> */}
                    <Form.Control
                      type="file"
                      accept="image/*"
                      ref={defaultFileInputRef}
                      onChange={handleDefaultImageAsFile}
                      // style={{ display: "none" }}
                      />
                  </Form.Group>
                {(errors["default_image_url"] && (
                  <span style={styles.validation_error}>{errors["default_image_url"]}</span>
                ))}
                </div>
                )}
            </div>
          :""}
           
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            onClick = {handleFormHide}
            disabled = {duringDefaultImageUpload}
          >
            {t("img_picker_button")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}