import React, { useState, useEffect } from "react";
import { get_user } from "../BookEdit/DBUtils";
import BooksCarousel from "./BooksCarousel";
// import { t, use } from 'i18next';
import {performBookSearch} from '../Common/SearchAPI'
import Container from "react-bootstrap/Container";
import { getCurrentUser } from "../Common/LocalStorage";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const YourStoriesScreen = () => {
  const [user_books, set_user_books] = useState();
  const [user, setCurrentUser] = useState(null);
  const [groupId,setGroupId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const { i18n,t } = useTranslation();
  const params = useParams();
  const getDirection = () => (i18n.language=== "he" ? "rtl" : "ltr");


  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        let currentUser = getCurrentUser();
        setCurrentUser(currentUser);
        if (currentUser && currentUser.email)
        {
          let filters = params.deleted_book_id ? ['creator_email:'+currentUser.email,'deleted:-true','book_id:-'+params.deleted_book_id] :
                                    ['creator_email:'+currentUser.email,'deleted:-true']
          console.log("filtering out:"+filters)
          await performBookSearch('', filters).then((search_response) =>{
            if (search_response && search_response.hits)
              set_user_books(search_response.hits.map(book_result => book_result))
          }).finally(() => {
              setIsLoading(false)
            })
          
          await get_user(currentUser.email).then((user_data)=>{
            if (user_data.organizations && 
                user_data.organizations.groups && 
                user_data.organizations.groups.length > 0 &&
                user_data.organizations.groups[0].group_id){
                  setGroupId(user_data.organizations.groups[0].group_id)
                }   
          })
        }
      } catch (err) {
        console.error("failed to fetch user books.", err);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <Container dir={getDirection()}>
      <h4 className={i18n.language=='he'? 'categoryHeaderHe' : 'categoryHeader'}>{t("your_books_header")}</h4>
      {isLoading ? (
            <div className='categoryHeader'>{t("loading_stories")}</div>
            ) :
        (user && user_books && user_books.length > 0) ? (
          <BooksCarousel id="personal_user_books" 
              books={user_books} 
              user={user.email} 
              getDirection={getDirection}
              groupId={groupId} />
        ) : (
          <div>no-books</div>
        )}
      </Container>
    </div>
  );
};
