import {SearchKeys} from '../BookEdit/Consts'
import algoliasearch from 'algoliasearch/lite';
const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);
const book_index = client.initIndex(process.env.REACT_APP_ALGOLIA_BOOKS_INDEX);
const symbols_index = client.initIndex(process.env.REACT_APP_ALGOLIA_SYMBOLS_INDEX);
const students_index = client.initIndex(process.env.REACT_APP_ALGOLIA_STUDENTS_INDEX);
const users_index = client.initIndex(process.env.REACT_APP_ALGOLIA_USERS_INDEX);

const performSearch = async (query, index, facetFilterArray) =>
{
    console.log("performing search for:"+query+" with filter:"+facetFilterArray+" on index:"+JSON.stringify(index.indexName))
    let results = []
// Perform an Algolia search:
// https://www.algolia.com/doc/api-reference/api-methods/search/
    //await index.search({query})
    await index.search(query,{
        //filters: "filters"
        facetFilters: facetFilterArray
      }).then(function(responses) {
        // Response from Algolia:
        // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
        console.log("hit_count:"+responses.hits.length);
        results = responses
    });
    return results
}

export const performBookSearch = async (query, filters=[]) => {
    let results = []
    await performSearch(query,book_index, filters).then((reponse) => {
        results = reponse
        console.log("book search for query:"+query+", count:"+results.hits.length)
    })
    return results
}

export const performOrgUserSearch = async (org_id, group_id=null) => {
    let results = []
    let filters = ['test_user:-true']
    let searchString = org_id + (group_id ? " && "+group_id : "")
    await performSearch(searchString,users_index,filters).then(response=>{
        results = response
    })
    return results
}

export const performsSymbolSearch = async (query) => {
    let results = []
    await performSearch(query,symbols_index).then((reponse) => {
        results = reponse
    })
    return results
}
export const performsStudentSearch = async (query,filters) => {
    let results = []
    await performSearch(query,students_index,filters).then((reponse) => {
        results = reponse
    })
    return results
}

