import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { uploadSoundToFirebase, generateRandomString } from "../FileStorageUtils";
import { useTranslation } from "react-i18next";
import CloseModalButton from "../../Common/CloseModalButton";
import './SoundPickerDialog.css';
import { BsRecordCircle } from "react-icons/bs";
import { BsMicFill } from "react-icons/bs";

export const SoundPickerDialog = ({ parentOfSoundPickerCallback, show, onHide, getDirection }) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const recordedChunks = useRef([]);

  const handleSoundAsFile = async (e) => {
    const file = e.target.files[0];
    setIsUploading(true);
    try {
      const soundUrl = await uploadSoundToFirebase(file);
      parentOfSoundPickerCallback(soundUrl, file.name);
      setIsUploading(false);
      onHide();
    } catch (error) {
      console.error("Error uploading sound:", error);
      setIsUploading(false);
    }
  };

  const handleRecordClick = async () => {
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const options = { audioBitsPerSecond: 128000, mimeType: "audio/webm" };
        const newMediaRecorder = new MediaRecorder(stream, options);
        console.log("MediaRecorder created with options:", options);

        newMediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            console.log("Data available:", event.data);
            recordedChunks.current.push(event.data);
          }
        };
        newMediaRecorder.onstop = async () => {
          console.log("Recording stopped, recorded chunks:", recordedChunks.current);

          const blob = new Blob(recordedChunks.current, { type: "audio/webm" });
          console.log("Recorded blob:", blob);

          setIsUploading(true);
          try {
            console.log("Uploading blob:", blob);
            const soundUrl = await uploadSoundToFirebase(blob);
            console.log("Sound uploaded, URL:", soundUrl);
            parentOfSoundPickerCallback(soundUrl, `${generateRandomString(16)}.webm`);
            setIsUploading(false);
            onHide();
          } catch (error) {
            console.error("Error uploading sound:", error);
            setIsUploading(false);
          }
          recordedChunks.current = [];
        };
        setMediaRecorder(newMediaRecorder);
        newMediaRecorder.start();
        setIsRecording(true);
      } catch (err) {
        setError("Failed to access microphone");
        console.error(err);
      }
    } else {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  const handleFormClose = () => {
    onHide();
  };

  return (
    <Modal show={show} onHide={handleFormClose} size="lg" centered dir={getDirection()}>
      <Modal.Header>
        <Modal.Title>{t("sound_picker_header")}</Modal.Title>
        <CloseModalButton onClick={handleFormClose} />
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>{t("upload_sound_file")}</Form.Label>
          <Form.Control
            type="file"
            accept="audio/*"
            ref={fileInputRef}
            onChange={handleSoundAsFile}
            disabled={isUploading}
          />
        </Form.Group>
        <Button onClick={handleRecordClick} disabled={isUploading}>
          {isRecording ? t("stop_recording") : t("start_recording")}
        </Button>
        {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleFormClose} disabled={isUploading}>
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
