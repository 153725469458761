import React, {memo} from 'react';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export const ExternalSymbolsCarousel = ({symbols, parentCallbackOnClick}) => {
    const styles = {
        card: {
          border:'none',
          cursor:'pointer',
          fontSize:'12px',
          padding:'5px',
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
        cardImage:{
            height:'50px',
        },
        cardText:{
            textAlign:'center',
        }
    }

    const onSymbolClick = (url) => {
        console.log("clicked symbol path:"+url)
        parentCallbackOnClick(url,true)
    }

    return (
        <div>
            <Row xs={2} sm={3} md={6} lg={7} xl={9} xxl={11} className="g-4" style={styles.row}>
                {
                symbols.map((symbol, index) => (
                <Col key={symbol.url}>
                <Card style={styles.card} onClick={() => onSymbolClick(symbol.url)}>
                    <span><img src={symbol.url} style={styles.cardImage}/></span>
                    <span style={styles.cardText}>{}</span>
                </Card>
                </Col>
                ))}
            </Row>
            
        </div>
    )
}