import React, { useEffect, useState, useCallback } from 'react';
import {getNumberOfPages} from './DBUtils'
import { deletePage } from './DBUtils';
import './editbook.css'
import { BsFillPlusSquareFill, BsFillTrashFill, BsFillPlayFill } from "react-icons/bs";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { DeletePageModalDialog } from './DeletePageModalDialog';
import { HiOutlineBookOpen } from "react-icons/hi";
import { useTranslation } from 'react-i18next';

export const EditBookActionStripe = ({book_id, 
                                    currentPageIndex, 
                                    browse_to_page_parent, 
                                    store_current_book_page_parent, 
                                    create_page_parent,
                                    delete_current_book_page_parent, 
                                    set_page_index_parent, 
                                    noUserCallback,
                                    page_disabled,
                                    page_metadata,
                                    next_page,
                                    prev_page,
                                    viewOnlyMode,
                                    set_progress_parent,
                                    numberOfPages,
                                    setNumberOfPages,
                                    current_page_id,getDirection}) =>
{
    //const [numberOfPages, setNumberOfPages] = useState(1)  
    const [modalDeletePageShow, setModalDeletePageShow] = useState(false);
    const handleDeletePageDialogHide = () => {
        setModalDeletePageShow(false)
    }
    const { t, i18n } = useTranslation();
    useEffect(() => {
        async function getNumberOfPagesFromDB() {
            if (book_id){
                console.log("attempting to get number of pages for book_id:"+book_id)
                getNumberOfPages(book_id).then(response => {
                    console.log("got number of pages:"+response)
                    setNumberOfPages(response)
                },
                (err) => { 
                    console.error("failed to get number of pages for book_id:"+book_id,err)
                })
            }
        }
        getNumberOfPagesFromDB()
    },[])

    const onPageDeletion = async () => {
        delete_current_book_page_parent(next_page,prev_page,decreaseNumberOfPages).then(
            (response) => {
                setModalDeletePageShow(false)
            },
            (err) => {
                console.error("Failed to delete current page",err)
            }
            
            )
    }
    const AskPageDeletion = () => {
        if(!viewOnlyMode){
            setModalDeletePageShow(true)
        }
    }

    const decreaseNumberOfPages = async () => {
        setNumberOfPages(numberOfPages-1);
    }

    const onPageBrowseBack = async () => {
        if (currentPageIndex>1)
            return await onPageBrowse(0)
    }

    const onPageBrowseNext = async () => {
        console.log("numberOfPages "+numberOfPages)
        if (currentPageIndex < numberOfPages)
            return await onPageBrowse(1)
    }

    const onCreateNewPage = () => {
        if(!viewOnlyMode){
            setNumberOfPages(numberOfPages+1)
            //return onPageBrowse(2)
            return create_page_parent(book_id)
        }
    }

    const setPageIndexInStory = (pageIndexToSet) =>{
        console.log("updating page index to:"+pageIndexToSet+", current page index:"+currentPageIndex+", current page id:"+current_page_id)
        set_page_index_parent(pageIndexToSet)
    }

    const calcPageToBrowse = async (pageNumerator) => {
        let result = null
        if (pageNumerator==1 && next_page){ //browse forward with next page in place
            result = next_page
            if (result != current_page_id) setPageIndexInStory(Number(currentPageIndex)+1)
        }
        else if (pageNumerator==-1 && prev_page){ //browse back with prev page in place
            result = prev_page
            if (result != current_page_id) setPageIndexInStory(Number(currentPageIndex)-1)
        }
        console.log("current page index:"+currentPageIndex+", page id:"+current_page_id+", done calculating page-to-browse, got:"+result)
        return result
    }


    const onPageBrowse =  async (direction) => 
    {
        try
        {
            if(!page_disabled)
            {
                set_progress_parent(t("page_navigation_toast"),true)
                await store_current_book_page_parent().then(async(response) => {
                    //if stored succefully, move to next page
                    console.log("stored current page, got response:"+response ? JSON.stringify(response) : response)
                    if (response != false) 
                    {
                        //direction=1 -> move forward, otherwise -> move backwords
                        const pageNumerator = direction==1? 1 : -1;
                        const page_id_to_browse = await calcPageToBrowse(pageNumerator)
                        await browse_to_page_parent(book_id, page_id_to_browse).then(response => {
                            console.log("browsed from page_id:"+current_page_id+" to page:"+page_id_to_browse+", got:"+JSON.stringify(response))
                            set_progress_parent(false)
                        })
                    }
                    else noUserCallback()
                },
                (err) => {
                    console.error("failed to store current book page",err)
                })
            }
            else console.log("tried browsing while page loading - to nothing")
        }
        catch(error)
        {
            console.error("browsing error.",error)
        }
    }
return (

  <div className="action-buttons">
    
       
    
    <span className={
          viewOnlyMode || numberOfPages<=1
            ? "action-btn remove_page_btn disabled"
            : "action-btn remove_page_btn"
        }   onClick={numberOfPages <= 1 ? null : AskPageDeletion}>
      <BsFillTrashFill className="remove-page-icon"/>
      <span className="button-caption">{t("delete_page")}</span>
    </span>
    <span className="navigate_buttons">
      <FaArrowAltCircleRight
        onClick={onPageBrowseBack}
        className={
          currentPageIndex > 1 && !page_disabled
            ? "action-btn browse_back_btn"
            : "action-btn browse_back_btn disabled"
        }
        
      />
      {/* {t("page_indicator")} */}
      <span className="page_indicator">{currentPageIndex}</span>
      <FaArrowAltCircleRight
        onClick={onPageBrowseNext}
        className={
          currentPageIndex < numberOfPages && !page_disabled
            ? "action-btn page_browse_btn_forward"
            : "action-btn page_browse_btn_forward disabled"
        }
      />
    </span>


    <span className={
          viewOnlyMode
            ? "action-btn add_page_btn disabled"
            : "action-btn add_page_btn"
        }   onClick={onCreateNewPage}>
      <span className="button-caption">{t("add_page")}</span>
      <BsFillPlusSquareFill className="add-page-icon" />
      {/*<BsFillTrashFill className="action-btn remove_page_btn" onClick={onPageDeletion}/>*/}
    </span>
    <div>
      <DeletePageModalDialog
      getDirection={getDirection}
        shouldShow={modalDeletePageShow}
        onPageDeletion={onPageDeletion}
        handleDeletePageDialogHide={handleDeletePageDialogHide}
      />
    </div>
  </div>
);

}