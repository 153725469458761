import { t } from 'i18next'
import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import { LoginScreen } from './UserMgmt/LoginScreen'




export const LoginModalDialog = ({shouldShow, onLogin, handleLoginDialogHide, postFirebaseLogin}) =>{

    const style = {
        height:'25px'
      };
    return (
        <Modal 
            show={shouldShow} 
            keyboard={true}
            onHide = {handleLoginDialogHide}>
            {/*<Modal.Header closeButton/>*/}
            <LoginScreen 
                shouldRedirect={false}
                onLogin={onLogin}
                style={style}
                postFirebaseLogin={postFirebaseLogin}
                />
            <div style={style}></div>
        </Modal>
    )
}
