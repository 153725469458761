import React, {useState, useEffect, memo} from 'react';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link,useNavigate } from "react-router-dom";
import { EditBookScreen } from '../BookEdit/EditBookScreen';
import { BiDownload } from "react-icons/bi"
import {BsFillTrashFill } from "react-icons/bs";
import '../Common/bookCard.css'
import { DeleteBookDialog } from '../BookEdit/DeleteBookDialog';
import { get_user, updateSingleMetadataProperty } from '../BookEdit/DBUtils';
import { ToastMessage } from "../BookEdit/ToastMessage";
import { useTranslation } from "react-i18next";
import { BookItemGenerateStory } from './BookItemGenerateStory';


const BooksCarousel = ({books, user, getDirection, groupId=null}) => {

  const { t, i18n } = useTranslation();
  
  const styles = {
    row: {
    },
    card: {
      /*backgroundColor: '#B7E0F2',
      borderRadius: 55,*/
      border:'none',
      cursor:'pointer'
    },
    cardImage: {
      objectFit: 'cover',
      //objectPosition: '0px 20%', //20% from top 
      borderRadius: 30,
      height:'200px'
    },
    cardBody:{
      textDecoration: 'underline white',
      color: 'rgba(0, 0, 0, 0.698)',
      paddingBottom:'5px',
    },
    title:{
      fontSize:'16px'
    },
    text:{
      fontSize:'12px',
      color:'rgba(0, 0, 0, 0.4)',
    },
    footer:{
      fontSize:'14px',
      background:'none',
      paddingTop:'0',
      border:'none',
      textDecoration: 'underline white',
      color:'rgba(0, 0, 0, 0.4)',
    },
    icons:{
      fontSize:'18px',
      paddingBottom:'2px',
    },
    iconsTrash:{
       fontSize:'18px',
      // paddingBottom:'2px',
      float:(i18n.language=='he') ? 'left': 'right',
      color:'#8A459B',
      position: 'relative',
      top: '2px',
      left: '2px'
    }
  }
  
  const navigate = useNavigate();
  const [progressVisable, setProgressVisable] = useState(false);
  const [currentBookId,setCurrentBookId] = useState()
  const [currentBookIndex, setCurrentBookIndex] = useState(0)
  const [booksMap, setBooksMap] = useState(books)
  const [showDeleteBookDialog, setShowDeleteBookDialog] = useState(false);
  const [bookCreatorNames, setBookCreatorNames] = useState({}); 

  useEffect(() => {
    setBooksMap(books);
  }, [books]);


  const onHideDeleteBookDialog = () => {
        setShowDeleteBookDialog(false)
  }
  const handleDeleteBook = async (bookId) => {
  setProgressVisable(true)  
    try {
      await updateSingleMetadataProperty(bookId, "deleted", true).then(response => {
        console.log("marked bookid:"+bookId+" as deleted. operation result:"+response)
      })
    }catch (error) {
        console.log(" Error deleting book: " +error);
    }
    finally{
      setShowDeleteBookDialog(false)
      setProgressVisable(false)
      //remove from current page's array object
      booksMap.splice(currentBookIndex,1)
    }
  }
  const onDeleteClick = (e,book_id,indexToDelete) => {
    setCurrentBookId(book_id)
    e.preventDefault()
    e.stopPropagation()
    console.log("delete button clicked")
    setCurrentBookIndex(indexToDelete)
    setShowDeleteBookDialog(true) 
  }

  const generateBookLink = (book_id) => {
    let uid = book_id.split('_')[0]
    let groupSuffix = groupId ? "/"+groupId :""
    return "/edit/"+uid+"/"+book_id+groupSuffix
  }

  return (
    <div>
      <Row xs={2} sm={3} md={4} lg={7} xl={9} xxl={11} className="g-4" style={styles.row}>
        <BookItemGenerateStory
          styles={styles}
          groupId={groupId}
        />
        {booksMap.map((book, index) => (
        <Link to={generateBookLink(book.book_id)} component={EditBookScreen} key={index}>
          <Col key={book.book_id} style={styles.column}>
            <Card className="book_card" style={styles.card}>
              <Card.Img variant="bottom" src={book.theme_img_url} style={styles.cardImage}/>
              <Card.Body style={styles.cardBody}>
                <Card.Title style={styles.title} className="g-1">{book.book_name}</Card.Title>
                <Card.Text style={styles.text}>
                {`${t("by_name")} ${book.creator_name ? book.creator_name : book.creator_email.split('@')[0]}`}
                </Card.Text>
              </Card.Body>
              <Card.Footer style={styles.footer}>
                <small><BiDownload style={styles.icons}/> {book.downloads}</small>
                {(user==book.creator_email)&&
                <span>
                  <BsFillTrashFill style={styles.iconsTrash} className="trash-icon" onClick={(e) => onDeleteClick(e,book.book_id,index)}/>
                </span>
                }
            </Card.Footer>
            </Card>
          </Col>
        </Link>
        ))}
      </Row>
        <DeleteBookDialog
          book_id={currentBookId}
          show={showDeleteBookDialog}
          handleDeleteBookDialogHide={onHideDeleteBookDialog}
          handleDeleteBook={handleDeleteBook}
          getDirection={getDirection}
        />
        {progressVisable ? (
          <ToastMessage
            header_text={t("delete_book_toast_header")}
            body_text={t("delete_book_toast_body")}
            position="bottom-start"
          />
        ) : null}



    </div>

  );
};

export default memo(BooksCarousel);
