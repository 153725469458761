// import { t } from 'i18next'
import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { ContactUsForm } from '../Common/ContactUsForm';
import { createSearchParams, useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';




export const UpgradeModalDialog = ({show,handleDialogHide,getDirection}) =>{

    const navigate = useNavigate();
    const style = {
        cursor:"pointer",
        textDecoration: "underline"
      };
      const footerstyle = {
        flexDirection: 'column'
      };
      
      
          const {t,i18n} = useTranslation();

        function onPurchaseClick() {
          firebase.analytics().logEvent('purchase_button_click');
           const userAuthId = JSON.parse(localStorage.getItem('user')).uid;
              // Get the user's preferred language from the browser settings
              const userLocaleLanguage = navigator.language;
                (userLocaleLanguage === 'he')?
                    window.location.href = "https://pay.sumit.co.il/4v72wf/5t6kjd?externalidentifier=" + userAuthId //he
                    :
                    window.location.href = "https://pay.sumit.co.il/4v72wf/5t6l3o?externalidentifier=" + userAuthId; //en
        }
        function handleHideAndLogEvent() {
          firebase.analytics().logEvent('cancel_purchase_button_click');
          handleDialogHide();
        }
    return (
        <Modal 
            show={show} 
            onHide = {handleDialogHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dir={getDirection()}
            >
           <Modal.Header> <Modal.Title>{t("upgrade_modal_header")}</Modal.Title></Modal.Header>
            <Modal.Body>{t("upgrade_modal_body")}</Modal.Body>
            <Modal.Footer style={footerstyle}>
                <Button onClick={onPurchaseClick}>{t("upgrade_modal_button")}</Button>
                <div style={style} onClick={handleHideAndLogEvent}>{t("upgrade_modal_link")}</div>
            </Modal.Footer>
        </Modal>
    )
}
