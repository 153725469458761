import React, { useCallback, useState, useEffect, useRef } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { BookSideBar } from "./BookSideBar";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import BookPage from "./BookPage";
import {
  readBookMetadata,
  addPage,
  readPage,
  updateNextPagePointer, 
  updatePrevPagePointer,
  updateLastCreatedPage,
  updateBookMetadata,
  deletePage,
  updatePrivateInfoOnBook,
  RemovePrivateInfoOnBook,
  getPrivateImg,
  updateBookDownloads,
  copyBook,
  removePrivateImg,
  getUserStudents,
  replaceImgDescOnBook,
  createUser,
  updateFirstPageRef,
  getGroupStudents,
  getSingleGroupStudent,
  getStudent,
  get_user
} from "./DBUtils";
import "../Common/workplace.css";
import { ToastMessage } from "./ToastMessage";
import update from "immutability-helper";
import { v4 as uuidv4 } from "uuid";
import { ItemTypes, LocalStorageKeys, Locations, DefaultImageMetrics, DefaultTextBoxMetrics, BoardMetrics, BookPermissions, UserPermission } from "./Consts";
import "./Pieces/image_editor.css";
import "./editbook.css";
import {
  getCurrentUser,
  useStateWithLocalStorage,
  readPageFromLocalStorage,
  setBookPage
} from "../Common/LocalStorage";
import { PublishBookDialog } from "./PublishBookDialog";
import { EditBookActionStripe } from "./EditBookActionStripe";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PrivateModalDialog } from "../BookSearch/PrivateModalDialog";
import { PersonalContentModalDialog } from "./PersonalContentModalDialog";
import { ChangeImagePrivateStateDialog } from "./ChangeImagePrivateStateDialog";
import { jsPDF } from "jspdf";
import { encryptImage, uploadToFileStorage} from './FileStorageUtils'
import { EditBookGlobalActions } from "./EditBookGlobalActions";
import { font } from "./Fonts/heebo-reg-font";
import { davidfont } from "./Fonts/david-reg-font";
import { openfont } from "./Fonts/opensans-reg-font";
import { arimofont } from "./Fonts/arimo-reg-font";
import { varelafont } from "./Fonts/varelaround-reg-font";
import { robotofont } from "./Fonts/roboto-reg-font";
import { rslabfont } from "./Fonts/robotoslab-reg-font";
import { lorafont } from "./Fonts/lora-reg-font";
import { latofont } from "./Fonts/lato-reg-font";
import { async } from "@firebase/util";
import { BlockMapBuilder } from "draft-js";
import {UpgradeModalDialog } from "../BookSearch/UpgradeModalDialog";
import { isPaidUser, getUserGroup } from '../UserMgmt/UserAdmin'

function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

export const EditBookScreen = ({ user, noUserCallback, userMetaString}) => {
  const params = useParams();
  const { t,i18n } = useTranslation();
  const [toastLoadBody, setToastLoadBody] = useState(null);
  const [currentPageIndex, setPageIndex] = useStateWithLocalStorage(
    LocalStorageKeys.last_visited_page,"1");
  const [bookName, setBookName] = useState(t("default_book_name"));
  const [boxes, setBoxes] = useState([]);
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [progressVisable, setProgressVisable] = useState(false);
  const [book_id, setBookId] = useState(params.bookid); //useStateWithLocalStorage(LocalStorageKeys.current_book_id, params.bookid)
  const [bookMetadata, setBookMetadata] = useState({});
  const [currentpageMetadata, setCurrentPageMetadata] = useState(null);
  const [page_id, setPageId] = useState("")
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [pageUpdatedOnDB, setUpdatedPageOnDB] = useState(null)
  const [currentUser, setCurrentUser] = useState(null);
  const [student_list, setStudentList] = useState({});
  const [privateImagesDesc, setprivateImagesDesc] = useState([]);
  const [last_created_page, setLastCreatedPage] = useStateWithLocalStorage(
    params.bookid+"_"+LocalStorageKeys.last_visited_page,"1");
  const [privateImage, setPrivateImage] = useState({});
  const [viewOnlyMode, setviewOnlyMode] = useState(false);
  const [pageBackground, setPageBackground] = useState("")
  const [nextBoxDistanceFromTop, setNextBoxDistanceFromTop] = useState(DefaultImageMetrics.DISTANCE_BETWEEN_BOXES)
  const [upgradeModalShow, setUpgradeModalShow] = useState(false);
  const navigate = useNavigate();
  const [pdfs_store_completed,setPdfsStoreCompleted]=useStateWithLocalStorage('pdfs_store_completed','false')
  const [convert_to_pdf, setConvertToPdf] = useStateWithLocalStorage('convert_to_pdf', '[]');
  const [numberOfPages, setNumberOfPages] = useState(1)  
  const [clonedFromFreeStory,SetClonedFromFreeStory] = useState(false)
  const [isCreatorStory,setIsCreatorStory] = useState(false)
  const [backgroundAttributes,setBackgroundAttributes] = useState({})
  const [creator_name,setCreatorName] = useState();
  const [showImagePickerFollowingPencilClick, setShowImagePickerFollowingPencilClick] = useState(null);
  const [ownerId,setOwnerId] = useState();
  const [user_data, setUserData] = useState();
  const [external_symbols_counter, setExternal_symbols_counter] = useState(0);
  const [pageOrientation, setPageOrientation] = useState('portrait');
  const [authorName, setAuthorName] = useState(null);

  const getUserData = () => {
    let user_data = {}
    try{
      user_data = JSON.parse(userMetaString)
    }
    catch(error){
      console.error("failed to parse usermetadatastring",error)
    }
    return user_data
  }

    const handlePencilClick = (imgUrl) => {
    if(currentUser.email==bookMetadata.creator_email){  
    console.log("PENCIL CLICKED - IMG URL: "+imgUrl)
    setShowImagePickerFollowingPencilClick(imgUrl);
    }
  };
  const lang=i18n.resolvedLanguage;
    const getDirection = () => (lang === "he" ? "rtl" : "ltr");
    const changePageContent = (boxesToLoad) => {
      setBoxes(boxesToLoad)
      //set page-update-date
      if (page_id)
        localStorage.setItem('page-'+page_id+"-lastupdate", new Date());
    }

    //calc height according to content
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
      React.useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
          setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
          })
        }, 1000)    

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
          window.removeEventListener('resize', debouncedHandleResize)  
        }
    })
    const [ChangeImagePrivateStateModalShow, setChangeImagePrivateStateModalShow] = useState(false); 
    const [ChangeImagePrivateState_isPrivate, setChangeImagePrivateState_isPrivate] = useState(''); 
    const [ChangeImagePrivateState_imgID, setChangeImagePrivateState_imgID] = useState(''); 
    const [ChangeImagePrivateState_imgDesc, setChangeImagePrivateState_imgDesc] = useState(''); 
    function onImgPrivateChange(imgId, isPrivate, desc)
    {
      setChangeImagePrivateState_isPrivate(isPrivate)
      setChangeImagePrivateState_imgDesc(desc)
      setChangeImagePrivateState_imgID(imgId)
      setChangeImagePrivateStateModalShow(true)
       
    }
    const setImgNonPrivate =async (imgID,imageDescription) => {
      try
      {
        await getImgUrl(imageDescription,true).then(imgURL =>{
          let boxIndexToUpdate = boxes.findIndex(box => box.id == imgID);
          let boxToUpdate = {
              [boxIndexToUpdate]: {
                  $merge: { 'isPrivate':false,'content':imgURL },
              },
            }
          RemovePrivateInfoOnBook(imageDescription,book_id)
          changePageContent(update(boxes, boxToUpdate));
         })
      }
      catch(error)
      {
        console.log("failed to update private false for box.id:"+imgID)
      }
    }

    const setImgDescription =async (imgID,oldImageDescription,newImageDescription) => {

      await replaceImgDescOnBook(book_id,oldImageDescription,newImageDescription).then((res)=>{
        let boxIndexToUpdate = boxes.findIndex(box => box.id == imgID);
        boxes[boxIndexToUpdate].content=newImageDescription;
      })
    }
    const setImgPrivate =async (imgID,imageDescription) => {
      try
      {
        let boxIndexToUpdate = boxes.findIndex(box => box.id == imgID);
        let imgURL = boxes[boxIndexToUpdate].content
        updateImgPrivateInfo(imgURL,imageDescription)
        let boxToUpdate = {
            [boxIndexToUpdate]: {
                $merge: { 'isPrivate':true,'content':imageDescription },
            },
          }
        changePageContent(update(boxes, boxToUpdate));
        
      }
      catch(error)
      {
        console.log("failed to update private true for box.id:"+imgID)
      }
    }
    const ChangeImagePrivateStateDialogHide=()=>{
      setChangeImagePrivateStateModalShow(false)
    }


    const [modalPersonalContentShow, setModalPersonalContentShow] = useState(false);
    const [modalPrivateShow, setModalPrivateShow] = useState(false);

    const handlePrivateStoryDialogHide = () => {
        setModalPrivateShow(false)
        // window.location.replace("http://"+window.location.host)
        history.back()
    }
    const SwitchPrivate = () => {
        setModalPrivateShow(true)
    }

    const showUpgradeModal = () => {
      setUpgradeModalShow(true)
    }
  
    const hideUpgradeModal = () => {
      setUpgradeModalShow(false)
    }
    const populateBookMetaData = async (book_id) => {
        let result = ""
        await readBookMetadata(book_id).then((response => {
            console.log("response.student_id:"+response.student_id)
            setBookMetadata(response)
            result = response
        }))
        return result
    }
    const [isPermittedUser, setisPermittedUser] = useState(false);
    const getStudents = params.groupid ? getGroupStudents : getUserStudents
    const getSingleStudent = params.groupid ? getSingleGroupStudent : getStudent
    useEffect(() => {
    async function fetchData() {
      try {
        let currentUser = getCurrentUser();
        if (currentUser){
          setCurrentUser(currentUser);
          let owner_id = params.groupid ? params.groupid : currentUser.email
          setOwnerId(owner_id)
          await getStudents(owner_id).then((response)=>{
            console.log("mapping students");
            if (typeof response === 'object')
            {
              let studentList = Object.keys(response).map((id)=>({
                value: params.groupid ? response[id].key.toLowerCase() : id.toLowerCase(),
                label:response[id].name ? response[id].name : id,
              }))
              setStudentList(studentList)
            }
          })
        }
      } 
      catch (err) {
        console.error("failed to fetch students.", err);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchUsersData() {
      try {
          let user_data = getUserData()
          let user_permission = user_data.permission ? user_data.permission : null
          let userGroup = getUserGroup(user_data)
          if (userGroup && userGroup.permission){
            user_permission = userGroup.permission
          } 

          getUserGroup(user_data)
          await isPaidUser(user_permission).then((res) => {
            setisPermittedUser(res)
            console.log("isPermittedUser"+res);
          });            
      } 
      catch (err) {
        console.error("failed to users file.", err);
      }
    }
    fetchUsersData();
    }, []);
    
      useEffect(() => {
    const fetchCreatorName = async () => {
        let currentUser = getCurrentUser();
        if (currentUser)
        {
          let creatorName = await getFirstName(currentUser.displayName, currentUser.email);
          setCreatorName(creatorName);
        }
    };

    fetchCreatorName();
}, []);

    const getFirstName = (creator_name, email) =>{
    let result = email.includes('@') ? email.split('@')[0] : email
    if (creator_name) {
    if (creator_name.includes(' ') && creator_name.split(' ') && creator_name[0]) {
          result = creator_name.split(' ')[0]
    }else{
          result = creator_name
        }
      }
      return result
    } 


    const generateNewBookId = async (currentUser) => {
      console.log("attempting to generate newbook id for user:"+currentUser)
      let updatedBookId = book_id ? book_id : getCurrentUser().uid+"_"+Date.now()
      console.log("updating bookID to be: "+updatedBookId)
      let current_page_id = updatedBookId+"_1"
      setBookId(updatedBookId)
      setPageId(current_page_id)
      setNextPage(null)
      setPrevPage(null)
      let groupid = params.groupid ? params.groupid : null
      let default_permission = BookPermissions.PRIVATE
      await updateBookMetadata(currentUser.email,updatedBookId,"","","","","","","","","",
                                current_page_id,current_page_id, default_permission,"",{},'false',
                                creator_name,external_symbols_counter, pageOrientation, groupid).then(async(updatedMetaData) => {
        console.log("done updating db, now attempting to store page and set metadata. updatedMetatata:"+updatedMetaData ? JSON.stringify(updatedMetaData): "")
        await storeCurrentBookPage(null, null, updatedBookId, current_page_id, pageBackground).then(()=>{
          console.log("done storing page:"+current_page_id+", book_id:"+updatedBookId)
          if(updatedMetaData){
              console.log("setting book metadata to:"+JSON.stringify(updatedMetaData))
              setBookMetadata(updatedMetaData)
            }
          })
        })
    }
    
    useEffect(() => {
        //load page content based on page index - run once only
        async function fetchPageContent() {
          try{
              console.log("rendering page. params.userid:"+params.userid+", params.bookid:"+params.bookid+", book_id:"+book_id+", params.groupid:"+params.groupid)
              //if user logged in - enable book view, otherwise require login
              let currentUser = getCurrentUser()
              if (currentUser && currentUser.email){
                  setCurrentUser (currentUser)
                  if (book_id)
                  {
                      //if not users' book - show tailor dialog
                      if(params.userid && 
                          params.userid!=currentUser.email && 
                          params.userid!=currentUser.uid)
                        {
                          setProgressVisable(true);
                          await readBookMetadata(book_id).then( response => {
                            if(response.private_content){
                              setprivateImagesDesc(Object.keys(response.private_content))
                            }
                            setviewOnlyMode(true);
                          })
                        }
                        else
                        {
                          await readBookMetadata(book_id).then( response => {
                            console.log("show:"+modalPersonalContentShow)
                            if(response.private_content){
                              setprivateImagesDesc(Object.keys(response.private_content))
                            }
                            console.log("setprivateImagesDesc.then");
                          })
                        }
 
                        let bookIdToFetch = params.bookid || book_id
                        populateBookMetaData(bookIdToFetch).then(async response => {
                            console.log("done populating book_id:"+bookIdToFetch+" metadata")
                            console.log("setting book name to:"+response.book_name)
                            setBookName(response.book_name)
                            //setBookProps(response)
                            setBookMetadata(response)
                            setPageId(response.first_page)
                            setLastCreatedPage(response.last_created_page)
                            setExternal_symbols_counter(response.external_symbols_counter)
                            setPageOrientation(response.page_orientation || pageOrientation)
                            // Check if the book is free
                            if (response.is_free === 1) {
                              setisPermittedUser(true);
                            } else {
                              // Check if the free book is cloned and created by the current user
                              if (response.story_copy_disallowed && response.cloned_from && response.creator_email === currentUser.email) {
                                setviewOnlyMode(false);
                                SetClonedFromFreeStory(true);
                                setIsCreatorStory(true);
                              }
                            }

                            let userGroupId = null
                            let userData = {}
                            try
                            {
                              userData = await get_user(currentUser.email)
                              setUserData(userData)
                              if (currentUser && currentUser.email)
                                if (userData.organizations && userData.organizations.groups && userData.organizations.groups.length > 0)
                                  userGroupId = userData.organizations.groups[0].group_id
                            }
                            catch(err)
                            {
                              console.error("failed to fetch user data from db for:"+currentUser.email, err)
                            }

                            //if story owned by user or isn't private -> allow access
                            if (response.creator_email == currentUser.email || 
                                (response.permission && response.permission == BookPermissions.PUBLIC) ||
                                (response.permission && response.permission == BookPermissions.ORG_PUBLIC && 
                                    response.group_id && response.group_id == userGroupId)
                              )
                            {           
                              if (params.bookid && currentPageIndex == 1)
                              {
                                  console.log("populating page 1 on bookid:"+params.bookid)
                                  if(response.first_page!=''&response.first_page!=null&response.first_page!=undefined){
                                      browseToPage(bookIdToFetch,response.first_page).then(
                                          console.log("browsed to page:"+response.first_page)
                                      )
                                  }
                                  else{
                                      let page_id = book_id+"_"+currentPageIndex
                                      browseToPage(bookIdToFetch,page_id).then(
                                          console.log("browsed to page:"+currentPageIndex)
                                      )
                                  }
                              }
                              //allow edit mode for admins
                              if ((response.permission == BookPermissions.ORG_PUBLIC &&
                                  userData && userData.permission == UserPermission.ORG_ADMIN) || 
                                  (response.permission == BookPermissions.PUBLIC && 
                                    userData && userData.permission == UserPermission.OZZY_PUBLISHER)){
                                    setviewOnlyMode(false);
                              }
                            }
                            else{
                                  SwitchPrivate()
                                }
                        })
                      //}
                    }
                    //end if (book_id)
                    else 
                    {
                      await generateNewBookId(currentUser)
                    }
                    }//end if(params.userid!=currentUser.email) else
            //    }//end if currentUser
                else noUserCallback()
                    }
          catch(err){
            console.error("failed to fetch page:"+currentPageIndex+" content",err)
          }
        }
        fetchPageContent()
      },[,book_id])

    const addToBoxes = async (boxToAdd) => {
        console.log("BOX TO ADD " + boxToAdd.top)
        if(boxToAdd.top<0){boxToAdd.top=5}
        if(boxToAdd.top+DefaultImageMetrics.SYMBOL_HEIGHT>BoardMetrics.BOARD_HIEGHT)
        {
          boxToAdd.top=150
        }
        changePageContent((box) => [...box, boxToAdd])
        console.log("added new box. box.id="+boxToAdd.id+". boxes.length="+boxes.length)
        //setNextBoxDistanceFromTop
    }

    const moveBox = useCallback((id, left, top) => {
        try
        {
          let boxIndexToUpdate = boxes.findIndex(box => box.id == id);
          let boxToUpdate = {
              [boxIndexToUpdate]: {
                  $merge: { left, top },
              },
          }
          changePageContent(update(boxes, boxToUpdate));
        }
        catch(error)
        {
          console.log("failed to merge left & top for box.id:"+id+" ,left:"+left+" ,top:"+top)
        }

    }, [boxes, setBoxes]);

    const removeBox = (id) => {
      let box=boxes.filter(item => item.id == id)[0];
      if(box.type==ItemTypes.IMAGE){
        setNextBoxDistanceFromTop(nextBoxDistanceFromTop-(DefaultImageMetrics.IMAGE_HEIGHT+DefaultImageMetrics.DISTANCE_BETWEEN_BOXES))
        if(box.isPrivate){
          removePrivateImg(book_id,box.content);
        }
      }
      if (box.type == ItemTypes.TEXT_BOX){
        setNextBoxDistanceFromTop(nextBoxDistanceFromTop-(DefaultImageMetrics.TEXT_BOX_HEIGHT+DefaultImageMetrics.DISTANCE_BETWEEN_BOXES))
      }
      if (box.type == ItemTypes.SYMBOL){
        setNextBoxDistanceFromTop(nextBoxDistanceFromTop-(DefaultImageMetrics.SYMBOL_HEIGHT+DefaultImageMetrics.DISTANCE_BETWEEN_BOXES))
      }
      if (box.content.startsWith('https://api.arasaac.org')){
        setExternal_symbols_counter(prevValue => prevValue - 1);
        console.log("counter:: " + external_symbols_counter)
      }
        
      changePageContent(boxes.filter(item => item.id !== id));
    }
    
    const onSymbolSelectionActions = (symbolPath) => {
        console.log("selected symbol path:"+symbolPath)
        //setProgressVisable(false);
        let idval = uuidv4()
        addToBoxes({id:idval, top:nextBoxDistanceFromTop, left:130, content:{id:idval ,src: symbolPath}, type:ItemTypes.SYMBOL, width:100}).then(
          setNextBoxDistanceFromTop(nextBoxDistanceFromTop+DefaultImageMetrics.SYMBOL_HEIGHT+DefaultImageMetrics.DISTANCE_BETWEEN_BOXES)
        )
    }
    
    const updateImgPrivateInfo = async (imgurl, desc, defaultImageURL, natWidth, natHeight) => {
      try {
        // Save to state
        let currentPrivateImage = {};
        currentPrivateImage[desc] = imgurl;
        setPrivateImage(currentPrivateImage);
        let encrypted_image = await encryptImage(imgurl);
        // Add to book
        await updatePrivateInfoOnBook(encrypted_image, desc, book_id, defaultImageURL, natWidth, natHeight);
        setProgressVisable(false);
      } catch (error) {
        console.error("Error updating private image info:", error);
        // setProgressVisable(false);
      }
    };
    
    
    const onImgBackgroundActions = (imgUrl, imgName, naturalWidth, naturalHeight,backgroundAttributes) => {
      console.log("updating background image. url="+imgUrl+" ,imgName:"+imgName)
      setProgressVisable(false);
      setPageBackground("center / cover no-repeat url("+imgUrl+")")
      setBackgroundAttributes(backgroundAttributes)
    }

    const onImageCreationActions = /* useCallback(*/(imgUrl, imagename="", is_Private, imageDescription, naturalWidth, naturalHeight,author = null) => {
        //setImgUrl(imgUrl);
        //hide progress bar
        setProgressVisable(false);
        //addToBoxes({id: uuidv4(), top: 90, left: 90, src:imgUrl, imgName:imagename, type:ItemTypes.IMAGE })
        
        /*
        nat-width:3840 nat-height:2160
        height: 150
        width = nat-width/nat-height * 150*/
        let width = Math.round(naturalWidth/naturalHeight*150 * 100) / 100
        console.log("adding initital image box, calculating width:"+width)
        if(is_Private){
          addToBoxes({id: uuidv4(), top: nextBoxDistanceFromTop, left: 90, width:width, height:DefaultImageMetrics.IMAGE_HEIGHT, rotate:0, 
                      content:imageDescription, isPrivate:true, imgName:imagename, type:ItemTypes.IMAGE,...(author && { author }) }).then(
                        setNextBoxDistanceFromTop(nextBoxDistanceFromTop+DefaultImageMetrics.IMAGE_HEIGHT+DefaultImageMetrics.DISTANCE_BETWEEN_BOXES)
                      )
        }
        else{
        addToBoxes({id: uuidv4(), top: nextBoxDistanceFromTop, left: 90, width:width, height:DefaultImageMetrics.IMAGE_HEIGHT, rotate:0, 
                    content:imgUrl, imgName:imagename, type:ItemTypes.IMAGE,...(author && { author }) }).then(
                        setNextBoxDistanceFromTop(nextBoxDistanceFromTop+DefaultImageMetrics.IMAGE_HEIGHT+DefaultImageMetrics.DISTANCE_BETWEEN_BOXES)
                    )
        }
    }//, [boxes, setBoxes]);

    const onSoundCreationActions = (soundUrl, soundName="", is_Private, soundDescription, duration) => {
      setProgressVisable(false);
      addToBoxes({id: uuidv4(),top: nextBoxDistanceFromTop,left: 90,width: 150,height: DefaultImageMetrics.IMAGE_HEIGHT,rotate: 0,
        content: soundUrl,imgName: soundName,type: ItemTypes.SOUND,...(is_Private && { isPrivate: true }),
        ...(soundDescription && { description: soundDescription }),...(duration && { duration }),
      }).then(() => {
        setNextBoxDistanceFromTop(nextBoxDistanceFromTop + DefaultImageMetrics.IMAGE_HEIGHT + DefaultImageMetrics.DISTANCE_BETWEEN_BOXES);
      });
    };
    
    
    const txtBoxCreationActions = () => {
        console.log("attempting to add text edit box. boxes.length="+boxes.length)
        //addToBoxes({id: uuidv4(), top: 90, left: 90, type:ItemTypes.TEXT_BOX, htmlContent:""})
        addToBoxes({id: uuidv4(), top: nextBoxDistanceFromTop, 
                      left: i18n.language=='en' ? DefaultTextBoxMetrics.TEXT_BOX_DISTANCE_FROM_LEFT : DefaultTextBoxMetrics.TEXT_BOX_DISTANCE_FROM_LEFT_RTL, 
                      type:ItemTypes.TEXT_BOX, content:""}).then(
          setNextBoxDistanceFromTop(nextBoxDistanceFromTop+DefaultImageMetrics.TEXT_BOX_HEIGHT+DefaultImageMetrics.DISTANCE_BETWEEN_BOXES)
        )
    }//, [boxes, setBoxes]);    

    function showUploadProgress(imageName)
    {
        setToastLoadBody(imageName);
        setProgressVisable(true);
    }

    function updateImageDimensions(imageName, image_id, width, height, rotate=0,scale=1,border=4)
    {
      try
      {
        showUploadProgress(imageName)
  
        console.log("attempting to merge updated image height:"+height+", width:"+width+" and rotate:"+rotate+" and scale:"+scale)  
        let boxIndexToUpdate = boxes.findIndex(box => box.id == image_id);
        let boxToUpdate = {
            [boxIndexToUpdate]: {
                $merge: { width, height, rotate,scale,border },
            },
          }
          changePageContent(update(boxes, boxToUpdate));
      }
      catch(error)
      {
        console.log("failed to merge width & height for box.id:"+image_id+", imageName:"+imageName+", width:"+width+" ,height:"+height+" ,rotate:"+rotate)
      }
    }

    function hideUploadProgress()
    {
        setProgressVisable(false);
    }

    const onUploadTextDone = (content, id, width, text_left) =>{
        //console.log("attempting to add src field to newly upadted text file. src="+src+" id="+id)
        console.log("attempting to update html content. htmlContent="+content+" id="+id)
        let boxIndexToUpdate = boxes.findIndex(box => box.id == id);
        let boxToUpdate = {
            [boxIndexToUpdate]: {
                $merge: { content, width, text_left },
            },
        }
        changePageContent(update(boxes, boxToUpdate));
    }

    const getImgUrl=async(content, isPrivate, callback, obj, is_placeholder=true, private_image_url=null) =>{
      let return_val=false;
      let thissite=document.location.origin;
      console.log("attempting to get img url. bookid:"+book_id+",content:"+content+", isPrivate:"+isPrivate+", obj:"+obj+", currentUser.email:"+currentUser.email+", bookMetadata.creator_email:"+bookMetadata.creator_email)
      if(isPrivate)
      {
        let currentUser = getCurrentUser();
          if(currentUser.email==bookMetadata.creator_email){
            await getPrivateImg(book_id,content,thissite).then((response) =>
            {
              if(response){
                return_val=privateImage[content] ? privateImage[content] : response;
                if(callback){
                  let private_img_url = private_image_url ? private_image_url : response
                  callback(private_img_url, obj, is_placeholder);
                }
              }
              else{
                console.log("no info on private_content");
                // return_val=thissite+"/images/private_image.png";
                return_val = privateImage[content] ? privateImage[content] : thissite+"/images/private_image.png" ;
                callback(return_val, obj, is_placeholder);
                setPrivateImage({});
              }
            },
            (err) => {
              //catches the errors
              console.error("failed to read imgurl:"+content+". book_id:"+book_id,err)
          })
          }
          else {
            return_val= await getPrivateImg(book_id, content, thissite, false)
            if(!return_val){
               return_val=thissite+"/images/private_image.png";
            }
            //let is_placeholder=true
            callback(return_val,obj,is_placeholder);
          }
      }
      else{
        return_val= content;
      }
      return return_val;
  }
    

    const browseToPage = (async (book_id, pageIdToBrowse) => {
      setNextBoxDistanceFromTop(DefaultImageMetrics.DISTANCE_BETWEEN_BOXES)
      let result = false
      if (pageIdToBrowse != page_id) //do nothing if current-page is also the one to browse
      {
        await readPageFromLocalStorage(book_id, pageIdToBrowse).then((response) =>
        {
            response!=null&&response.page_id!=null ? setPageId(response.page_id) : setPageId("")
            response!=null&&response.content!=null ? setBoxes(response.content) : setBoxes([])
            response!=null&&response.next_page!=null ? setNextPage(response.next_page) : setNextPage(null)
            response!=null&&response.prev_page!=null ? setPrevPage(response.prev_page) : setPrevPage(null)
            response!=null&&response.background!=null ? setPageBackground(response.background) : setPageBackground("#FFFFFF")
            response!=null&&response.background!=null ? setBackgroundAttributes(response.background_attributes) : setBackgroundAttributes({})
            response!=null&&response.updated_on!=null ? setUpdatedPageOnDB(response.updated_on) : setUpdatedPageOnDB(null)
            result = true
        },
        (err) => {
          //catches the errors
          console.error("failed to read page:"+pageIdToBrowse+"'s content. book_id:"+book_id,err)
        })
      }
      setProgressVisable(false)
      return result
  })

    const createNewPage = (async (book_id) => {
      setProgressVisable(true)
      setPdfsStoreCompleted(false)
      setToastLoadBody(t("new_page_creation_toast"))
      try
      {
        var lastpage = last_created_page || bookMetadata.last_created_page_id || book_id+"_1";
        var lastpagesplited = lastpage.split('_');
        var index = lastpagesplited[lastpagesplited.length -1];
        var newPageIndex=parseInt(index)+1;
        var newPageId=book_id+"_"+newPageIndex;

        // go to current page's next page -> then update it's next page pointer to point 
        //if (nextPage) updatePageNextPrev(book_id, nextPage,newPageId,1).then(() => 
        /* 
        1. case 1: some@email.com_7_1 -> some@email.com_7_2 -> some@email.com_7_3
            if adding a page after page_id:some@email.com_7_2ֿ
            new page ID: some@email.com_7_10
            a. go to some@email.com_7_3 - update it's prev_page pointer to point some@email.com_7_10
            b. once done - update next_page pointer on some@email.com_7_2, to point some@email.com_7_10
        2. case 2: if there isn't a next page: some@email.com_7_1 -> some@email.com_7_2
            if adding a page after page_id:some@email.com_7_2ֿ
            new page ID: some@email.com_7_10
            since some@email.com_7_2ֿ is the last page, there's no current next_page to udpate so do nothing
        */

        console.log("attemption to update "+nextPage+"'s previous page's pointer - will not update if null")
        updatePrevPagePointer(book_id, nextPage, newPageId).then(() => {
          console.log("updated prev_page pointer of page_id:"+nextPage+" to point to newly created page_id:"+newPageId)
          updateNextPagePointer(book_id, page_id, newPageId).then(() => {
            console.log("updated page_id:"+page_id+"'s next_page pointer, to point"+newPageId)

            //store current page then switch content to new page
            storeCurrentBookPage(newPageId, prevPage).then((response)=>{
              let currentUser = getCurrentUser();
              let pdfURL = response && response.pdfURL ? response.pdfURL : ""
              addPage(book_id, currentUser.email, currentUser.displayName, '0', currentUser.uid, 
                      [],newPageId, nextPage, page_id, pdfURL, pageBackground,lang).then(async (response) => {
                        //if stored succefully, move to next page
                  await updateLastCreatedPage(book_id,newPageId).then(async() => {
                    setLastCreatedPage(newPageId)
                    if (currentPageIndex) setPageIndex(Number(currentPageIndex)+1)
                    //done updating current and new page, now browse to next page
                    await browseToPage(book_id,newPageId).then(() =>{
                      console.log("browsed to page:"+currentPageIndex)
                    })
                    
                });
                console.log("stored page, got response:"+response)
                setProgressVisable(false)
              },
              (err) => {
                  console.error("failed to store book page",err)
              })
            })
          })
        },
        (err) => {
            //catches the errors
            console.log("error Update prev on page "+nextPage, err)
        })
      }
      catch(err){
        console.error("failed to get last_created_page_id number:"+bookMetadata.last_created_page_id+". book_id:"+book_id,err)
      }

  })

    const addCurrentPageToDB = async (book_id, currentUser, next_page, prev_page, current_page = page_id, pdfURL, page_background = pageBackground,background_attributes) => {
        await addPage(book_id, currentUser.email, currentUser.displayName, '0', currentUser.uid, 
                boxes,current_page, next_page, prev_page, pdfURL, page_background,lang,background_attributes).then(() => 
                    {
                    console.log('Added new page in book:'+book_id+' for user:'+currentUser.email+". page_id:"+current_page);
                    //reset boxes
                    //setBoxes([])
                    },
                    (err) => {
                        //catches the errors
                        console.log("error adding page "+current_page+" to db", err)
                    })
    }
    const storeCurrentPageCheckLocalChange = async () =>
    {
      return storeCurrentBookPage(nextPage, prevPage, book_id, page_id, pageBackground, false)
    }

    const shouldStoreCurrentPageToDB = (book_id, page_id, skipLocalUpdateCheck) =>{
      let shouldStoreToDB = true
      const newlyClonedStoryPDFUpdated = localStorage.getItem(book_id+'-just-cloned-pdf-updated')
      const pageLastUpdateLocally = localStorage.getItem('page-'+page_id+'-lastupdate')
      const pageLastUpdateLocallyDate = pageLastUpdateLocally ? new Date(pageLastUpdateLocally) : null
      const pageUpdateOnDB = pageUpdatedOnDB ? new Date(pageUpdatedOnDB) : 0
      shouldStoreToDB = skipLocalUpdateCheck || !JSON.parse(newlyClonedStoryPDFUpdated) || (pageLastUpdateLocallyDate > pageUpdateOnDB)
      return shouldStoreToDB
    }

    const storeCurrentBookPage = async (next_page = nextPage, prev_page = prevPage, current_book_id = book_id, current_page = page_id, page_background = pageBackground, skipLocalUpdateCheck = true, background_attributes=backgroundAttributes) =>{
        let result = false
        try
        {
            if (shouldStoreCurrentPageToDB(book_id,page_id,skipLocalUpdateCheck))
            {
              let currentUser = getCurrentUser()
              if (currentUser && currentUser.email)
              {
                console.log("bookid already set. found book_id:"+current_book_id+". Attemption to store page_id:"+current_page)
                //if book_id exists on session - don't increment it, keep updating current book
                
                //update page json structure on DB
                let pdfURL = ""
                await addCurrentPageToDB(current_book_id, currentUser, next_page, prev_page, current_page, pdfURL, page_background,background_attributes).then(async ()=>{
                    console.log("stored current page:"+current_page+" to DB on bookid:"+current_book_id)
                    result = {'book_id':current_book_id,'pdfURL':pdfURL}
                })
              }
              else {
                  console.log("unlogged-in user attempting to store current book page - popping login dialog first")
                }
            }else {
              console.log("unchanged page:"+page_id+", no data to update on DB")
              result = "skip"
            }
        }
        catch(error)
        {
            console.error("add page error. page_id:"+current_page,error)
        }
        return result
    }

    const onHidePublishDialog = () => {
        setShowPublishDialog(false)
    }
    const copyStoryWithNewPersonal=async(kidName,imagesURL,studentId=null,studentAttributes,privateContentAttributes) => {
      let return_val=false;
      console.log("kidName: "+kidName);
      console.log(imagesURL);
      updateBookDownloads(book_id);
      let bookIndex = Date.now()
      let defaultPrivateContent = {}
      if(bookMetadata.private_content_attributes){
        defaultPrivateContent = bookMetadata.private_content_attributes
      }
      let bookPermission = BookPermissions.PRIVATE
      await copyBook(book_id, getCurrentUser().uid+"_"+bookIndex, getCurrentUser().email,
                      kidName, imagesURL, studentId, studentAttributes, privateContentAttributes, 
                      creator_name, defaultPrivateContent, params.groupid, bookPermission).then((res)=>{
        if(res){
          onBookCopyDone(getCurrentUser().uid+"_"+bookIndex)
        }
        return_val=res;
      })
      return return_val;
  }

    const ViewWithoutTailoring=async() => {
      console.log("on ViewWithoutTailoring");
      firebase.analytics().logEvent('tailor_dailog_exit');
      setToastLoadBody(t("tailoring_story_toast"))
      setProgressVisable(false)
      setModalPersonalContentShow(false);
      if(currentUser.email!=bookMetadata.creator_email) 
          setviewOnlyMode(true);   
    }

    const onBookCopyDone=async(bookid) => {
      let groupIdSuffix = params.groupid ? "/"+params.groupid : ""
      let url="/edit/"+getCurrentUser().uid+"/" + bookid + groupIdSuffix;
      window.location.href=window.location.origin+url;
      setProgressVisable(false)
      localStorage.setItem(bookid+"-just-cloned-pdf-updated", false);
    }

    const setTailorProgress = () =>{
      setToastLoadBody(t("tailoring_story_toast")) 
      setProgressVisable(true)
    }
  
    const onPageDeletion = async (next_page,prev_page,setNumOfPagesFunc) => {
        //if prev page is null - deleting first page - update 2nd page to be the new first (2ndpage.prev = null)
        if (next_page) updatePrevPagePointer(book_id, nextPage, prevPage).then(() =>
          {
            console.log('Updated prev on page:'+next_page+' in book:'+book_id+' for user:'+currentUser.email+'. new prev:'+prev_page);
          },
          (err) => {
              //catches the errors
              console.log("error Update prev on page "+next_page, err)
          })
        //if current page has a prev_page - update prevpage.next = currentpage.next
        if (prev_page) updateNextPagePointer(book_id,prev_page,next_page,0).then(() => 
          {
            console.log('Updated next on page:'+prev_page+' in book:'+book_id+' for user:'+currentUser.email+'. new next:'+next_page);
          },
          (err) => {
              //catches the errors
              console.log("error Update next on page "+prev_page, err)
          })

        await deletePage(book_id,page_id).then(async (response) =>{
                const storedPages = JSON.parse(convert_to_pdf);
                const updatedPages = storedPages.filter((pageID) => pageID !== page_id);
                setConvertToPdf(JSON.stringify(updatedPages));
                if (response)
                {
                    //setNumberOfPages(numberOfPages-1)   
                    //if deletion succedded - browse to previous page if possible, otherwise - to next page
                    if(prev_page){
                        browseToPage(book_id,prev_page);
                        setPageIndex(Number(currentPageIndex)-1);
                    }
                    else{
                        browseToPage(book_id,next_page);
                    }

                    //if first page deleted - update book metadata accordingly
                    if (bookMetadata.first_page == page_id){
                        let updatedMetadata = bookMetadata
                        updatedMetadata.first_page = next_page
                        setBookMetadata(updatedMetadata)
                        //updateBookMetadata(creator_email: any, book_id: any, book_name: any, kid_image: any, kid_age: any, locale: any, category: any, themeImageUrl: any, first_page: any, last_created_page_id: any, permission?: string, student_id?: string, deleted?: string)
                        await updateFirstPageRef(book_id,updatedMetadata.first_page).then(firstPageUpdateResponse => {
                          console.log("first page deleted, updated a new first page pointer. got response:"+firstPageUpdateResponse)
                        })
                        
                    }

                    setNumOfPagesFunc();
                }
            }
        )
    }

    const postPublishActions = (async(book_id, book_name) => {
        setBookName(book_name)
        //using window.location.href to reach full page reload - after changes done on search engine
        setTimeout(() => {
          setShowPublishDialog(false)
          console.log("Hiding publish dialog. got book_name:"+book_name+". got book_id:"+book_id+". redirecting to /yourstories page")
          window.location.href = window.location.origin+"/yourstories"
          //navigate("/yourstories")
        },2000)
    })

    const updatePDFArray = async (pageId, bookId, alreadyConvertedPages) => {
      const arrayFromStorage = JSON.parse(window.localStorage.getItem('convert_to_pdf')) || '[]';

      console.log("pages_convert_to_pdfs: "+arrayFromStorage+" alreadyConvertedPages:"+alreadyConvertedPages)
      if (!arrayFromStorage.includes(pageId)) {
        console.log("adding page_id:"+pageId+" to converted_to_pdfs array")
        setConvertToPdf(JSON.stringify([...arrayFromStorage, pageId]));
      }
      //getNumberOfPages(bookId).then((num_pages)=>{
        console.log("number of created pages for book:"+bookId+" is:"+numberOfPages)
        //check if number of converted pages (including the new one) is just one behind page count
        if (arrayFromStorage.length+1 >= numberOfPages-1){
          console.log("completed storing page pdfs. arrayFromStorage.length:"+arrayFromStorage.length+", num_pages:"+numberOfPages)
          setPdfsStoreCompleted(true)
        }
      //})
    }
  


    const imageToBase64 = async(imageUrl) => {
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const base64String = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
        });
        return base64String.split(',')[1];
    }
    const exportPageToPDF = async (current_book_id=book_id, current_page=page_id) => {
      let result = false
      try
      {      
        let pdf = new jsPDF("portrait", "pt", [510,640]);
        let data = document.querySelector("#book-page");

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        // pdf.setFillColor(pageBackground);
        // pdf.rect(0, 0, pageWidth, pageHeight, "F");

        // Add page background if it's a color
      if (/^#[0-9A-F]{6}$/i.test(pageBackground)) {
          pdf.setFillColor(pageBackground);
          pdf.rect(0, 0, pageWidth, pageHeight, 'F');
          }else if(pageBackground.includes('url')){        
      // Add page background if it's an image
        const url = pageBackground.match(/url\((.*?)\)/)[1];
        const base64Image = await imageToBase64(url);
        pdf.addImage(base64Image, 'PNG', 0, 0, pageWidth, pageHeight);
    }

        //localize doc font 
        if (i18n.language=='he') {
          pdf.addFileToVFS('Heebo-Regular-normal.ttf', font);
          let selectedFont = "Heebo" //"Helvetica"
          pdf.addFont('Heebo-Regular-normal.ttf', "Heebo", 'normal');
          pdf.setFont(selectedFont);
          pdf.setR2L(true)
          console.log("set PDF font to:"+selectedFont)


          pdf.addFileToVFS('DavidLibre-Regular-normal.ttf', davidfont);
          let selectedFont1 = "David Libre" //"Helvetica"
          pdf.addFont('DavidLibre-Regular-normal.ttf', "David Libre", 'normal');
          pdf.setFont(selectedFont1);
          pdf.setR2L(true)
          console.log("set PDF font to:"+selectedFont1)

          pdf.addFileToVFS('Arimo-Regular-normal.ttf', arimofont);
          let selectedFont2 = "Arimo" //"Helvetica"
          pdf.addFont('Arimo-Regular-normal.ttf', "Arimo", 'normal');
          pdf.setFont(selectedFont2);
          pdf.setR2L(true)
          console.log("set PDF font to:"+selectedFont2)

          pdf.addFileToVFS('OpenSans-Regular-normal.ttf', openfont);
          let selectedFont3 = "Open Sans" //"Helvetica"
          pdf.addFont('OpenSans-Regular-normal.ttf', "Open Sans", 'normal');
          pdf.setFont(selectedFont3);
          pdf.setR2L(true)
          console.log("set PDF font to:"+selectedFont3)

          pdf.addFileToVFS('VarelaRound-Regular-normal.ttf', varelafont);
          let selectedFont4 = "Varela Round" //"Helvetica"
          pdf.addFont('VarelaRound-Regular-normal.ttf', "Varela Round", 'normal');
          pdf.setFont(selectedFont4);
          pdf.setR2L(true)
          console.log("set PDF font to:"+selectedFont4)

       
        }
        else{
          pdf.addFileToVFS('Roboto-Regular-normal.ttf', robotofont);
          let selectedFont5 = "Roboto" //"Helvetica"
          pdf.addFont('Roboto-Regular-normal.ttf', "Roboto", 'normal');
          pdf.setFont(selectedFont5);
          console.log("set PDF font to:"+selectedFont5)

          pdf.addFileToVFS('RobotoSlab-Regular-normal.ttf', rslabfont);
          let selectedFont6 = "Roboto Slab" //"Helvetica"
          pdf.addFont('RobotoSlab-Regular-normal.ttf', "Roboto Slab", 'normal');
          pdf.setFont(selectedFont6);
          console.log("set PDF font to:"+selectedFont6)

          pdf.addFileToVFS('Lora-Regular-normal.ttf', lorafont);
          let selectedFont7 = "Lora" //"Helvetica"
          pdf.addFont('Lora-Regular-normal.ttf', "Lora", 'normal');
          pdf.setFont(selectedFont7);
          console.log("set PDF font to:"+selectedFont7)

          pdf.addFileToVFS('Lato-Regular-normal.ttf', latofont);
          let selectedFont8 = "LLatoora" //"Helvetica"
          pdf.addFont('Lato-Regular-normal.ttf', "Lato", 'normal');
          pdf.setFont(selectedFont8);
          console.log("set PDF font to:"+selectedFont8)

          pdf.addFileToVFS('OpenSans-Regular-normal.ttf', openfont);
          let selectedFont3 = "Open Sans" //"Helvetica"
          pdf.addFont('OpenSans-Regular-normal.ttf', "Open Sans", 'normal');
          pdf.setFont(selectedFont3);
          console.log("set PDF font to:"+selectedFont3)
        }

        await pdf.html(data).then(async () => {
          //store pdf on file storage
          let page_blob = pdf.output('blob',current_page)
          page_blob.name = current_page
          await uploadToFileStorage(page_blob, Locations.PDF_FOLDER+'/'+current_book_id+"/").then(
              async(responseURL) => {
                console.log("done uploading page_blob. uploaded to url:" + responseURL);
                await updatePDFArray(current_page, current_book_id, convert_to_pdf)
                result = responseURL
              },
              (error) => {
                console.log("failed to upload page_blob to file storage", error);
              })
      })
      }
      catch (err){
        console.error("failed to export page to pdf",err)
      }
      finally{
        return result;
      }
    }

    useEffect(() => {
      const handlePasteAnywhere = event => {
        console.log(event.clipboardData.getData('text'));
      };
  
      window.addEventListener('paste', handlePasteAnywhere);
  
      return () => {
        window.removeEventListener('paste', handlePasteAnywhere);
      };
    }, []);

    const setProgessAndToast = (text=t('default_toast_text'),progressState) => {
        setToastLoadBody(text)
        setProgressVisable(progressState)
    }

    const uploadPastedData = async (blob) => {
      // Check if the blob is already a JPEG
      if (blob.type === 'image/jpeg') {
        // Handle the JPEG blob directly
        let img_name = "pastedAt_" + Date.now() + ".jpeg";
        setProgressVisable(true);
    
        await uploadToFileStorage(blob, Locations.IMAGE_FOLDER + getCurrentUser().uid, img_name).then(
          (responseURL) => {
            if (responseURL) {
              // Create a new Image object from the responseURL
              let uploadedImg = new Image();
              uploadedImg.src = responseURL;
    
              uploadedImg.onload = function () {
                console.log("nat-width:" + this.naturalWidth + " nat-height:" + this.naturalHeight);
                // Trigger your onImageCreationActions function with the updated parameters
                onImageCreationActions(responseURL, img_name, false, "", this.naturalWidth, this.naturalHeight);
              };
            }
            setProgressVisable(false);
          }
        );
        return;
      }
    
      const reader = new FileReader();
      reader.onload = async () => {
        const img = new Image();
        img.src = reader.result;
    
        img.onload = () => {
          // Create a canvas element
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
    
          // Set canvas dimensions to match the image
          canvas.width = img.width;
          canvas.height = img.height;
    
          // Fill the canvas with a white background
          ctx.fillStyle = '#FFFFFF';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
    
          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0, img.width, img.height);
    
          // Convert the canvas content to a data URL with JPEG format
          canvas.toBlob(async (jpegBlob) => {
            let img_name = "pastedAt_" + Date.now() + ".jpeg";
            setProgressVisable(true);
    
            // Upload the JPEG blob
            await uploadToFileStorage(jpegBlob, Locations.IMAGE_FOLDER + getCurrentUser().uid, img_name).then(
              (responseURL) => {
                if (responseURL) {
                  // Create a new Image object from the responseURL
                  let uploadedImg = new Image();
                  uploadedImg.src = responseURL;
    
                  uploadedImg.onload = function () {
                    console.log("nat-width:" + this.naturalWidth + " nat-height:" + this.naturalHeight);
                    // Trigger your onImageCreationActions function with the updated parameters
                    onImageCreationActions(responseURL, img_name, false, "", this.naturalWidth, this.naturalHeight);
                  };
                }
                setProgressVisable(false);
              }
            );
          }, 'image/jpeg', 1); // 1 is the JPEG quality, adjust as needed
        };
      };
    
      reader.readAsDataURL(blob);
    };
    
    

    const handlePaste = async (event) => {
      var items = (event.clipboardData || event.originalEvent.clipboardData).items;
      console.log(JSON.stringify(items)); // might give you mime types
      for (var index in items) {
          var item = items[index];
          if (item.kind === 'file') {
              var blob = item.getAsFile()
              await uploadPastedData(blob).then(res => {
                console.log("done uploading pasted image via ctrl+v")
              })
          }
      }
  }

    const styles = {
        height: (dimensions.height-70),

    }

  return (
    <div id={i18n.language=='en'? "main_page" : "main_page_he"}>
      <span className="sidebar">
        <BookSideBar
          uploadedImgURLs={onImageCreationActions}
          onImageUploadActions={showUploadProgress}
          onSoundCreationActions={onSoundCreationActions}
          txtBoxCreationActions={txtBoxCreationActions}
          onSymbolSelectionActions={onImageCreationActions}
          updateImgPrivateInfo={updateImgPrivateInfo}
          viewOnlyMode={viewOnlyMode}
          onBackgroundImageUploadActions={showUploadProgress}
          postBackgroundImageUploadCallback={onImgBackgroundActions}
          pageBackground={pageBackground}
          setPageBackground={setPageBackground}
          getDirection={getDirection}
          book_id = {book_id}
          current_page_id={page_id}
          showImagePickerFollowingPencilClick={showImagePickerFollowingPencilClick}
          setShowImagePickerFollowingPencilClick={setShowImagePickerFollowingPencilClick}
          uploadPastedData = {uploadPastedData}
          user_data={user_data}
          setShowUpgradeModal={setUpgradeModalShow}
          hideUpgradeModal={hideUpgradeModal}
          setExternal_symbols_counter = {setExternal_symbols_counter}
          pageOrientation = {pageOrientation}
          setPageOrientation = {setPageOrientation}
          boxes = {boxes}
          authorName={authorName}
        />
      </span>
      <span className="board" style={styles}>
        <EditBookGlobalActions
            bookMetadata = {bookMetadata}
            setToastVisibleCallback = {setProgressVisable}
            setToastLoadText = {setToastLoadBody}
            book_id = {book_id}
            setBookNameCallback = {setBookName}
            storeCurrentBookPageCallback = {storeCurrentBookPage}
            setShowPublishDialogCallback = {setShowPublishDialog}
            nextPage = {nextPage}
            prevPage = {prevPage}
            viewOnlyMode={viewOnlyMode}
            setModalPersonalContentShow={setModalPersonalContentShow}
            isPermittedUser={isPermittedUser}
            setUpgradeModalShow={setUpgradeModalShow}
            pdfs_store_completed={pdfs_store_completed}
            user_email={getCurrentUser() && getCurrentUser().email ? getCurrentUser().email : null}
            clonedFromFreeStory={clonedFromFreeStory}
            isCreatorStory={isCreatorStory}
        />
        <EditBookActionStripe
            book_id = {book_id}
            currentPageIndex = {currentPageIndex}
            browse_to_page_parent = {browseToPage}
            store_current_book_page_parent = {storeCurrentPageCheckLocalChange}
            delete_current_book_page_parent = {onPageDeletion}
            create_page_parent={createNewPage}
            noUserCallback = {noUserCallback}
            page_disabled = {progressVisable}
            page_metadata = {currentpageMetadata}
            set_page_index_parent={(pageindex) => setPageIndex(pageindex)}
            next_page = {nextPage}
            prev_page = {prevPage}
            viewOnlyMode={viewOnlyMode}
            set_progress_parent={setProgessAndToast}
            numberOfPages={numberOfPages}
            setNumberOfPages={setNumberOfPages}
            current_page_id={page_id}
            getDirection={getDirection}
        />
        <div className="page" onPaste={handlePaste}>
          <DndProvider backend={HTML5Backend}>
            <>
              <BookPage
                boxes={boxes}
                moveBox={moveBox}
                removeBox={removeBox}
                onImageUploadActions={updateImageDimensions}
                onImageUploadDone={hideUploadProgress}
                onUploadTextDone = {onUploadTextDone}
                getImgUrl={getImgUrl}
                viewOnlyMode={viewOnlyMode}
                onImgPrivateChange={onImgPrivateChange}
                pageBackground={pageBackground}
                handlePencilClick={handlePencilClick}
                private_image={privateImage}
                currentPageIndex ={currentPageIndex}
                external_symbols_counter = {external_symbols_counter}
                pageOrientation = {pageOrientation}
              />
            </>
          </DndProvider>
        </div>
        {progressVisable ? (
          <ToastMessage
            header_text={t("tst_load_img_header")}
            body_text={toastLoadBody || "Doing stuff"}
            position="bottom-start"
            containerPosition="absolute"
          />
        ) : null}
      </span>
      <span className="clearfix"></span>
      {book_id ? (
        <React.Fragment>
        <PublishBookDialog
          book_id={book_id}
          show={showPublishDialog}
          onHide={onHidePublishDialog}
          postPublishCallback={postPublishActions}
          book_name_from_parent={bookName}
          user_email={getCurrentUser() && getCurrentUser().email ? getCurrentUser().email : null}
          book_metadata={bookMetadata}
          student_list={student_list}
          getDirection={getDirection}
          isPermittedUser={isPermittedUser}
          creator_name={creator_name}
          getStudents={getStudents}
          ownerId={ownerId}
          setStudentList={setStudentList}
          external_symbols_counter={external_symbols_counter}
          page_orientation={pageOrientation}
        />
        </React.Fragment>
      ) : (
        <div />
      )}
        <div>
        <PrivateModalDialog
        shouldShow = {modalPrivateShow}
        handlePrivateStoryDialogHide = {handlePrivateStoryDialogHide}
        getDirection={getDirection}/>
      
        </div>
        <ChangeImagePrivateStateDialog
        show={ChangeImagePrivateStateModalShow}
        onHide={ChangeImagePrivateStateDialogHide}
        setImgNonPrivate={setImgNonPrivate}
        setImgPrivate={setImgPrivate}
        setImgDescription={setImgDescription}
        imageDescription={ChangeImagePrivateState_imgDesc}
        isPrivate={ChangeImagePrivateState_isPrivate} 
        imgID={ChangeImagePrivateState_imgID}
        getDirection={getDirection}
      />
        <div>
          <PersonalContentModalDialog
            shouldShow = {modalPersonalContentShow}
            imagesList={privateImagesDesc}
            copyStoryWithNewPersonal={copyStoryWithNewPersonal}
            setProgessToast={setTailorProgress}
            viewWithoutTailoring={ViewWithoutTailoring}
            names_list={student_list}
            user_email={getCurrentUser() && getCurrentUser().email ? getCurrentUser().email : null}
            book_metadata={bookMetadata}
            getStudents={getStudents}
            getSingleStudent={getSingleStudent}
            setprivateImagesDesc={setprivateImagesDesc}
            getDirection={getDirection}
            setStudentList={setStudentList}
          />

      <UpgradeModalDialog
        show={upgradeModalShow}
        handleDialogHide={hideUpgradeModal}
        getDirection={getDirection}
        />
       </div>
    </div>
  );
};