import React, { useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';

const CloseModalButton = ({ onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const buttonStyle = {
    border: 'none',
    backgroundColor: 'transparent',
    color: isClicked ? 'purple' : (isHovered ? 'black' : 'rgba(128, 128, 128, 1)'),
    fontSize: '28px',
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    setIsClicked(true);
    onClick();
  };

  return (
    <button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={buttonStyle}
    >
      <IoCloseOutline />
    </button>
  );
};

export default CloseModalButton;