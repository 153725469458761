import React, {useEffect, useRef} from 'react';
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import { Link } from "react-router-dom";
import { BiDownload } from "react-icons/bi"
import '../Common/bookCard.css'
import { useTranslation } from "react-i18next";
import './carousel.css'
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

export const BookItemGenerateStory = ({styles, groupId}) => {

    const { t, i18n } = useTranslation();
    const pathRef = useRef(null);

    useEffect(() => {
    const path = pathRef.current;
      if (path) {
        const svg = path.closest('svg');
  
        const updatePathPosition = () => {
          const { width, height } = svg.getBoundingClientRect();
          const pathBBox = path.getBBox();
          const pathWidth = pathBBox.width;
          const pathHeight = pathBBox.height;
  
          const centerX = width / 2;
          const Y = 150;
          path.setAttribute(
            'transform',
            `translate(${centerX - pathWidth / 2}, ${Y})`
          );
        };
  
        window.addEventListener('resize', updatePathPosition);
        updatePathPosition(); // Initialize center position
  
        return () => {
          window.removeEventListener('resize', updatePathPosition);
        };
      }
    }, []);

    return(
        <Link 
          to={groupId ? "/ai-story/"+groupId : "/ai-story"} 
          key={99999}
          onClick={() => firebase.analytics().logEvent('home:ai-story_crousel_item_clicked')}>
        <Col key="eb2dc3fc-f1e1-4dfa-810a-8f9f26f8b04c" style={styles.column}>
          <Card className="book_card" style={styles.card}>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            style={styles.cardImage}
            preserveAspectRatio="xMidYMid meet"
            >
                <rect
                    width="100%"
                    height="100%"
                    fill="none"
                    stroke="#000" //"#8A459B"
                    strokeWidth="1.5"
                    strokeDasharray="8, 10"
                    rx="30"
                    ry="30"
                >
                </rect>
                <text className="story_body_main" x="50%" y="30%" textAnchor="middle" dominantBaseline="middle">
                    {t('my_ai_story_card_story_body_main')}
                </text>
                <text className="story_body_sub" x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
                    {t('my_ai_story_card_story_body_sub_1')}
                </text>
                <text className="story_body_sub" x="50%" y="60%" textAnchor="middle" dominantBaseline="middle">
                    {t('my_ai_story_card_story_body_sub_2')}
                </text>
                <path 
                    ref={pathRef}
                    d="M7.5 5.6 10 7 8.6 4.5 10 2 7.5 3.4 5 2l1.4 2.5L5 7zm12 9.8L17 14l1.4 2.5L17 19l2.5-1.4L22 19l-1.4-2.5L22 14zM22 2l-2.5 1.4L17 2l1.4 2.5L17 7l2.5-1.4L22 7l-1.4-2.5zm-7.63 5.29a.996.996 0 0 0-1.41 0L1.29 18.96a.996.996 0 0 0 0 1.41l2.34 2.34c.39.39 1.02.39 1.41 0L16.7 11.05a.996.996 0 0 0 0-1.41l-2.33-2.35zm-1.03 5.49-2.12-2.12 2.44-2.44 2.12 2.12-2.44 2.44z"
                />
            </svg>
            <Card.Body style={styles.cardBody}>
              <Card.Title style={styles.title} className="g-1">
                {t('my_ai_story_card_story_name')}
              </Card.Title>
              <Card.Text style={styles.text}>
                {t('my_ai_story_card_by')}
              </Card.Text>
            </Card.Body>
            <Card.Footer style={styles.footer}>
              <large><BiDownload style={styles.icons}/>∞</large>
          </Card.Footer>
          </Card>
        </Col>
      </Link>
    )

}