import React from 'react'
import { t } from 'i18next';
import { Form, Modal } from 'react-bootstrap';
import { update_user_properties } from '../BookEdit/DBUtils';

function ConsentModalDialog({show,getDirection,setShowConsentChecked,user}) {

const handleConsentClick = (async ()=>{
    await update_user_properties(user,new Array('user_consent')).then((res)=>{
          console.log("updated user consent: ", res);
          setShowConsentChecked(false)
    });
})
    
  return (
        <Modal 
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dir={getDirection()}>
           <Modal.Header>{t("privacy_and_terms_header")}</Modal.Header>
            <Modal.Body> {t("privacy_and_terms_body")}</Modal.Body>
        <div className="checkbox-container mb-3">
        <Form.Group controlId="formBasicCheckbox">
          <div className="checkbox-wrapper" style={{ display: "flex", alignItems: "center",gap: "7px",marginBottom:'5px' }}>
            <Form.Check
              type="checkbox"
              onChange={()=>handleConsentClick()}
            />
            <label>{t('login_privacy_checkbox')}
                  {
                    <a className="login_terms_link" href="/privacy" target="_blank" rel="noopener noreferrer">
                      {t('login_privacy')}
                    </a>
                  }
                  {t('login_and')}
                  {
                    <a className="login_terms_link" href="/terms" target="_blank" rel="noopener noreferrer">
                      {t('login_terms')}
                    </a>
                  }</label>
          </div>
        </Form.Group>
      </div>
        </Modal>
  )
}

export default ConsentModalDialog