import React from 'react';
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import ToastHeader from 'react-bootstrap/ToastHeader'
import ToastBody from 'react-bootstrap/ToastBody'
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from "react-i18next";

import "./toast.css";

export const ToastMessage = ({header_text, body_text, position}) => {
    const { i18n } = useTranslation();
    const isHebrew = i18n.resolvedLanguage === "he";

    return (
        <div
        aria-live="polite"
        aria-atomic="true"
        className="bg-dark position-relative"
        dir={isHebrew ? "rtl" : "ltr"}
        >
        <ToastContainer className="p-3 toast-loading" position={position}  >
            <Toast>
                <Toast.Header closeButton={false}>
                    <strong>{header_text}</strong>
                </Toast.Header>
                <Toast.Body>
                <span className='body_text'>{body_text}</span>
                <span style={{float: isHebrew ? 'left' : 'right'}}>
                    <small>            
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </small>
                </span>
                </Toast.Body>
            </Toast>
        </ToastContainer>
        </div>
    )
}