import React, { useState, useEffect } from 'react';

export const TimestampConverter = ({timestamp}) => {
  const [formattedDateTime, setFormattedDateTime] = useState('');

  useEffect(() => {
    const convertTimestamp = () => {
      const date = new Date(timestamp); // Convert seconds to milliseconds
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are zero-based
      const year = date.getFullYear();
      const hour = date.getHours();
      const minute = date.getMinutes();

      // Format the date and time components
      const formattedDateTime = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}, ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

      setFormattedDateTime(formattedDateTime);
    };

    convertTimestamp();
  }, [timestamp]);

  return (
    <span>{formattedDateTime}</span>
  );
}
