import React, { useEffect, useState } from 'react'
import { IoMdAdd } from "react-icons/io";
import EditStudentPage from '../UserMgmt/EditStudentPage';
import {getGroupStudents, get_group_name} from '../BookEdit/DBUtils'
import { Link } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import StudentsCarousel from '../UserMgmt/StudentsCarousel';
import { useTranslation } from "react-i18next";
import Card from 'react-bootstrap/Card'
import { MdAdd } from "react-icons/md";
import './organization.css'
import { updateSingleMetadataProperty } from '../BookEdit/DBUtils';
import { Locations } from '../BookEdit/Consts';

export const OrganizationStudentsPage = ({groupName, userMetaString}) => {

  const [organization_students, set_organization_students] = useState();
  const [user_groupid, setUserGroupId] = useState(null)
  const { i18n,t } = useTranslation();
  const getDirection = () => (i18n.language=== "he" ? "rtl" : "ltr");
  const [orgName, setOrgName] = useState("")

  useEffect(() => {
    async function fetchData() {
      try {
        let userObject = JSON.parse(userMetaString)
        let userData = userObject
        let group_id = null

        if (userData.organizations && userData.organizations.groups && userData.organizations.groups.length>0){
          group_id = userData.organizations.groups[0].group_id
          setUserGroupId(group_id)
          await getGroupStudents(group_id).then((response => {
            set_organization_students(response)
          }))
        }

        if (userData.organizations.id)
        {
          let orgid = userData.organizations.id
          await get_group_name(group_id, orgid, i18n.language).then((response) =>{
            setOrgName(response)
          })
        }

      } catch (err) {
        console.error("failed to fetch group students.", err);
      }
    }
    fetchData();
  }, []);

  const generateAddNewStudentLink = () => {
    return "/editstudent/" + (user_groupid ? +user_groupid+"/?at="+user_groupid : "")
  }

  return (
    <div>
      <Container dir={getDirection()}>
        <h4 className={i18n.language=='he'? 'categoryHeaderHe' : 'categoryHeader' }>{t("organization_students_header")+groupName}</h4>
          {
            <Link to={generateAddNewStudentLink()} component={<EditStudentPage/>} >
                <Button className="add_button mb-4">{t("add_new_student")}<MdAdd/></Button>
            </Link>
          }
            {(organization_students) && (Object.keys(organization_students)).length > 0 ? (
              <StudentsCarousel 
                id="personal_user_students" 
                students={organization_students} 
                user_organization_groupid={user_groupid}
                getDirection = {getDirection}
                deleteStudent = {(studentId) => updateSingleMetadataProperty(studentId, "deleted", true, Locations.USERS_TABLE_STUDENT_FIELD)}
              />
        ) : (
          <div>no-students</div>
        )}
      </Container>
    </div>
  )
}