// import { t } from 'i18next'
import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useTranslation } from "react-i18next";





export const DeleteBookDialog = ({book_id,show,handleDeleteBookDialogHide,handleDeleteBook,getDirection}) =>{

    const style = {
        height:'25px'
      };
          const {t} = useTranslation();
    return (
        <Modal 
            show={show} 
            keyboard={true}
            onHide = {handleDeleteBookDialogHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-container"
            dir={getDirection()}>
           <Modal.Header> <Modal.Title>{t("delete_book_modal_header")}</Modal.Title></Modal.Header>
            <Modal.Body>{t("delete_book_modal_body")}</Modal.Body>
            <Modal.Footer>
                <Button variant="danger"onClick={() => handleDeleteBook(book_id)}>{t("delete_book_modal_button_delete")}</Button>
                <Button variant="secondary" onClick={handleDeleteBookDialogHide}>{t("delete_book_modal_button_cancel")}</Button>
            </Modal.Footer>
        </Modal>
    )
}
