import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import CloseModalButton from '../Common/CloseModalButton';


export const SubMaxStudentsDialog = ({show, handleDialogHide, getDirection}) =>{

    const {t} = useTranslation();
    const navigate = useNavigate();
    
    function openContactUsFilled() {    
    navigate("/contact")
}
    return (
        <Modal 
            show={show} 
            keyboard={true}
            onHide = {handleDialogHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-container"
            dir={getDirection()}>
           <Modal.Header> <Modal.Title>{t("max_students_modal_header")}</Modal.Title>
           <CloseModalButton onClick={handleDialogHide}/>
           </Modal.Header>
            <Modal.Body>{t("max_students_modal_body")}</Modal.Body>
            <Modal.Footer>
                <Button onClick={openContactUsFilled}>{t("max_students_modal_btn")}</Button>
            </Modal.Footer>
        </Modal>
    )
} 