import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Container } from 'react-bootstrap'
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import FloatingLabel from 'react-bootstrap/FloatingLabel'

export const ValidateOrganizationLogin = ({orgId, onOrganizationValidated, orgName, orgLogo, authResult}) =>{
    
    const { t, i18n } = useTranslation();
    let language=i18n.language;
    const getDirection = () => (language === "he" ? "rtl" : "ltr");
    const [inputPass, setInputPass] = useState("")
    const [showNotValidMsg, setShowNotValidMsg] = useState(false)

    const validateOrgToken = async (input_token) => 
    {
        let result = false
        try{
            const validateURL = process.env.REACT_APP_VALIDATE_ORG_TOKEN;
            const validate_call_body = JSON.stringify({ org_id: orgId, user_id: authResult.user.uid, org_token: input_token})
            console.log("export_call_body: "+validate_call_body)
            // Make a POST request to the server to export the story to PDF
            await fetch(validateURL, {
                method: 'POST',
                body: validate_call_body,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json, text/plain, */*'
                },
             })
            .then(res => res.json())
            .then(data => {
                result = data && data.isvalid && data.isvalid=='true'
            })
            .catch((error) => {
                console.error('falied to validate org token for org:'+orgId, error);
          });
        }
        catch(err){
            console.log("failed to validate organization",err)
        }

      return result
    }

    const postOrgValidation = async (e) =>{
        e.preventDefault()
        await validateOrgToken(inputPass).then(result=>{
            if (result){
                onOrganizationValidated()
            }
            else setShowNotValidMsg(true)
        })

    }

    const styles = {
        org_logo:{
            height:'30px',
            marginBottom:'10px',
        },
        invalid_msg:{
            color:'#dd2c00',
            marginTop:'5px'
        }
    }

    return (
        <Container className='form_container'>
            <img style={styles.org_logo} src={orgLogo}/>
            <div>{t('org_valid_header')+" "+orgName}</div>
            <Form onSubmit={postOrgValidation}>
                <FloatingLabel
                controlId="floatingInput"
                label={t('org_valid_pass_lbl')}
                value="password"
                className="mb-2">
                <Form.Control required 
                                type="password" 
                                autoFocus
                                value={inputPass}
                                onChange={(e) => setInputPass(e.target.value)}
                                />
                </FloatingLabel>
                <Button type="submit">{t('org_valid_btn_lbl')}</Button>
                {showNotValidMsg ? <div style={styles.invalid_msg}>{t('org_valid_code_failed')+orgName}</div> : null}
            </Form>
        </Container>
    )
}