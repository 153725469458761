import { UserPermission } from '../BookEdit/Consts';
import { isPaidConsumerUser} from '../BookEdit/FileStorageUtils';

export const isPaidUser = async (user_permission) => {
    let result = false
    if (user_permission && await isPartOfPaidOrg(user_permission)) {
        result = true;
    } else {
        await isPaidConsumerUser().then((res) => {
            result = res || result
            ;});

    }
    return result;
};

  export const isPartOfPaidOrg = async (user_permission) => {
        return user_permission === UserPermission.ORG_USER || 
               user_permission === UserPermission.ORG_ADMIN || 
               user_permission === UserPermission.ORG_GROUP_ADMIN ||
               user_permission === UserPermission.OZZY_PUBLISHER;
    };

export const getUserGroup = (user_data) => {
  let result = {}
  if (user_data && user_data.organizations && 
    user_data.organizations.groups && 
    user_data.organizations.groups.length > 0 &&
    user_data.organizations.groups[0].group_id){
      result.group_id = user_data.organizations.groups[0].group_id
      if (user_data.organizations.groups[0].permission)
      {
        result.permission = user_data.organizations.groups[0].permission
      }
    }
    return result
}

export const CanGenerateStory = async (user_permission,free_generation_quota) =>{
    let result = false
    if((free_generation_quota && free_generation_quota > 0) || (free_generation_quota===null) || await isPaidUser(user_permission)){
      result = true
    }
    return result;
}