export const concatenateVocalizedWords = (jsonData) => {
    let concatenatedWords = '';
    if (jsonData && jsonData.data) {
      concatenatedWords = jsonData.data
        .map(token => token.nakdan.options && token.nakdan.options.length > 0 && token.nakdan.options[0].w  ? 
                          token.nakdan.options[0].w.replace(/\|/g, '')
                        : token.nakdan.word
                        ) // Remove '|' characters
        .join(' ');
    }
    return concatenatedWords;
  }

export  const fetchNikudFromAPI = async (text) => {
    
      const params = {
        "task": "nakdan",
        "useTokenization": true,
        "genre": "modern",
        "data": text,
        "addmorph": false,
        "matchpartial": true,
        "keepmetagim": false,
        "keepqq": false,
        "apiKey": "d587f643-7ae0-4f48-b5a9-76a9ccea5c66"
      };
    
      try {
        const response = await fetch('https://nakdan-5-3.loadbalancer.dicta.org.il/addnikud', {
          method: 'POST',
          body: JSON.stringify(params)
        });
    
        if (response.ok) {
          const jsonResponse = await response.json();
          const concatenatedWords = concatenateVocalizedWords(jsonResponse)
          console.log("Nikud fetched successfully");
          return concatenatedWords;
        } else {
          console.error('Failed to fetch nikud');
        }
      } catch (error) {
        console.error('Error trying to fetch nikud:', error);
      }
    }