import React, {useState} from 'react'
import { BookPermissions } from '../../BookEdit/Consts'
import { t } from 'i18next';
import i18n from "../../i18n";

export const customStyles = {
    control: (base, state) => ({
    ...base,
            '&:hover': { borderColor: '#8A459B' }, // border style on hover
            border: '1px solid lightgray', // default border color
            boxShadow: 'none',  // no box-shadow
              }),
   placeholder: (base) => ({
      ...base,
      textAlign: (i18n.language=='en') ? "left" : "right",
    }),
    option:(base, state)=>({
      ...base,
      textAlign: (i18n.language=='en') ? "left" : "right",
      color: state.isSelected ? 'white' : 'black', // Change text color of selected option
      '&:hover': {
        backgroundColor: '#EDDFF0', // Change hover color when hovering an option
        color: 'black', // Change text color on hover
      },
      backgroundColor: state.isSelected ? '#8A459B' : 'white'
    }),
    singleValue: (provided) => ({
      ...provided,
      //color: '#8A459B', // Change selected value color
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'green' : 'white', // Change background color of dropdown menu when opened
      }),
  };

  export const categoriesOptions = [
    { value: t('cat_conduct_change'), label: t('cat_conduct_change') },
    { value: t('cat_a_new_independance') , label: t('cat_a_new_independance') },
    { value: t('cat_one_time_occuasion') , label: t('cat_one_time_occuasion') },
    { value: t('cat_my_surroundings'), label: t('cat_my_surroundings') },
  ]

//export const [errors, setError] = useState({ book_name: "",student_id:"",theme_image:"" });
const get_property_display_name = (property) => {
    let result = ""
    switch (property){
    case "book_name":
        result = t("upload_book_name_validator")
        break
    case "student_id":
        result = t("upload_student_id_validator")
        break
    case "theme_image":
        result = t("upload_theme_image_validator")
        break
    }
    return result
}

export const postNewStudentCreation = (student_id, book_props, setBookProps) => {
    let updatedBookProps = book_props 
    if (student_id)
    {
        updatedBookProps["student_id"] = student_id.toLowerCase()
        console.log("updated book_props post new student upddate:"+updatedBookProps)
        setBookProps(updatedBookProps)
    }
  }