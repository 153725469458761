import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw, ContentState, SelectionState, RichUtils } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './text_editor.css'
import '.././image_editor.css'
import { AiOutlineDelete } from "react-icons/ai";
import { RxSpeakerLoud } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import { t } from 'i18next';
import { fetchNikudFromAPI } from './NikudUtils';
import {BsPencilFill} from "react-icons/bs";
/*import { Resizable } from 'react-resizable';
import './resizeable.css'*/

export const TextEditor = ({is_shown, txt_id, onUploadTextDone, html_content, read_only, onEditModeChange, width,left}) =>
{
    const { i18n } = useTranslation();
    /*Spot clicking ouside text editor to switch from edit to view mode*/
    const wrapperRef = useRef(null);
    const editorRef = useRef(null)
    const [textHovered, setTextHovered] = useState(false);
    const synth = window.speechSynthesis;
    const [isSpeaking, setSpeaking] = useState(false);
    const [iconTextHovered,setIconTextHovered] = useState(false)
    const [voice, setVoice] = useState('')
    const isContentDeleted = useRef(false)
    let lastTapTime=0;

    //default state is edit mode, unless already occuiped with DB date
    //console.log("html_content="+html_content)
    const [isEditMode, setEditMode] = useState(html_content ? false : true)

    useEffect(() => {
        let voices = synth.getVoices();
        console.log("VOICES: "+JSON.stringify(voices));
        if (i18n.resolvedLanguage === 'he' && voices.some(voice => voice.lang === 'he-IL')){
            setVoice(voices.find(voice => voice.lang === 'he-IL'))
        }
        if (i18n.resolvedLanguage === 'en') {
            setVoice(voices.find(voice => voice.lang.startsWith('en')))
          }
    },[])

  const speakText = async (text) => {
    const utterThis = new SpeechSynthesisUtterance(text);
    utterThis.voice = voice
    // Adjust the speaking pace (rate)
    utterThis.rate = 0.8; // Decrease the value to slow down the pace
    console.log("utterThis: "+JSON.stringify(utterThis.voice))
    console.log("utterThis: "+JSON.stringify(voice))

    let timeoutId;

    const stopPlaying = () => {
        setSpeaking(false);
      };

    synth.speak(utterThis);
    setSpeaking(true);

    utterThis.onend = () => {
        setSpeaking(false);
        clearTimeout(timeoutId);
    };
    
    timeoutId = setTimeout(stopPlaying, 10000);
};

    const handleSpeakerIconClick = async () => {
        console.log("isSpeaking : "+isSpeaking)
        let plainText = editorState.getCurrentContent().getPlainText()
        console.log("editorState getPlainText : "+plainText)
        if (!isSpeaking) {
            if(i18n.language=='he' && contains_heb(plainText)){
                    await punctuateText().then((puncuatedContent)=>{
                    speakText(puncuatedContent);
                })
            }
            else{
                speakText(plainText);
            }
        } 
    };

    const handleEditMode = () => {
        if (!read_only) { // Only allow editing if the component is not set to be read-only
            setEditMode(true);
            onEditModeChange(true); // If you have a callback to manage state outside this component
        }
    };    

    const create_editor_state_from_DB = (htmlContent) => {
        console.log("creating editor state from db. got htmlContent:"+htmlContent)
        let editorStateToReturn = EditorState.createEmpty()
        if (htmlContent)
        {
            console.log("html content pre-loaded. content: "+htmlContent)
            const contentBlock = htmlToDraft(htmlContent);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                editorStateToReturn = EditorState.createWithContent(contentState);
            }
        }
        return editorStateToReturn
    }
    const [editorState,setEditorState] = useState(create_editor_state_from_DB(html_content))
    const [isEditorEmpty,setIsEditorEmpty] = useState(html_content ? false : true)

    function useOutsideAlerter(ref) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
              switchEditMode(false)
              console.log("set as non-edit mode")
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside)
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
          };
        }, [ref]);
      }

      const onTextBoxFocus = () => {
        console.log("textbox focused")
        wrapperRef.current.focus()
        editorRef.current.focusEditor()
      }

      function useInsideAlerter(ref, html_content) {
         useEffect(() => {
        function handleClickInside(event) {
            if (ref.current && ref.current.contains(event.target)) {
                if (event.type === 'dblclick') {
                    switchEditMode(true);
                // event.preventDefault(); // Prevent the default touch event behavior
                } else if (event.type === 'touchstart') {
                const currentTime = new Date().getTime();
                const tapThreshold = 300; // Adjust this value to control the double-tap speed
                 if (currentTime - lastTapTime < tapThreshold) {
                     switchEditMode(true);
                }else{
                    lastTapTime = currentTime
                }
            }

    //     /* set cursor - uncomment only if setEditorState on blur fails to set the cursor
    //     let focus = editorRef.current.getEditorState().getSelection().hasFocus;
    //     console.log("has focus:" + focus);
    //     if (!focus) setEditorState(create_editor_state_from_DB(html_content)); */
      }
    }

    // Bind the event listener
    document.addEventListener('touchstart', handleClickInside);
    document.addEventListener('dblclick', handleClickInside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('touchstart', handleClickInside);
      document.removeEventListener('dblclick', handleClickInside);
    };
  }, [ref, html_content]);
  }

    useOutsideAlerter(wrapperRef);
    useInsideAlerter(wrapperRef, html_content);

    const switchEditMode = (toolbarState) => {
        if(!read_only){
            if(wrapperRef.current.innerText == 'Font\nClick to insert text\n\n'){
            handleDelete()
            }else{
            console.log("switching edit mode to:"+toolbarState)
            setEditMode(toolbarState);
            onEditModeChange(toolbarState)
            }
        }
    }

    const punctuateText = async () => {
        let contentToReturn = editorState.getCurrentContent().getPlainText()
        contentToReturn = await fetchNikudFromAPI(contentToReturn)
        //const newEditorState = EditorState.createWithContent(ContentState.createFromText(contentToReturn))
        //setEditorState(newEditorState)
        return contentToReturn
    }

    const handleDelete = () => {
        console.log("deleting text id="+txt_id)
        is_shown(txt_id)
        isContentDeleted.current = true
    }

    const onEditorStateChange = (editorStateToUpdate) => {
        //contentState
        console.log("setting editor state to:"+JSON.stringify(editorStateToUpdate))
          
        if(JSON.stringify(editorStateToUpdate).includes("fontfamily")){
                const x=document.querySelectorAll(".public-DraftStyleDefault-rtl span");
                console.log(x);
                
        }
            
        setEditorState(editorStateToUpdate)
        //mark if editor box is empty or contains text
        editorStateToUpdate.getCurrentContent().getFirstBlock().text.localeCompare('')==0 ?
        setIsEditorEmpty(true)
        :
        setIsEditorEmpty(false)

        //console.log('focusing on text editor, on editor state load')
        wrapperRef.current.focus();
        editorRef.current.focusEditor();
    }

    const textUploadCallback = (storageRef) => {
        console.log("succesfully uploaded text to cloud storage at "+storageRef)
        //call parent with storageRef to store it on DB for future retreival
        onUploadTextDone(storageRef)
    }

    const contains_heb = (str) => {
        return (/[\u0590-\u05FF]/).test(str);
    }

    const onTextEditBlur = async () => {

        //convert to rawData and store on cloud storage
        if (!isContentDeleted.current)
        {
            let rawContent = draftToHtml(convertToRaw(editorState.getCurrentContent()))
            console.log("ontextbox blur, rawHTMLContent="+rawContent)
    
            if (rawContent && rawContent != "<p></p>\n")
            {
                //Get net width of text only within a text-box
                const inner_text_width = document.querySelector('div[boxid="'+txt_id+'"]').querySelector('span[data-text="true"]').offsetWidth
                console.log("inner_text_width for box:"+txt_id+" is:"+inner_text_width)
                
                setEditorState(create_editor_state_from_DB(rawContent))
                console.log("editorState.getCurrentInlineStyle:"+editorState.getCurrentInlineStyle())
                
                //store entire html text to database
                
                let updated_textbox_width = editorRef.current.editor.editor.offsetWidth
                console.log("editor width:"+updated_textbox_width)
        
                let text_left = (updated_textbox_width-inner_text_width)+left
                console.log("text left: "+text_left)
        
                /*if(i18n.language=='he' && contains_heb(rawContent)){
                    await punctuateText().then((puncuatedContent)=>{
                        console.log("done puncuating text. isContentDeleted:"+isContentDeleted)
                        if (!isContentDeleted.current) onUploadTextDone(puncuatedContent, updated_textbox_width, text_left)
                    })
                }
                else{
                    onUploadTextDone(rawContent, updated_textbox_width, text_left)
                }*/

                onUploadTextDone(rawContent, updated_textbox_width, text_left)
            }
        }
    }

    const text_style = {
        width: width ? width : "430px"
    }
     const iconStyle = {
        position: 'absolute',
        top:'11px',
        padding:'9px',
        [i18n.resolvedLanguage === 'he' ? 'right' : 'left']: '-35px',
        color: isSpeaking ? 'gray' : '#8A459B',
        cursor: isSpeaking ? 'not-allowed' : 'pointer',
    }

    const iconHover={
        backgroundColor:'#8A459B',
        color:'white',
        borderRadius: '50%',
        padding:'5px',
        width: '25px',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const handleMouseEnter = () => {
        setTextHovered(true);
      };
    
      const handleMouseLeave = () => {
        setTextHovered(false);
      };
    
      const handleTap = () => {
        setTextHovered(!textHovered);
      };

    
    return (
	<div id="textbox" 
        ref={wrapperRef} 
        style={text_style} 
        key={txt_id}
        >
            {!isEditMode && voice!='' &&
                (<span style={iconStyle} onClick={handleSpeakerIconClick} onTouchStart={handleSpeakerIconClick}>
                    <span
                        onMouseEnter={() => setIconTextHovered(true)} 
                        onMouseLeave={() => setIconTextHovered(false)}>
                            <RxSpeakerLoud style={iconTextHovered ? iconHover : ''} />
                    </span>
                </span>
        )}
        {/*only show buttons if in edit mode*/ 
        (isEditMode || isEditorEmpty)  ? 
        <div className="buttonsRow text_edtior_buttonsRow">
            <button type="button" className="hoverable float btn-circle" onClick={handleDelete}><AiOutlineDelete/></button>
        </div>
        : null}
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Heebo&family=Arimo&family=David+Libre&family=Varela+Round&family=Roboto&family=Lora&family=Open+Sans&family=Roboto+Slab&family=Lato:wght@100;400;700;900&display=swap');
            </style>
                <Editor
                    readOnly = {!isEditMode || read_only}
                    ref={editorRef}
                    editorState={editorState}
                    onFocus={onTextBoxFocus}
                    onBlur={onTextEditBlur}
                    initialContentState={""}
                    onEditorStateChange={onEditorStateChange}
                     //wrapperClassName={(isEditMode || isEditorEmpty)? "text_editor_wrapper_edit_mode" : ""}
                    wrapperClassName={(isEditMode || isEditorEmpty)? "text_editor_wrapper_edit_mode" : "text_editor_wrapper_edit_mode text_editor_wrapper"}
                    toolbarClassName ={(i18n.language=='he' ? 'toolbar toolbar_rtl' : 'toolbar')}
                    editorClassName = "editor"
                    /*textAlignment={(i18n.language=='he' ? 'right' : 'left')}
                    direction="RTL"*/
                    placeholder={t("textbox_placeholder")}
                    toolbarHidden = {!isEditMode&&!isEditorEmpty}
                    toolbar = {{
                        options: ['inline', 'fontSize', 'fontFamily', 'colorPicker', 'history'],
                        inline:{
                            options: ['bold', 'underline'],
                        },
                        fontSize:{
                            dropdownClassName: "toolbar_fontsize_dropdown",
                            options:[14,18,22,24,26,28,30,32,34,36],
                        },
                        fontFamily :{
                            options: (i18n.language=='he') ? ['Heebo','Arimo','David Libre','Open Sans','Varela Round'] : ['Lato','Roboto','Roboto Slab','Lora','Open Sans']
                        },
                        classname: "hoverable float btn-circle"
                    }}
                />
                <div>
                    {(!isEditMode && !read_only && <span><BsPencilFill className={`info_pencil_for_text ${i18n.resolvedLanguage === 'he' ? 'left' : 'right'}`} onClick={handleEditMode} onTouchEnd={handleEditMode} /> </span>)}
                </div>
        </div>
    ) 

}

