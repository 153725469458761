import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { createLead } from "../BookEdit/DBUtils";
import { send } from "emailjs-com";
import { EmailService } from "../BookEdit/Consts";
import Container from "react-bootstrap/Container";
import { ToastMessage } from "../BookEdit/ToastMessage";
// import { t } from 'i18next';
import { useTranslation } from "react-i18next";
import { getCurrentUser} from "../Common/LocalStorage";
import { useParams, useNavigate } from "react-router-dom";
import bett_logo from "./images/isaac_il-logo_03.png";
import './contact.css'

export const ContactUsForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [postSuccessSumbit, setPostSuccessSubmit] = useState(false)
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "Ozzystory",
    message: "",
    phone:"",
    reply_to: "",
    organization:"",
    jobtitle:""
  });

  const bettSuffix = "isaac2024"

    const [phoneError, setPhoneError] = useState("");
    const [isPhoneValid, setIsPhoneValid] = useState(true);

    const handlePhoneChange = (e) => {
      setIsPhoneValid(true);
      setPhoneError("");
      let phoneNumber = e.target.value;
      // check if phone number is valid
      if (phoneNumber) {
        let phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
        if (!phoneRegex.test(phoneNumber)) {
          setIsPhoneValid(false);
          return;
        }
      }
      onFormFieldUpdate(e, "phone");
  };

  const onFormFieldUpdate = (e, property) => {
    let updatedContactProps = toSend;
    console.log("setting contact " + property + " to:" + e.target.value);
    updatedContactProps[property] = e.target.value;
    setToSend(updatedContactProps);
  };

  useEffect(() => {
    async function iffill() {
      try {
        console.log("Got params:"+JSON.stringify(params))
        if(params.fill=="upgrade"){
          FillForm();
        }
      } catch (err) {
        console.error("failed iffill", err);
      }
    }
    iffill();
  }, []);

  const [input_name, setInputName] = useState('');
  const [input_email, setInputEmail] = useState('');
  const [input_content, setInputContent] = useState('');



  const FillForm = () => {
    let currentUser = getCurrentUser();
    let elem = document.getElementById('contactUsForm.ControlBookNameInput');
    if(currentUser.displayName){
      elem.value = currentUser.displayName;
      onFormFieldUpdate({ target: { value: currentUser.displayName } }, "from_name");
    }
    elem = document.getElementById('contactUsForm.ControlBookEmailInput');
    if(currentUser.email){
      elem.value = currentUser.email;
      onFormFieldUpdate({ target: { value: currentUser.email } }, "reply_to");
    }
    elem = document.getElementById('contactUsForm.ControlBookContentInput');
    elem.value = t("contact_fill_upgrade");
    onFormFieldUpdate({ target: { value: t("contact_fill_upgrade") } }, "message");


  };

  const updateLeadsDb = (name,phone,email,organization,jobtitle) => {
    createLead(email, name, organization, jobtitle, phone).then(
      (res)=>{
        console.log("created lead successfully:"+res);
        },
      (err)=>{
        console.log("creat lead fail",err)}
    )
  }
 
  const onSubmit = (e) => {
    e.preventDefault();
    try{
      if (!isPhoneValid) {
        setPhoneError(t("phone_error"));
        return;
    }
    setProgressVisable(true);
    send(
      EmailService.SERVICE_ID,
      EmailService.TEMPLATE_ID,
      toSend,
      EmailService.USER_ID
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setProgressVisable(false);
        setPostSuccessSubmit(true)
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setProgressVisable(false);
      });
    }
    catch(err){
      console.log("failed to submit request for:"+toSend)
    }

    //also write to DB async
    updateLeadsDb(toSend.from_name,toSend.phone,toSend.reply_to,toSend.organization,toSend.jobtitle)
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const [progressVisable, setProgressVisable] = useState(false);
  const { t, i18n } = useTranslation();

  const currentLanguageCode = i18n.resolvedLanguage;
  const getDirection = () => (currentLanguageCode === "he" ? "rtl" : "ltr");

  return (
    <Container className={"contact_container "+getDirection()} dir={getDirection()}>
    {!postSuccessSumbit ? 
     <Form onSubmit={onSubmit} className="contact_form">
     {params.fill != bettSuffix ? null : <div className="contact_form_header">{t("contact_post_bett_draw_header")}</div>}
        <FloatingLabel
          controlId="contactUsForm.ControlBookNameInput"
          label={t("contact_name")}
          className="mb-2"
        >
          <Form.Control
            type="text"
            autoFocus
			      required
            onChange={(event) => onFormFieldUpdate(event, "from_name")}
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="contactUsForm.ControlBookEmailInput"
          label={t("contact_email")}
          className="mb-2"
        >
          <Form.Control
            type="text" 
			      required
            onChange={(event) => onFormFieldUpdate(event, "reply_to")}
          />
        </FloatingLabel>
         {params.fill == bettSuffix ? null : <FloatingLabel
          controlId="contactUsForm.ControlBookEmailInput"
          label={t("contact_phone")}
          className="mb-2"
        >
          <Form.Control
            type="text"
            //onChange={handlePhoneChange}
            onChange={(event) => onFormFieldUpdate(event, "phone")}
          />
          {!isPhoneValid && <p style={{color: "red"}}>{phoneError}</p>}
        </FloatingLabel>}
        {params.fill != bettSuffix ? null :<FloatingLabel
          controlId="contactUsForm.ControlBookTitleInput"
          label={t("contact_organization")}
          className="mb-2"
        >
          <Form.Control
            type="text" 
            onChange={(event) => onFormFieldUpdate(event, "organization")}
          />
        </FloatingLabel>}
        {params.fill != bettSuffix ? null :<FloatingLabel
          controlId="contactUsForm.ControlBookTitleInput"
          label={t("contact_jobtitle")}
          className="mb-2"
        >
          <Form.Control
            type="text" 
            onChange={(event) => onFormFieldUpdate(event, "jobtitle")}
          />
        </FloatingLabel>}
        {params.fill == bettSuffix ? null : <FloatingLabel
          controlId="contactUsForm.ControlBookContentInput"
          label={t("contact_content")}
          className="mb-2"
        >
          <Form.Control
            as="textarea"
            rows={5}
            className="content_box"  
            onChange={(event) => onFormFieldUpdate(event, "message")}
          />
        </FloatingLabel>}
        <Button type="submit">{t("contact_submit_btn")}</Button>
        {progressVisable ? (
          <ToastMessage
            header_text={t("contact_toast_header")}
            body_text={t("contact_toast_body")}
            position="bottom-start"
          />
        ) : null}
      </Form> : null}
      {postSuccessSumbit ? 
      <div className="post_submit_container">
          <div className="post_submit_header">{params.fill == bettSuffix ? t("contact_post_bett_draw_submit") : t("contact_post_submit")}</div>
          <Button onClick={() => navigate("/") }>{t("contact_post_submit_calltoaction")}</Button>
      </div> : null}
      {params.fill != bettSuffix ? null :
      <div className="ozzy-at-bett-container">
      <img className="bettLogo" src={bett_logo}></img><span className="ozzystory-at-bett">@ Ozzystory</span>
      </div>}
    </Container>
  );
};
