import {getFirebaseUser} from "../firebase"
import { getIdToken } from "firebase/auth"

export const performExport = async ({ story_id, user_email,setShowToast, book_name }) => 
{
    const exportURL = process.env.REACT_APP_EXPORT_PDF_URL;
    setShowToast(true);
    const export_call_body = JSON.stringify({ story_id: story_id, user_email: user_email })
    console.log("export_call_body: "+export_call_body)
    // Make a POST request to the server to export the story to PDF
    let currentUser = getFirebaseUser()
    let token_id = await getIdToken(currentUser)

    fetch(exportURL, {
      method: 'POST',
      body: export_call_body,
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token_id}`
    },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = book_name+'.pdf';

        downloadLink.click();
        setShowToast(false);
      })
      .catch((error) => {
        console.error('Perform-pdf-export error:', error);
        setShowToast(false);
      });
  };