import Modal from 'react-bootstrap/Modal'
import React from 'react'
import EditStudentPage from './EditStudentPage';

function EditStudentModal({ show, onHide, names_list, postAddViaDialogActions, user_email, noUserCallback}) {

  return (
        user_email != null ? 
          <Modal show={show} onHide={onHide} className="modal-container">
              <EditStudentPage
              names_list={names_list}
              postAddViaDialogActions={postAddViaDialogActions}
              noUserCallback = {noUserCallback}
              />
          </Modal>
        : noUserCallback()
  )
}

export default EditStudentModal