import React from 'react'
export const PrivacyPolicy = () =>
{
    const styles = {
        page:{
            marginLeft:'10px'
        }
    }
    return (
    <div style={styles.page}>

        <h2>Privacy Policy for Ozzystory</h2>

        <p>
            At Ozzystory, accessible from ozzystory.com, we are committed to protecting the privacy of our visitors. This Privacy Policy explains the types of information we collect and how we use it. Please read this policy carefully. If you have any questions or need more information, feel free to contact us.
        </p>

        <p>
            This Privacy Policy applies solely to our online activities and is applicable to visitors of our website concerning the information they share and collect on Ozzystory. It does not apply to information collected offline or through channels other than this website.
        </p>

        <h2>Our Responsibilities and Limitations:</h2>

        <ul>
            <li><strong>Limited Liability:</strong> Please note that we are in the early stages, and we cannot be held responsible for the exposure of personal images.</li>
            <li><strong>Content Copying:</strong> We cannot be held responsible if a story is copied.</li>
            <li><strong>User-Generated Content:</strong> Some users may publish their stories on our platform. These stories may be accessible to others, who may read, fork, and customize them.</li>
        </ul>

        <h2>Consent</h2>

        <p>
            By using our website, you consent to our Privacy Policy and agree to its terms.
        </p>

        <h2>Information We Collect</h2>

        <p>
            We will clearly explain the personal information we ask you to provide when necessary. If you contact us directly, we may collect additional information, such as your name, email address, phone number, message contents, and attachments.
        </p>

        <p>
            When you register for an account, we may request contact information, including items like name, company name, address, email address, and telephone number.
        </p>

        <h2>How We Use Your Information</h2>

        <p>
            We use collected information for various purposes, including:
        </p>

        <ul>
            <li>Providing, operating, and maintaining our website</li>
            <li>Improving, personalizing, and expanding our website</li>
            <li>Understanding and analyzing website usage</li>
            <li>Developing new products, services, features, and functionality</li>
            <li>Communicating with you for customer service, updates, and marketing purposes</li>
            <li>Sending you emails</li>
            <li>Detecting and preventing fraud</li>
        </ul>

        <h2>Log Files</h2>

        <p>
            We use log files, which include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamps, referring/exit pages, and the number of clicks. This information is used for analyzing trends, administering the site, tracking user movement, and gathering demographic information.
        </p>

        <h2>Cookies and Web Beacons</h2>

        <p>
            Our website uses cookies to store information about visitors' preferences and the pages they access. This information helps optimize user experiences.
        </p>

        <h2>Your Rights</h2>

        <ul>
            <li><strong>CCPA Privacy Rights (Do Not Sell My Personal Information):</strong> Under the CCPA, California consumers have rights, including requesting the categories and specific pieces of personal data collected, requesting data deletion, and requesting data not be sold.</li>
            <li><strong>GDPR Data Protection Rights:</strong> You have rights such as access to your data, data rectification, data erasure, restricting processing, and data portability.</li>
        </ul>

        <h2>Children's Information</h2>

        <p>
            Protecting children online is a priority. We do not knowingly collect Personal Identifiable Information from children under the age of 13. If you believe your child has provided such information on our website, please contact us, and we will promptly remove it from our records.
        </p>

    </div>

    )
}
