import React, { useCallback, useEffect, useState } from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { t } from 'i18next';
import EditStudentModal from './EditStudentModal';
import { getCurrentUser } from '../Common/LocalStorage';
import { UserPermission } from '../BookEdit/Consts';
import { UpgradeModalDialog } from '../BookSearch/UpgradeModalDialog';
import { SubMaxStudentsDialog } from './SubMaxStudentsDialog';
import { useTranslation } from 'react-i18next';
import { getUserGroup, isPartOfPaidOrg} from './UserAdmin';
import { get_user } from '../BookEdit/DBUtils';
import { isPaidConsumerUser } from '../BookEdit/FileStorageUtils';

const SelectStudent = ({student_list, current_student_id, onChange, onNewStudentCreation, getStudents, language, noUserCallback}) => {

    const [showEditStudentDialog, setShowEditStudentDialog] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(current_student_id)
    const { t, i18n } = useTranslation();
    const [upgradeModalShow, setUpgradeModalShow] = useState(false);
    const [maxStudentsModalShow, setMaxStudentsModalShow] = useState(false);
    const [is_paid_user,set_is_paid_user] = useState(false);
    const [isOrgUser, setIsOrgUser] = useState(null)

    const getDirection = () => (i18n.language === "he" ? "rtl" : "ltr");

    const onHideEditDialog = () => {
        setShowEditStudentDialog(false)
    }

useEffect(() => {
    const fetchData = async () => {
        try {
            // Check if the user is a paid consumer
            const paidUser = await isPaidConsumerUser();
            set_is_paid_user(paidUser);

            // Check if the user is a part of paid organization
            const currentUser = getCurrentUser();
            const user_data = await get_user(currentUser.email);
            let user_permission = null;
            let userGroup = getUserGroup(user_data);
            if (userGroup && userGroup.permission) {
                user_permission = userGroup.permission;
            } else {
                if (user_data.permission)
                    user_permission = user_data.permission;
            }
            const isOrg = await isPartOfPaidOrg(user_permission);
            setIsOrgUser(isOrg);
            console.log("isPermittedUser: ", isOrg);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };
    fetchData();
}, []);

    const showUpgradeModal = () => {
    setUpgradeModalShow(true)
  }
    const hideUpgradeModal = () => {
    setUpgradeModalShow(false)
  }
    const showSubMaxStudentsModal = () => {
    setMaxStudentsModalShow(true)
  }
    const hideSubMaxStudentsModal = () => {
    setMaxStudentsModalShow(false)
  }

    const onCreateNewStudent = ()=>{
      firebase.analytics().logEvent('tailor_dailog_create_student_click');
      setShowEditStudentDialog(true)
    }
    const onStudentChange = (e,property) => {
      setSelectedStudent(e.target.value)
      console.log("stduent_selected:"+e.target.value)
      onChange(e,property)
    }

  const postAddViaDialogActions = (async(ownerId, student_key) => {
        setShowEditStudentDialog(false)
        console.log("Hiding Add Student dialog. for owner:"+ownerId+". got student key:"+student_key)
        const response = await getStudents(ownerId, student_key);
        const updatedStudentList=Object.keys(response).map((id)=>({
          value: response[id].key/*ownerId ? response[id].objectID.toLowerCase() : id.toLowerCase()*/,
          label:response[id].name,
    }))
        setSelectedStudent(student_key.toLowerCase())
        onNewStudentCreation(student_key.toLowerCase(), updatedStudentList)
  })

    return (
    <div>         

        <FloatingLabel   
        controlId="saveBookForm.ControlStudentInput"
          label={t("select_student")}
          className="mb-2">
          <Form.Select
            value={selectedStudent}
            required
            onChange={onStudentChange}
          >
            {Object.keys(student_list).map((id) =>
              <option
                key={id}
                value={student_list[id].value.toLowerCase()}>
                  {student_list[id].label}
              </option>
            )}
          </Form.Select>
                  {((Object.keys(student_list)).length < UserPermission.SUBSCRIBER_MAX_STUDENTS && is_paid_user) ||
                    ((Object.keys(student_list)).length < UserPermission.MAX_STUDENT && !is_paid_user) || isOrgUser ? (
                        <Button className="mt-2" size="sm" onClick={onCreateNewStudent}>{t("add_new_student")}</Button>
                    ) : (
                        <Button className="mt-2" size="sm" onClick={is_paid_user ? showSubMaxStudentsModal : showUpgradeModal}>{t("add_new_student")}</Button>
                    )}
          {/* <Button className="mt-2"  size="sm" onClick={onCreateNewStudent}>{t("add_new_student")}</Button> */}
        </FloatingLabel>
        <EditStudentModal
          show={showEditStudentDialog}
          onHide={onHideEditDialog}
          postAddViaDialogActions={postAddViaDialogActions}
          names_list={student_list}
          user_email={getCurrentUser() && getCurrentUser().email ? getCurrentUser().email : null}
          noUserCallback = {noUserCallback}
        />
        <>
        <UpgradeModalDialog
        show={upgradeModalShow}
        handleDialogHide={hideUpgradeModal}
        getDirection={getDirection}
        />

        <SubMaxStudentsDialog
        show={maxStudentsModalShow}
        handleDialogHide={hideSubMaxStudentsModal}
        getDirection={getDirection}/>
        </>
      </div>

          
  )
}

export default SelectStudent