import React, {memo, useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from "react-router-dom";
import {BsFillTrashFill } from "react-icons/bs";
import '../Common/bookCard.css'
import { useTranslation } from "react-i18next";
import { DeleteStudentDialog } from './DeleteStudentDialog';
import { ToastMessage } from '../BookEdit/ToastMessage';

const StudentsCarousel = ({students, user_organization_groupid='', getDirection, deleteStudent,set_user_students}) => {

    const { t, i18n } = useTranslation();
   
    const styles = {
      // column: {
      //   margin:'-10px',
      // },
      row: {
      },
      card: {
        border:'none',
        cursor:'pointer',
      },
      cardImage: {
        objectFit: 'cover',
        borderRadius: 20,
        padding:'5px',
        width:'200px',
        height:'200px',
        marginRight:'15px',
        marginLeft:'15px',
        // paddingBottom:'15px',
      },
      title:{
        fontSize:'20px',
        textAlign:'center',
        marginBottom: '1px',
      },
      cardBody:{
        textDecoration: 'underline white',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.698)',
        paddingBottom:'5px',
        paddingTop:'10px',
        position: 'relative'
        //marginRight:'30px'
      },
      iconsTrash:{
      fontSize:'18px',
      //  float:(i18n.language=='he') ? 'left': 'right',
       color:'#8A459B',
       position: 'absolute',
       top: '14px',
      left: (i18n.language === 'he') ? '2px' : 'auto',
      right: (i18n.language === 'he') ? 'auto' : '2px', 
     }
  }

  const [progressVisable, setProgressVisable] = useState(false);
  const [showDeleteStudentDialog, setShowDeleteStudentDialog] = useState(false);
  const [currentStudentId, setCurrentStudentId] = useState(null)
  const [currentStudentIndex, setCurrentStudentIndex] = useState(0)
  const [studentsMap, setStudentsMap] = useState(students)

  const handleDeleteStudent = async () => {
    setProgressVisable(true)  
      try {
        await deleteStudent(currentStudentId).then(response => {
          console.log("marked student:"+currentStudentId+" as deleted. operation result:"+response)
        })
      }catch (error) {
          console.log("Error deleting student:"+currentStudentId+", error:" +error);
      }
      finally{
        handleDeleteStudentDialogHide(false)
        setProgressVisable(false)
        //remove from current page's array object
        if (Array.isArray(studentsMap)) {
            // Remove from the array using splice
            studentsMap.splice(currentStudentIndex, 1);

        }else if (typeof studentsMap === 'object'){
            // Create a new object without the deleted student
            const updatedStudentsMap = { ...studentsMap };
            delete updatedStudentsMap[currentStudentId];
            setStudentsMap(updatedStudentsMap);
            set_user_students(updatedStudentsMap);
            
            // Update the local storage
          try {
            const localStorageData = JSON.parse(localStorage.getItem('user-group-name'));
            if (localStorageData && localStorageData.students) {
              localStorageData.students = updatedStudentsMap;
              localStorage.setItem('user-group-name', JSON.stringify(localStorageData));
            } else {
              console.error('Invalid data in local storage');
            }
          } catch (e) {
            console.error('Error parsing local storage data', e);
          }
        } else {
          console.error('Invalid type for studentsMap:', typeof studentsMap);
        }
      }
    }

  const handleDeleteStudentDialogHide = () => {
    setShowDeleteStudentDialog(false)
  }

  const onDeleteClick = (e, student_id, indexToDelete) => {
    setCurrentStudentId(student_id)
    e.preventDefault()
    e.stopPropagation()
    setCurrentStudentIndex(indexToDelete)
    setShowDeleteStudentDialog(true)
  }
  
  const generateStudentLink = (id) => {
     let org_groupId = user_organization_groupid ? user_organization_groupid : ''
      if (!isNaN(id)) {
      return "/editstudent/"+students[id].key+"/"+org_groupId
    }else
      return "/editstudent/"+id
  }

  return (
    <div>
      <Row xs={2} sm={3} md={4} lg={5} xl={9} xxl={11} className="g-3" style={styles.row}>
        {
          (Object.keys(studentsMap).filter((id) => studentsMap[id].deleted!=='true')).map((id, index) => (
          <Link key={id}  to={generateStudentLink(id)} /*component={<EditStudentPage/>}*/>
            <Col style={styles.column}> 
              <Card style={styles.card} className="book_card">
                <Card.Img variant="bottom" src={students[id].image} style={styles.cardImage}/>
                <Card.Body style={styles.cardBody}>
                  <Card.Title style={styles.title} className="g-1">{students[id].name}</Card.Title>
                  <span>
                   <BsFillTrashFill style={styles.iconsTrash} className="trash-icon" onClick={(e) => onDeleteClick(e,students[id].key,id)}/>
                  </span>
                </Card.Body>
              </Card>
            </Col>
          </Link>
        ))}
      </Row>
      <DeleteStudentDialog
          student_id={currentStudentId}
          show={showDeleteStudentDialog}
          handleDeleteStudentDialogHide={handleDeleteStudentDialogHide}
          handleDeleteStudent={handleDeleteStudent}
          getDirection={getDirection}
        />
        {progressVisable ? (
          <ToastMessage
            header_text={t("delete_book_toast_header")}
            body_text={t("delete_book_toast_body")}
            position="bottom-start"
          />
        ) : null}
    </div>
  )
}


export default memo(StudentsCarousel);

