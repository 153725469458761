import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { BsSearch } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import CloseModalButton from '../Common/CloseModalButton';
import logo_ARASAAC_black from '../Common/images/logo_ARASAAC_black.png';
import { performsSymbolSearch } from '../Common/SearchAPI';
import { SymbolCarousel } from './SymoblCarousel';
import { ExternalSymbolsCarousel } from './ExternalSymbolsCarousel';
import './symbolSearchDialog.css';

export const SymbolSearchDialog = ({ show, onHide, getDirection, setExternal_symbols_counter }) => {
  const styles = {
    symbolsContainer: {
      marginLeft: '0',
      marginRight: '0',
      width: '50%',
    },
    symbolDialog: {
      height: '80vh'
    },
    symobolMsg: {
      marginTop: '5px',
      fontSize: '18px',
      marginLeft: '15px',
    },
    logo: {
      width: '100px',
      height: '23.76px',
    },
    symbolsFromMsg: {
      marginTop: '5px',
      marginBottom: '20px',
      fontSize: '18px',
      marginLeft: '15px',
      marginRight: '15px',
    },
    arasaacMsg: {
      marginTop: '2px',
      marginBottom: '2px',
      fontSize: '11px',
      marginLeft: '20px',
      textAlign: 'left'
    }
  };

  const [ARASAAC_symbols, set_ARASAAC_symbols] = useState([]);
  const [search_result_symbols, set_search_result_symbols] = useState([]);
  const [selected_symbol_path, set_selected_symbol_path] = useState('');
  const [searchInput, setSearchInput] = useState("");
  const { t, i18n } = useTranslation();
  const [cancellationToken, setCancellationToken] = useState(new CancellationToken());

  useEffect(() => {
    cancellationToken.cancel();
    const newToken = new CancellationToken();
    setCancellationToken(newToken);
    const timeoutId = setTimeout(() => {
      if (searchInput !== '') {
        fetchSymbolsFromAPI(searchInput, newToken, i18n.resolvedLanguage);
      }
    }, 400); 
    return () => {
      newToken.cancel();
      clearTimeout(timeoutId);
    };
  }, [searchInput]); 

  const handleChange = async (e) => {
    set_ARASAAC_symbols([]);
    setSearchInput(e.target.value);
    await performsSymbolSearch(e.target.value).then((search_response) => {
      if (search_response && search_response.hits) {
        set_search_result_symbols(search_response.hits.map(symbol_result => symbol_result));
        set_selected_symbol_path("");
      }
    });
  };

  const fetchSymbolsFromAPI = async (query, thisCancellationToken, lang) => {
    try {
      const response = await fetch(`https://api.arasaac.org/v1/pictograms/${lang}/search/${query}`, {
        method: 'GET',
      });
      if (response.ok) {
        const jsonData = await response.json();
        const idArray = jsonData.map(item => item._id);
        const max_results = 60;
        let counter = 0;
        for (const symbol_id of idArray) {
          counter++;
          if (thisCancellationToken.isCancelled) {
            set_ARASAAC_symbols([]);
            return;
          }
          if (counter > max_results) break;
          const response2 = await fetch(`https://api.arasaac.org/v1/pictograms/${symbol_id}`, {
            method: 'GET',
          });
          set_ARASAAC_symbols(prevList => [...prevList, { url: response2.url }]);
        }
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSymbolClick = (url, is_external = false) => {
    set_selected_symbol_path(url);
    onHide(url);
    if (is_external) {
      setExternal_symbols_counter(prevValue => prevValue + 1);
    }
  };

  const handleFormHide = e => {
    e.preventDefault();
    onHide(selected_symbol_path);
  };

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={styles.symbolDialog}
      dir={getDirection()}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("symbol_search_title")}
        </Modal.Title>
        <CloseModalButton onClick={onHide} />
      </Modal.Header>
      <Form onSubmit={handleFormHide}>
        <Container style={{ marginTop: '20px', marginBottom: '5px' }}>
          <div className="searchContainer">
            <BsSearch className="searchIcon" />
            <input
              className="search_input"
              type="search"
              placeholder={t('symbol_search_box_caption')}
              onChange={handleChange}
              value={searchInput}
            />
          </div>
        </Container>
        <Modal.Body className='symbols-modal-body'>
          {ARASAAC_symbols && ARASAAC_symbols.length > 0 && (
            <div>
              <div style={styles.symbolsFromMsg}>
                {t("symbols_from_ARASAAC")}
                <img src={logo_ARASAAC_black} style={styles.logo} alt="logo_ARASAAC" />
              </div>
              <ExternalSymbolsCarousel
                id='search_result_symbols'
                symbols={ARASAAC_symbols}
                parentCallbackOnClick={handleSymbolClick}
              />
            </div>
          )}
          {search_result_symbols && search_result_symbols.length > 0 && (
            <div>
              <div style={styles.symbolsFromMsg}>{t("symbols_from_Ozzystory")}</div>
              <SymbolCarousel
                id='search_result_symbols'
                symbols={search_result_symbols}
                parentCallbackOnClick={handleSymbolClick}
              />
            </div>
          )}
          {search_result_symbols && ARASAAC_symbols &&
            ARASAAC_symbols.length == 0 && search_result_symbols.length === 0 &&
            <div style={styles.symobolMsg}>{t("no_symbol_found")}</div>}
        </Modal.Body>
        <Modal.Footer style={{ height: '46px' }}>
          <div style={{ ...styles.arasaacMsg, marginTop: '-6px' }}>
            The pictographic symbols used are the property of the Government of Aragón and have been created by Sergio Palao for ARASAAC (http://www.arasaac.org), which distributes them under Creative Commons License BY-NC-SA.
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

class CancellationToken {
  constructor() {
    this._isCancelled = false;
  }

  cancel() {
    this._isCancelled = true;
  }

  get isCancelled() {
    return this._isCancelled;
  }
}
