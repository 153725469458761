import React, { useState, useEffect, useRef } from 'react';
import { createApi } from 'unsplash-js';
import { useTranslation } from "react-i18next";
import './webimagesearch.css'

const unsplash = createApi({
  accessKey: "waiUm5SNkES3aIsZh8LgTem-POvsCcG56MmyNuolxjE",
});

const WebImageSearch = ({ onImageSelect, selectedImage, externalImages, setExternalImages,query,setQuery}) => {
  const { t } = useTranslation();
  
  const [isFocused, setIsFocused] = useState(false);
  const searchRef = useRef(null); // For debouncing
  const abortControllerRef = useRef(new AbortController()); // For canceling fetch requests

  useEffect(() => {
    if (query.length === 0) {
      setExternalImages([]);
      return;
    }

    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    const detectLanguage = (text) => {
      const hasHebrew = /[\u0590-\u05FF]/.test(text); // Check for Hebrew characters
      const hasEnglish = /[A-Za-z]/.test(text); // Check for English characters
    
      if (hasHebrew) {
        return 'he'; // Return 'he' if Hebrew characters are found
      } else if (hasEnglish) {
        return 'en'; // Return 'en' if English characters are found
      }
      return 'en'; // Return 'en' as default
    };

    searchRef.current = setTimeout(() => {
      abortControllerRef.current.abort(); // Cancel the previous request
      abortControllerRef.current = new AbortController(); // Create a new controller for the new request

      const searchImages = async () => {
        try {
          const response = await unsplash.search.getPhotos({
            query: query,
            page: 1,
            perPage: 20,
            signal: abortControllerRef.current.signal, // Pass the signal to fetch
            lang: detectLanguage(query)
          });
          if (response.response) {
            setExternalImages(response.response.results);
          }
        } catch (error) {
          if (error.name !== 'AbortError') {
            console.error('Fetch error:', error);
          }
        }
      };

      searchImages();
    }, 300);

    return () => clearTimeout(searchRef.current); // Cleanup on unmount
  }, [query]);

  return (
    <div className="search-container">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder={isFocused ? '' : t('web_search_box_text')}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(query.length > 0)}
        className="search-input"
      />
      <svg className="search-icon" viewBox="0 0 24 24">
        <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
      </svg>

      
    </div>
  );
};

export default WebImageSearch;
