import React, {useState, useMemo, useEffect, cloneElement, useCallback} from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import countryList from 'react-select-country-list'
import {updateBookMetadata, readBookMetadata, get_group_name, getStudent, get_user, getSingleGroupStudent} from './DBUtils'
import Image from 'react-bootstrap/Image'
import {uploadThemeImageToCloudStorage} from './FileStorageUtils'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { t } from 'i18next';
import { style } from 'dom-helpers'
import { useTranslation } from "react-i18next";
import { ToastMessage } from './ToastMessage'
import { getCurrentUser } from '../Common/LocalStorage'
import EditStudentModal from '../UserMgmt/EditStudentModal'
import SelectStudent from '../UserMgmt/SelectStudent'
import Select from 'react-select';
import CloseModalButton from "../Common/CloseModalButton";
import { useParams } from 'react-router-dom'
import { BookPermissions, UserPermission } from './Consts'
import { useSortBy } from 'react-instantsearch-hooks'
import { customStyles, categoriesOptions } from '../Common/Utils/FormUtils'


export const PublishBookDialog = ({book_id, show, onHide, postPublishCallback, book_name_from_parent, 
                                  user_email, book_metadata, student_list, getDirection,
                                  isPermittedUser, creator_name, getStudents, ownerId, setStudentList,
                                  external_symbols_counter, page_orientation}) => {

  //generic set value function to conrtol from controllers
  /*const updateValue = (setFunc) =>
      ({target:{value}}) => setFunc(value)*/
  const options = useMemo(() => countryList().getData(), [])
  const params = useParams();

  //const [book_props, setBookProps] = useState(() => readBookMetadata(book_id))
  const [book_props, setBookProps] = useState(book_metadata)
  const [themeImageAsFile, setThemeImage] = useState (book_props.theme_img_url ? book_props.theme_img_url : '')
  const [themeImgUrl, setThemeImgUrl] = useState(null)

  const [validated, setValidated] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showEditStudentDialog, setShowEditStudentDialog] = useState(false);
  const [userData,setUserData] = useState({})
  const [orgName, setOrgName] = useState(null)

    useEffect(()=>{
      async function fetchUserData() {
        if (user_email)
        {
          await get_user(user_email).then(async(res)=>{
            setUserData(res)
            if (res.organizations && 
              res.organizations.id && 
              res.organizations.groups.length > 0 
              && res.organizations.groups[0].group_id){
                let orgid = res.organizations.id
                let groupid = res.organizations.groups[0].group_id
                await get_group_name(groupid, orgid, i18n.language).then((response) =>{
                  setOrgName(response)
                })
              }
          })
        }
    }
    fetchUserData()
    },[])

    const getStudentData = params.groupid ? getSingleGroupStudent : getStudent

  // FROM https://stackblitz.com/edit/react-ts-6j1amq?file=New.tsx yyy
  // AND FROM https://www.cluemediator.com/usestate-with-object-in-react-hooks yyy
  const [errors, setError] = useState({ book_name: "",student_id:"",theme_image:"" });
    const get_property_display_name = (property) => {
    let result = ""
    switch (property){
      case "book_name":
        result = t("upload_book_name_validator")
        break
      case "student_id":
        result = t("upload_student_id_validator")
        break
      case "theme_image":
          result = t("upload_theme_image_validator")
          break
    }
    return result
  }

    const { i18n} = useTranslation();
    const currentLanguageCode = i18n.resolvedLanguage;
  const onFormFieldUpdate = (e,property) => {
    let updatedBookProps = book_props
    if(property==='permission'){
      params.groupid ? 
        updatedBookProps[property] = (e.target.checked===true) ? BookPermissions.ORG_PUBLIC : BookPermissions.PRIVATE
        : 
        updatedBookProps[property] = (e.target.checked===true) ? BookPermissions.PRIVATE : BookPermissions.PUBLIC
    }
    else if(property==='category'){
        updatedBookProps[property]=e.value.toLowerCase();
    }
    else if(property==='book_name'){
        updatedBookProps[property]=e.target.value;
    }
    else{
    updatedBookProps[property] = e.target.value.toLowerCase()}
    console.log("In onFormFieldUpdate " + property + " :" + updatedBookProps[property])
    console.log("updatedBookProps[property] < 1", updatedBookProps[property] < 1)
    if (updatedBookProps[property] < 1) {
      setError(prevError => ( { ...prevError, [property]: get_property_display_name(property)} ));
      console.log("errors: ", errors)
      console.log("errors.book_name: ", errors.book_name) 
      console.log("errors.student_id: ", errors.student_id)
    }
    else {
      setError({ book_name: "" }); 
    }
    setBookProps(updatedBookProps)
  }

  const postNewStudentCreation = (student_id, updatedStudentList) => {
    let updatedBookProps = book_props  
    updatedBookProps["student_id"] = student_id.toLowerCase()
    console.log("updated book_props post new student upddate:"+updatedBookProps)
    setBookProps(updatedBookProps)
    setStudentList(updatedStudentList)
  }

  const styles = {
    imgThumbnail:{
      height:'130px',
      marginLeft:'10px',
    },
    thumbnailLabel:{
      verticalAlign:'top',
    },
    validation_error:{
      color:'#dd2c00',
      marginLeft:'5px',
    }
  }
  async function fetchBookProps() {
    try{
      if (book_id)
      {
          if(book_metadata 
            && Object.keys(book_metadata).length === 0
            && Object.getPrototypeOf(book_metadata) === Object.prototype) 
              setBookProps(book_metadata)
          else
          {
            readBookMetadata(book_id).then(response => {
              console.log("read props for book_id:"+book_id+" metadata. got:"+(response ? JSON.stringify(response) : 'noting'))
              //modify book name to be as parent
              if (response)
              {
                response.book_name = book_name_from_parent
                setBookProps(response)
              }
          })
        }
      }    
    }
    catch(err){
      console.error("failed to fetch book props:"+book_id,err)
    }
  }

  const updateThemeImageURL = (e) => {
    const image = e.target.files[0]
    console.log("image name on browse="+image.name)
    setThemeImage(image)
  }

    //       const onAddClick = () => {
    //     console.log("add student button clicked")
    //       setShowEditDialog(true)
    // }
  useEffect(() => {
    let update_book_props = book_props
    update_book_props.book_name = book_name_from_parent
    console.log("updating book_props due to parent name change to:"+book_name_from_parent)
    setBookProps(update_book_props)
  },[book_name_from_parent])

  useEffect(() => {
    let fileReader, isCancel = false;
    if (themeImageAsFile) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setThemeImgUrl(result)
        }
      }
      fileReader.readAsDataURL(themeImageAsFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
      setShowToast(false)
    }
  }, [themeImageAsFile]);

  const handleImageUploads = (async(event) => {
    let result = {}
    console.log("themeImgUrl:"+themeImgUrl+" ,book_props.theme_img_url:"+book_props.theme_img_url)
    //if no image selected, keep current one (if exists)
    if (!themeImageAsFile && book_props.theme_img_url) 
      result = book_props.theme_img_url
    else //upload new image if selected
      await uploadThemeImageToCloudStorage(event, themeImageAsFile, postPublishCallback, setThemeImage).then(response => {
        result = response
    })
    return result
  })

  const isPrivate = ()=>{
      return (book_metadata.permission == BookPermissions.PRIVATE || 
              (JSON.stringify(book_metadata.permission)=='""')) ? true : false
  }
  
  const displayError = () =>{
      if(book_props.book_name==='')
      {
        setError(prevError => ( { ...prevError, ["book_name"]: get_property_display_name("book_name")}))
      }
      if(book_props.theme_img_url===''){
        setError(prevError => ( { ...prevError, ["theme_image"]: get_property_display_name("theme_image")}))
      }
      if(book_props.student_id==""){
        setError(prevError => ( { ...prevError, ["student_id"]: get_property_display_name("student_id")}))
      }
  }
  const isFormValid = (event) => {
    let result = false
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      console.log("form invalid - setting validated to false")
      setValidated(false)
      setError("field is missing")
      result = false  // yyy
    }
    else {
      console.log("form valid - setting validated to true")
      //setValidated(true);
      setError('');
      result = true
    }
    return result
  }

  const setAttributeValue = (attribute, defaultVal="") => {
    return book_props[attribute] && book_props[attribute]!="undefined" ? book_props[attribute] : defaultVal
  }

  const onPublishSubmit = (async (event) => {
    if (isFormValid(event))
    {
        setShowToast(true);
        console.log(book_props ? ("Submitting book. bookProps:"+JSON.stringify(book_props)+", theme_img_url:"+themeImageAsFile.name) : 'no data to submit')
        event.preventDefault() //prevent parent from rerendering
        let default_student_id = student_list&&student_list.length>0&&student_list[0].value ? student_list[0].value : ""
        //if student_id null or empty - use first student on the list
        let student_id_to_update = book_props.student_id ? book_props.student_id : default_student_id
        await getStudentData(user_email, student_id_to_update).then(async studentData => {
          let studentAttributes = {}  
          if (studentData){
              const { image, key, ...attributes} = studentData;
              book_props.kid_age = studentData.age;
              studentAttributes = attributes
              book_props.student_attributes = attributes;
            }
            await handleImageUploads(event).then(async(uploadedThemeImgURL) => {
              if (studentAttributes && (!studentAttributes.name || (studentAttributes.name && studentAttributes.name.length == 0)))
              {
                  studentAttributes = await getStudentData(user_email,student_id_to_update)
              }
              if((userData.permission && userData.permission != UserPermission.OZZY_PUBLISHER) &&
                  (!isPermittedUser || !(params.groupid && book_props.permission != BookPermissions.PUBLIC)))
              {
                book_props.permission = BookPermissions.PRIVATE
              }
              await updateBookMetadata(book_props.creator_email || user_email,  
              book_props.book_id || book_id, 
              book_props.book_name,//bookNameOnDialog, 
              "http://someurl.com",
              studentAttributes.age || book_props.kid_age,
              studentAttributes.concentration_level || 1,
              studentAttributes.language_comprehension || 1,
              studentAttributes.main_challenges || [],
              book_props.locale = (currentLanguageCode=='he')?'IL':'US',
              book_props.category, 
              uploadedThemeImgURL ? uploadedThemeImgURL : '',
              book_props.first_page,
              book_props.last_created_page_id,
              (userData.permission == UserPermission.OZZY_PUBLISHER || 
                (params.groupid && book_props.permission != BookPermissions.PUBLIC)) ? 
                    book_props.permission : 
                    BookPermissions.PRIVATE,
              student_id_to_update,
              studentAttributes || book_props.student_attributes,
              book_props.deleted ? book_props.deleted:'',
              book_props.creator_name || creator_name,
              external_symbols_counter || 0,
              page_orientation,
              params.groupid ? params.groupid : null).then(async(response)=>{
                //set book properties to maintain page persistancy
                setBookProps({
                  "book_id":book_props.book_id || book_id,
                  "book_name": book_props.book_name,//bookNameOnDialog,
                  "creator_email":book_props.creator_email || user_email,
                  "creator_name":book_props.creator_name || creator_name,
                  "kid_age":studentAttributes.age || book_props.kid_age,
                  "kid_concentration_level":studentAttributes.concentration_level || 1,
                  "kid_language_comprehension":studentAttributes.language_comprehension || 1,
                  "kid_main_challenges":studentAttributes.main_challenges || [],
                  "locale":book_props.locale = (currentLanguageCode=='he')?'IL':'US',
                  "category":book_props.category,
                  "page_orientation": book_props.page_orientation || page_orientation,
                  "theme_img_url":uploadedThemeImgURL ? uploadedThemeImgURL : '',
                  "first_page":(book_props.book_id || book_id)+"_1",
                  "permission": (userData.permission == UserPermission.OZZY_PUBLISHER) ? book_props.permission : 'private',
                  "student_id":student_id_to_update,
                  "student_attributes":studentAttributes || book_props.student_attributes,
                  "deleted":book_props.deleted ? book_props.deleted:'',
                  "external_symbols_counter": external_symbols_counter || 0,
                  "last_created_page_id":book_props.book_id || book_id+"_1",
                })
                //event.preventDefault() //prevent parent from rerendering
                console.log("updated book metadata post publish")
                //postPublishCallback(bookNameOnDialog, book_props.book_id)
                await postPublishCallback(book_props.book_id || book_id, book_props.book_name)
              },
              (err) =>
              {
                console.error("failed to update book details",err)
              })
            }
          )
        })
    }
    else {
      console.log("publish form invalid")
      displayError();
      console.log("errors.book_name: ", errors["book_name"])  // c yyy
      console.log("errors.theme_image: ", errors["theme_image"])
      console.log("errors.student_id: ", errors["student_id"])
      event.preventDefault()
      event.stopPropagation()
    }``
  })

  return (
    <Modal
      show={show}
      onShow={fetchBookProps}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-container"
       dir={getDirection()}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" >
          {t("upload_book_dialog_header")}
        </Modal.Title>
        <CloseModalButton onClick={onHide}/>
      </Modal.Header>
      <Form onSubmit={onPublishSubmit} noValidate validated={false} >
        <Modal.Body >
          {/*<Form>*/}
          <FloatingLabel
            controlId="saveBookForm.ControlBookNameInput"
            label={t("book_name_label")}
            className="mb-2">
            <Form.Control
              required
              type="text"
              defaultValue={book_props.book_name}
              onChange={(event) => onFormFieldUpdate(event, "book_name")}
            />
            {errors["book_name"] && (
              <span style={styles.validation_error}>{errors["book_name"]}</span>
            )}
          </FloatingLabel>
          <SelectStudent
          student_list={student_list}
          current_student_id={book_metadata.student_id}
          onChange={(event) => onFormFieldUpdate(event, "student_id")}
          //onNewStudentCreation={(studentId) => postNewStudentCreation(studentId)}
          onNewStudentCreation={(studentId, updatedStudentList) => postNewStudentCreation(studentId, updatedStudentList)}
          getStudents={getStudents}
          />
              {errors["student_id"] && (
              <span style={styles.validation_error}>{errors["student_id"]}</span> 
             )}

            <Select placeholder={t('category_label')} styles={customStyles} className="mb-2" options={categoriesOptions}
              onChange={(event) => onFormFieldUpdate(event, "category")}
            />
            {(userData.permission=='ozzy-publisher')?
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <div className="commoncheckbox">
                <Form.Check type="checkbox" 
                defaultChecked={isPrivate()}
                onChange={(event) => onFormFieldUpdate(event, "permission")} />
                <span>{t("private_permission")}</span>
            </div>
            </Form.Group>
            :
              params.groupid ?
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <div className="commoncheckbox">
                  <Form.Check type="checkbox" 
                  defaultChecked={!isPrivate()}
                  onChange={(event) => onFormFieldUpdate(event, "permission")}
                  />
                  <span>{
                    t("public_org_permission") + (orgName ? orgName : t("public_org_default_name"))
                    }
                  </span>
                  </div>
              </Form.Group>
              :
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <div className="commoncheckbox">
                  <Form.Check type="checkbox" 
                  // label={t("private_permission")}
                  defaultChecked={true}
                  disabled
                  />
                  <span>{t("private_permission")}</span>
                  </div>
              </Form.Group>}

          <Form.Group controlId="saveBookForm.ThemeImage" className="mb-2">
            <Form.Label style={styles.thumbnailLabel} className="mb-0">
              {t("theme_image")}
            </Form.Label>
            <Row>
              <Col>
                <Form.Control
                  type="file"
                  onChange={updateThemeImageURL}
                  placeholder={t("theme_image")}
                />
                {errors["theme_image"] && (
                  <span style={styles.validation_error}>
                    {errors["theme_image"]}
                  </span>
                )}
              </Col>
              <Col>
                <Image
                  style={styles.imgThumbnail}
                  src={
                    themeImgUrl
                      ? themeImgUrl
                      : book_props && book_props.theme_img_url
                      ? book_props.theme_img_url
                      : ""
                  }
                  thumbnail
                />
              </Col>
            </Row>
          </Form.Group>
          {/*</Form>*/}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit"
            disabled = {showToast}
          >
            {t("submit_book_dialog_btn")}
          </Button>
        </Modal.Footer>
      </Form>
            {showToast ? (
        <ToastMessage
            header_text={t("publish_toast_header")}
            body_text={t("publish_toast_body")}
            position="bottom-start"
          />
        ) : null}   

    </Modal>

  );
}