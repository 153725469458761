import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useTranslation } from "react-i18next";


export const DeleteStudentDialog = ({student_id, show, handleDeleteStudentDialogHide, 
                                        handleDeleteStudent, getDirection}) =>{

    const style = {
        height:'25px'
      };
          const {t} = useTranslation();
    return (
        <Modal 
            show={show} 
            keyboard={true}
            onHide = {handleDeleteStudentDialogHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-container"
            dir={getDirection()}>
           <Modal.Header> <Modal.Title>{t("delete_student_modal_header")}</Modal.Title></Modal.Header>
            <Modal.Body>{t("delete_student_modal_body")}</Modal.Body>
            <Modal.Footer>
                <Button variant="danger"onClick={() => handleDeleteStudent(student_id)}>{t("delete_book_modal_button_delete")}</Button>
                <Button variant="secondary" onClick={handleDeleteStudentDialogHide}>{t("delete_book_modal_button_cancel")}</Button>
            </Modal.Footer>
        </Modal>
    )
}