import React, { useCallback, useEffect, useState, createRef } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from 'react-bootstrap/FloatingLabel'
// import { t } from 'i18next';
import { storage } from "../firebase";
import { uploadToFileStorage,getImageDimensionsByFile } from "./FileStorageUtils";
import { useTranslation } from "react-i18next";
import { getCurrentUser} from "../Common/LocalStorage";
import { Locations } from './Consts';
import SelectStudent from "../UserMgmt/SelectStudent";
import { convertStudentIdToName, getStudent } from "./DBUtils";
import {BsFillTrashFill } from "react-icons/bs";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import './personalContentDialog.css'
import CloseModalButton from "../Common/CloseModalButton";
import { t } from 'i18next';
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";


export const PersonalContentModalDialog = ({shouldShow, imagesList, copyStoryWithNewPersonal, setProgessToast, 
                                            viewWithoutTailoring, names_list, user_email, book_metadata, setprivateImagesDesc, 
                                            getDirection, getStudents, getSingleStudent, setStudentList}) => {
  const { t,i18n } = useTranslation();
  const [imageAsFile, setImageAsFile] = useState("");
  const [imagesURL, setImagesURL] = useState("");
  const [kidName, setKidName] = useState(names_list&&names_list.length>0&&names_list[0].value ? names_list[0].value : "");
  const [submitBtndisabled, setSubmitbtnDisable] = useState(false) 
  const [studentId, setStudentId] = useState(null)
  const [studentAttributes,setStudentAttributes]=useState({});

  const handleImageAsFile = (e,desc) => {
    const image = e.target.files[0];
    var imgInfo=[];
    imgInfo[desc.desc]=image;
    setImageAsFile((images) => [...images, imgInfo])
    console.log("image name on browse=" + image.name);
  };
  //convertStudentIdToName(user_email,e)
  const convertIdToName = async (student_id) => {
    let result = ""
      try {
          await getSingleStudent(user_email,student_id.toLowerCase()).then((response) => {
            console.log("got student: "+JSON.stringify(response));
            let updateStudentName = response && response.name ? response.name : t('personal_content_default_name')
            setKidName(updateStudentName)
            result = updateStudentName
            setStudentId(student_id)
            setStudentAttributes(response);
        })
      } 
      catch (err) {
        console.error("failed to fetch student.", err);
      }
      return result
  }

  const handleFormHide = (e) => {
    console.log("calling onHide with filename. filename = " + imageAsFile.name);

  };

  const postNewStudentCreation = async (student_id, student_list) => {
    console.log("got new student:"+student_id+", name would appear on copied story")
    firebase.analytics().logEvent('student_created_derived_from_tailor_dialog');
    setStudentId(student_id)
    setStudentList(student_list)
    await convertIdToName(student_id)
  }

  const uploadImageToFirebase = useCallback (async () => {
    let return_val=[];
    for (const image of imageAsFile) { 
      await uploadToFileStorage(Object.values(image)[0],Locations.IMAGE_FOLDER +getCurrentUser().uid).then(
      (responseURL) => {
        if (responseURL) {     
          var imgurl={};
          imgurl[Object.keys(image)[0]]=responseURL;
          setImagesURL((images) => [...images, imgurl])
          return_val.push(imgurl);

        }
        (error) => {
          console.log("failed to upload image to file storage", error);
        }
      });
    }
    return return_val;
    })

    const getDefaultStudent = () =>{
      let result = {id:names_list&&names_list.length>0&&names_list[0].value ? names_list[0].value : "", 
                    name:names_list&&names_list.length>0&&names_list[0].label ? names_list[0].label : ""}

      if (book_metadata && book_metadata.student_id){
        result.id = book_metadata.student_id
        for (let i=0; i < names_list.length; i++){
          if (names_list[i].value == book_metadata.student_id)
            result.name = names_list[i].label
        }
      }
      return result
    }

    const createPersonalStory = async (e) =>
    {
      firebase.analytics().logEvent('tailor_dailog_create_click');
      /*let default_student_name = names_list&&names_list.length>0&&names_list[0].label ? names_list[0].label : ""
      let default_student_id = names_list&&names_list.length>0&&names_list[0].value ? names_list[0].value : ""*/
      let default_student = getDefaultStudent()
      
      let kidNameToPlace = kidName=="" ? default_student.name : kidName
      let new_stuednt_id = studentId ? studentId : default_student.id
      setStudentId(new_stuednt_id)
      setSubmitbtnDisable(true)
      setProgessToast()
      e.preventDefault()
      const privateContentAttributes = {}
      const responseURL = await uploadImageToFirebase();
        for (const image of imageAsFile) {
        const desc = Object.keys(image)[0];
        const dimensions = await getImageDimensionsByFile(Object.values(image)[0]);
        // Store the image dimensions in privateContentAttributes
        privateContentAttributes[desc] = {
          "nat-height": dimensions["height"],
          "nat-width": dimensions["width"],
        };
      }
      copyStoryWithNewPersonal(kidNameToPlace,responseURL,new_stuednt_id,studentAttributes,privateContentAttributes);
    }
    const handleImageDelete = (index) => {
      firebase.analytics().logEvent('tailor_dailog_trash_click');
      const updatedList = [...imagesList];
      console.log("updatedList: "+updatedList + " index: "+ index)
      console.log("updatedList index: "+updatedList[index])
      console.log("book_metadata private images: "+JSON.stringify(book_metadata.private_content))
      updatedList.splice(index, 1);
      setprivateImagesDesc(updatedList);
    };
  return (
    <Modal
      show={shouldShow}
      onHide={viewWithoutTailoring}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-container"
      dir={getDirection()}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
          {t("personal_content_header")}
        </Modal.Title>
         <CloseModalButton onClick={viewWithoutTailoring}/>
      </Modal.Header>
      <Form onSubmit={createPersonalStory}>
        <Modal.Body>
        <Form.Group>
        <SelectStudent 
          student_list={names_list} 
          current_student_id={book_metadata.student_id}
          onChange={(e)=> convertIdToName(e.target.value)}
          onNewStudentCreation={(studentId, updatedStudentList) => postNewStudentCreation(studentId, updatedStudentList)}
          getStudents={getStudents}
              />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            onClick = {handleFormHide}
            disabled = {submitBtndisabled}
          >
            {t("personal_content_button")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}