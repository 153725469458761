import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getIdToken } from "firebase/auth";
import { getCurrentUser } from "../Common/LocalStorage"


export const GetPublicStoriesUtils = async (locale) =>
{  
    // let token_id = currentUser.stsTokenManager.accessToken
  const getPublicStoriesURL = process.env.REACT_APP_GET_STORIES_URL;
        const bodyData = {
        Locale: locale
        };
    try {
        const response = await fetch(getPublicStoriesURL, {
            method: 'POST',
            body: JSON.stringify(bodyData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Accept': 'application/json, text/plain, */*'                },
            });
            const data = await response.json();
            return data; // Return JSON response from server
    } catch (err) {
        console.log("error in GetPublicStoriesUtils for:", err);
        throw err; // Rethrow the error
    }
}
