import React, { useState } from 'react';
import { Container, Form, FloatingLabel, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const PaymentFailedPage = ({ getDirection}) => {
  const [codeError,setCodeError] = useState("");
  const { i18n,t } = useTranslation();
  const navigate = useNavigate();



  function openPaymentPageAgain() {
      const userAuthId = JSON.parse(localStorage.getItem('user')).uid;
      (i18n.language === 'he')?
          window.location.href = "https://pay.sumit.co.il/4v72wf/5t6kjd?externalidentifier=" + userAuthId //he
          :
          window.location.href = "https://pay.sumit.co.il/4v72wf/5t6l3o?externalidentifier=" + userAuthId; //en
  }

  function openContactUs() {    
    navigate("/contact")
  } 

  return (
    <Container dir={getDirection()}>
      <h4>
        {t("payment_failed_header")}
      </h4>
      <div className="body" style={{ marginTop: '15px' }}>
        {t("payment_failed_body") + codeError}
      </div>
      <div style={{ marginTop: '25px', display: 'flex', gap: '15px' }}>
        <Button type="submit" onClick={openPaymentPageAgain}>{t("payment_failed_btn")}</Button>
        <Button type="submit" onClick={openContactUs}>{t("payment_failed_err_contact_btn")}</Button>
      </div>
    </Container>
  );
};
export default PaymentFailedPage;