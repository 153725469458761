import React, {useState, useEffect} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import './editbook.css'
import { t } from 'i18next';
import {IoIosPaperPlane,IoIosCopy} from 'react-icons/io'
import {AiFillFilePdf} from 'react-icons/ai'
import {FaFilePdf} from 'react-icons/fa'
import {getOrderedPagesPDF} from "./DBUtils";
import {StoryToPDFMerger} from './StoryToPDFMerger'
import Button from "react-bootstrap/Button";
import { GiSewingMachine } from "react-icons/gi";
import {BsFillTrashFill } from "react-icons/bs";
import {TiDeleteOutline} from "react-icons/ti"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {performExport} from './ExportPdfUtils';
import { ToastMessage } from './ToastMessage';

export const EditBookGlobalActions = ({bookMetadata, setToastVisibleCallback, setToastLoadText, 
                                      book_id, setBookNameCallback, storeCurrentBookPageCallback,
                                      setShowPublishDialogCallback, nextPage, prevPage,viewOnlyMode,setModalPersonalContentShow,isPermittedUser,setUpgradeModalShow,pdfs_store_completed,clonedFromFreeStory,isCreatorStory,user_email }) => {

    const [bookNameDisplay,setBookNameDisplay] = useState("")//useState(bookMetadata.book_name || t("default_book_name"))
    const [showToast, setShowToast] = useState(false);
    useEffect(() => {
      console.log("got book_name:"+bookMetadata.book_name)
      if (bookMetadata.book_name) setBookNameDisplay(bookMetadata.book_name || t("default_book_name"))
    },[bookMetadata])

      const onPublishClick = () => {
        console.log("publish button clicked")
        storeCurrentBookPageCallback(nextPage, prevPage).then(
          setShowPublishDialogCallback(true))
    }

  const handleExportPDF = async () => {
    console.log("export button clicked");
    let book_name = bookNameDisplay || bookMetadata.book_name
    await storeCurrentBookPageCallback().then((res)=>{
        // await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 3 seconds
      performExport({ story_id: res.book_id, user_email: bookMetadata.creator_email, setShowToast, book_name }).then(response => {
      console.log(response)
      })
    })
  };
    const onTailorClick = () => {
      console.log("tailor button clicked");
      firebase.analytics().logEvent('tailor_btn_click');
      (bookMetadata.creator_email==user_email) ? (
        storeCurrentBookPageCallback().then(
        setModalPersonalContentShow(true)
      )) : (
        setModalPersonalContentShow(true)
      )   
    }

    const onBookNameChange = (e) =>{
      setBookNameDisplay(e.target.value)
      setBookNameCallback(e.target.value)
    }
    const showUpgradeModal = ()=>{
      console.log("disabled tailor button clicked")
      setUpgradeModalShow(true)
    }

      return (
        <div className="bookDetailsRow">
        <input
          value={bookNameDisplay}
          className="bookName"
          placeholder={t("default_book_name")}
          onChange={onBookNameChange}
        ></input>
        <span className="book-buttons">

        {viewOnlyMode ? 
         null:(
          <Button className="btn" onClick={handleExportPDF} disabled={showToast}>
          <span className="book-button-text">{t('export_book_pdf_btn')}</span>
          <span>
            <FaFilePdf className="book-btn-icon" />
          </span>
        </Button>      
          )}
          {viewOnlyMode ? 
           (<Button className="btn" onClick={onTailorClick}>
            <span className="book-button-text">{t("tailor_book_btn")}</span>
            <span>
              <GiSewingMachine className="book-btn-icon" />
            </span>
          </Button>):
          ((isPermittedUser || !clonedFromFreeStory || !isCreatorStory) ?
              (
            <React.Fragment>
            <Button className="btn" onClick={onTailorClick}>
              <span className="book-button-text">{t("copy_book_btn")}</span>
              <span>
                <IoIosCopy className="book-btn-icon" />
              </span>
            </Button>
              <Button className="btn" onClick={onPublishClick}>
              <span className="book-button-text">{t("publish_book_btn")}</span>
              <span>
                <IoIosPaperPlane className="book-btn-icon" />
              </span>
            </Button>
          </React.Fragment>
            ):(
          <Button className="btn" onClick={onPublishClick}>
              <span className="book-button-text">{t("publish_book_btn")}</span>
              <span>
                <IoIosPaperPlane className="book-btn-icon" />
              </span>
            </Button>
             )  )}
        </span>
           <>
          {showToast && (
          <ToastMessage
          header_text={t('export_book_pdf_loader_header')}
          body_text={t('export_book_pdf_loader')+' '+bookNameDisplay || bookMetadata.book_name}
          position="top-center"
        />
      )}
    </>
      </div>
      )
}